import { Howl, Howler } from "howler";

//// I'm sorry for the singleton... 
//// I'm sorry for the singleton... 
//// I'm sorry for the singleton... 
//// I'm sorry for the singleton... 
//// I'm sorry for the singleton... ;(

export const audioService = new class AudioService {
  public muted = !! localStorage.getItem('muted');

  public readonly map:Record<string,{snd:Howl,volume:number}> = {}

  public debug = true;

  /**
   * Also returns the value of the 'muted' flag
   */
  public toggleMute() {
    this.muted = ! this.muted;
    
    this.muted 
    ? localStorage.setItem('muted', 'true')
    : localStorage.removeItem('muted')

    Object.values(this.map).forEach( ({snd,volume}) => snd.volume(this.muted ? 0.0 : volume))

    return this.muted;
  }

  public preload( src:string, volume = 1) {
    this.createOrGetSound(src, volume);
  }
  
  public play( src:string, volume = 1, stopAll = false ) {
    if ( this.muted  ) return
    if ( document.visibilityState !== 'visible' ) return
    if ( ! document.hasFocus() ) return
    if ( ! Howler.usingWebAudio || Howler.noAudio ) return
    
    if(stopAll) {
      (Howler as any).stop()
    }

    const snd = this.createOrGetSound(src, volume);

    snd.seek(0);
    snd.volume(volume);
    snd.play();
  }

  public createOrGetSound(src, volume, options = {}) {
    const { snd } = this.map[src] ?? ( this.map[src] = { snd : this.createSound(src, volume, options), volume })
    
    return snd;
  }
  public createSound(src, volume, options = {}) {
    const newSound = new Howl({ src, volume, ...options });
    newSound.on('unlock', () => {
      newSound.stop();
    });
    
    if(!this.debug) 
      return newSound;

      newSound.on('load', () => {
        console.log('[SoundService] load: ' + src)
      });
      newSound.on('loaderror', () => {
        console.log('[SoundService] loaderror: ' + src)
      });
      newSound.on('playerror', () => {
        console.log('[SoundService] playerror: ' + src)
      });
      
      newSound.on('play', () => {
        console.log('[SoundService] play: ' + src)
      });
      newSound.on('end', () => {
        console.log('[SoundService] end: ' + src)
      });
      newSound.on('pause', () => {
        console.log('[SoundService] pause: ' + src)
      });
      newSound.on('stop', () => {
        console.log('[SoundService] stop: ' + src)
      });
      newSound.on('mute', () => {
        console.log('[SoundService] mute: ' + src)
      });
      newSound.on('volume', (x) => {
        console.log(`[SoundService] volume: ${src} (${x})` )
      });
      newSound.on('rate', (x) => {
        console.log(`([SoundService] rate: ${src} (${x})` )
      });
      newSound.on('seek', (x) => {
        console.log(`[SoundService] seek: ${src} (${x})` )
      });
      newSound.on('unlock', () => {
        console.log(`[SoundService] unlock: ${src}` )
        newSound.stop();
      });
      newSound.on('fade', () => {
        console.log(`[SoundService] fade: ${src}` )
      });

    return newSound;
  }

}();
