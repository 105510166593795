import firebase from 'firebase';

import { GameStateController as GameStateControllerBase } from '@ven/shared/core/gameplay/GameStateController';
import type { GameSessionData as GameSessionDataBase, PlayerID } from '@ven/shared/core/gameplay/GameSessionData';
import type { CanvasAction } from '../drawing/CanvasAction';

import { getRandomFrom, range } from '@ven/shared/core/utils/arrays';

import { audioService } from '@ven/core/services/SoundService';
//@ts-ignore
import CorrectSound from "@ven/shared/components/assets/sounds/correct.mp3"
import { LoggingFeature, WithLogging } from '@ven/shared/core/gameplay/plugins/Logging';
import { GamePseudoServer } from './GamePseudoServer';
import { levenshtein } from '@ven/shared/core/utils/stringUtil';
import { analyticsService } from '@ven/platform/main/services/AnalyticsService';

////
let words = new Array;

export const DEFAULT_CONFIG = {
  rounds : 3,
  turnTime : 60,
  wordTime : 120,
  minPlayersCount: 2,
  maxPlayersCount: 16,
  pack: 'scribbler-starter'
}
////

export enum TimerPhase {
  PercentLeftLessThan100 = 0,
  PercentLeftLessThan40 = 1,
  PercentLeftLessThan20 = 2,
}
export type ChatMessage = {
  text : string
  correct? : boolean 
  sender : PlayerID
  turn? : number
  to?: string
}
export type GameStateTurnData = {
  over : boolean
  started : boolean
  player? : PlayerID
  time? : number
  timePhase? : TimerPhase
  timeout?: number
  word? : string
  wordHint? : string
  canvas? : Record<string,CanvasAction>
  chat? : Record<string, ChatMessage>
  number: number
}
export type GameStateRoundData = {
  number: number
  playersWaitingForTurn? : PlayerID[]
}
export type GameStateData = {
  started : boolean
  over? : boolean
  nextGameUrl? : string
  turn : GameStateTurnData
  round : GameStateRoundData
  forbiddenWords : string[]
  prevTurnResults? : {
    scores : Record<PlayerID,number>
    player : PlayerID
    word : string
    timeLeft : number
    skipped?: boolean
  }
}
export type GameConfigurationData = {
  rounds : number
  turnTime : number
  wordTime : number
  pack: string
}
export type PlayerData = {
  guessed : boolean
  score : number
} & GameSessionDataBase['players'][0]

export interface GameSessionData extends GameSessionDataBase
{
  players : Record<PlayerID, PlayerData>
  presences : Record<PlayerID, GameSessionDataBase['presences'][0]>
  config : GameConfigurationData
  state : GameStateData
}

export class GameStateController extends GameStateControllerBase<GameSessionData & WithLogging>
{
  public readonly log = new LoggingFeature(this);

  public readonly server = new GamePseudoServer(this);
  
  public tickerEnabled = true

  public readonly ref = {
    ...this.ref as GameStateControllerBase<GameSessionData>['ref'],
    canvas : () => this.ref.state().child('turn/canvas'),
    chat : () => this.ref.state().child('turn/chat'),
  }

  public readonly update = {
    state : ( data:Partial<GameStateData> ) => this.ref.state().update( data ),
    round : ( data:Partial<GameStateRoundData> ) => this.ref.round().update( data ),
    turn : ( data:Partial<GameStateTurnData> ) => this.ref.turn().update( data ),
    player : ( playerId:PlayerID, data:Partial<PlayerData> ) => this.ref.players().child( playerId ).update( data ),
    me : ( data:Partial<PlayerData> ) => this.ref.players().child( this.myUserId! ).update( data ),
  }

  public everyPlayer = async ( callback:(props:{id:PlayerID,data:PlayerData}) => any ) =>
    Object.entries( await this.get.players() ).every( ([id,data]) => callback({ id, data }) )

  public async getNewWords( count:number )
  {
    const forbiddenWords = this.data?.state?.forbiddenWords || []
    
    if(!words.length) {
      const packId = this.getConfig().pack;
      const docData = await firebase
      .firestore()
      .collection("packs")
      .doc(packId)
      .get()

      words = (docData.data() as any).words;
    }

    const wordPool = words.filter( w => ! forbiddenWords.includes( w ) )
    return range( count ).map( () => getRandomFrom( wordPool ) )
  }

  public getConfig():GameConfigurationData {
    return {
      ...DEFAULT_CONFIG,
      ...this.data.config,
    }
  }

  public setConfig(config : Partial<GameConfigurationData>) {
    this.ref.config().update(config)
  }

  async initialize() {
    super.initialize();
    requestAnimationFrame( () => this.server.initialize() );
    words.length = 0;
  }

  async destroy() {
    this.server.destroy();
    super.destroy();
  }

  async start(userConfig = {})
  {
    const config = this.getConfig();
    await super.start({}, { ...config, ...userConfig })
    await this.server.giveNextTurn()
    this.tickerEnabled = true
  }

  async onTick() {}

  public canStartGame = () => 
  {

    if (!this.getConfig().pack) {
      return false
    }

    return ! this.data.state.started &&
      Object.keys( this.data.players || {} ).length > 1
  }

  async clearCanvas()
  {
    this.ref.canvas().set([])
  }

  async addToBannedWords( words:string[] )
  {
    this.update.state({
      forbiddenWords : [ ...this.data?.state?.forbiddenWords || [], ...words ]
    })
  }

  async startTurn()
  {
    await this.update.turn({ 
        started : true 
      })
  }

  async chooseWord( word:string )
  {
    await Promise.all([
      this.update.turn({ 
        started : true,
        word : word, 
        wordHint : [ ...word ].map( c => c === ' ' ? c : "_" ).join(''),
      }),
      this.update.state({
        prevTurnResults : {
          player : this.data.state.turn.player!,
          scores : {},
          word,
          timeLeft : 1
        }
      })
    ])
    await this.ref.canvas().push([2]);
    await this.clearCanvas();
  }

  async guessWord( word:string )
  {
    const state = this.data.state as GameStateData

    if ( state?.over ) return;
    if ( !state?.started ) return;
    if ( !state?.turn?.started ) return;

    const correct = this.server.isGuessCorrect(word)
    const me = this.data.players[ this.myUserId! ]

    if ( state.turn.player === this.myUserId ) return
    if ( me.guessed ) return
        
    if(!correct)
    {
      const turn = this.data?.state?.turn?.number || 0;
      const message:ChatMessage = { text : word, correct, sender : this.myUserId!, turn }
      this.ref.chat().push( message );
      const turnWord = this.data?.state?.turn?.word;
      if(turnWord && word.trim().length > 3) {
        const isAlmostThere = levenshtein(word.trim().toLowerCase(), turnWord?.trim()?.toLowerCase()) <= 3;
        if(isAlmostThere) {
          const almostThereMessage:ChatMessage = { text : `You are almost there!`, to: this.myUserId!, sender : '', turn  }
          this.ref.chat().push( almostThereMessage )
        }
      }
    }

    this.server.sendAction("guess", {
      forTurnNumber: state.turn.number,
      phrase: word,
    })
  }
  
  async end()
  {
    await this.update.state({ over : true, })
    analyticsService.GameCompleted(this.gameId, this.roomId!);
  }
}
