import { database } from '@ven/core/data/firebase';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { MainHeader } from '../../organisms/MainHeader';
import { parse } from 'papaparse';
import Form from "@rjsf/core";
import { JSONSchema7 } from "json-schema";
import _ from "lodash";

import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import Dropzone, { useDropzone } from 'react-dropzone';
import styled from '@emotion/styled';
import firebase from 'firebase';
import { Helmet } from 'react-helmet';
import { RequestRolePanel } from '@ven/shared/components/common/templates/RequestRolePanel';
import { UserDataContext } from '@ven/platform/main/services/user/UserDataContext';

const PackEditor: React.FC = () => {
  const history = useHistory();
  let types: any[] = [];
  const [username, setUsername] = useState('');

  const [elements, setElements] = useState(new Array);

  const [files, setFiles] = useState();
  const [grid, setGrid] = useState(new Array);

  interface GridElement extends ReactDataSheet.Cell<GridElement, string> {
    value: string | null;
  }

  const schema: JSONSchema7 = {
    title: "Create Pack",
    type: "object",
    required: ['id', 'name', 'game', 'password'],
    properties: {
      id: { type: "string", title: "Id" },
      name: { type: "string", title: "Name", default: "Pack" },
      description: { type: "string", title: "Description"},
      game: { type: "string", title: "Game", enum: ["Scribbler", "Outlaw"], default: "Scribbler" },
      price: { type: "number", title: "Price", default: 0 },
      owner: { type: "string", title: "User Id" },
      hasHeader: { type: "boolean", title: "CSV has a header", default: false },
      password: { type: "string", title: "Password" }
    }
  };

  const uiSchema =  {
    password: {
      "ui:widget": "password"
    }
  };

  const [formData, setFormData] = useState(Object);
  const [csvData, setCsvData] = useState(Object);

  useEffect(() => {
    if (!files)
      return;

    const fileName = _.kebabCase((files![0] as any).name.split('.csv')[0]);
    const isOutlaw = fileName.toLowerCase().includes('outlaw')


    const packName =  fileName.toLowerCase()
      .replace('outlaw-', '').replace('scribbler-', '')
      .replace('outlaw_', '').replace('scribbler_', '')
      .replace('outlaw', '').replace('scribbler', '')
    setFormData({ ...formData, id: fileName, name: _.capitalize(packName), game: isOutlaw ? 'Outlaw' : formData.game })
  }, [files]);


  const validateScribbler = (data) => {
    let valid = true;
    data.forEach((line, index) => {
      if (!valid) {
        return;
      }

      if (line.length != 1) {
        alert("Scribbler must have exactly one column");
        valid = false;
        return;
      }

      if (!line[0].trim() && index != data.length - 1) {
        alert("empty words are not valid - line " + (index + 1));
        valid = false;
        return;
      }

      if (line[0].length > 32) {
        alert("no word must be bigger than 32 characters - line " + (index + 1));
        valid = false;
        return;
      }
    });
    return valid;
  }

  const validateOutlaw = (data) => {
    let valid = true;
    data.forEach((line, index) => {
      if (!valid) {
        return;
      }

      if (line.length != 6) {
        alert("Outlaw must have exactly 6 columns");
        valid = false;
        return;
      }

      line.forEach(word => {
        if (!valid) {
          return;
        }

        if (!word.trim() && index != data.length) {
          alert("empty words are not valid - line " + (index + 1));
          valid = false;
          return;
        }
      });

      if (line[0].length > 32) {
        alert("no word must be bigger than 32 characters - line " + (index + 1));
        valid = false;
        return;
      }
    });
    return valid;
  }

  const validator = {
    scribbler: validateScribbler,
    outlaw: validateOutlaw
  }

  const processScribbler = (data) => {
    const words = new Array;
    data.forEach(e => {
      (e as any).forEach(item => {
        const word = item.trim()
        if (word)
          words.push(word);
      })
    });
    return { words };
  }

  const processOutlaw = (data) => {
    const words = new Array;
    data.forEach(item => {
      const word = {};
      item.map(i => i.trim());
      word[item[0]] = [...item].splice(1);
      words.push(word);
    });
    return { words };
  }
  const processor = {
    scribbler: processScribbler,
    outlaw: processOutlaw
  }

  const submit = async (schemeData) => {
    const { formData : submittedFormData } = schemeData;

    const gameName = submittedFormData.game.toLowerCase();

    if (!csvData) {
      alert("Please Upload a csv file");
      return;
    }

    if (submittedFormData.hasHeader)
      delete csvData[0];

    if (!validator[gameName](csvData)) {
      return;
    }

    const gamePackData = processor[gameName](csvData);

    const pack: any = {
      ...gamePackData,
      ...submittedFormData,
      game: gameName
    };

    pack.owner = submittedFormData.owner ?? null;
    pack.id = formData.id;
    const response = await fetch(`${process.env.FUNCTION_URL}/packs`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST', body: JSON.stringify(pack)
    });
    if (!response.ok) {
      const responseText = await response.text();
      alert(`Error: ${response.status} - ${responseText}`);
      return;
      // let json = await response.json();
      // console.log(json);
    }
    alert('Done!');

  }

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles);

    let file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      const csv = (reader.result as string) || '';
      parse(csv, {
        complete: (result) => {
          const resultData = result.data as any;
          if (!resultData[resultData.length - 1][0].trim())
           delete resultData[resultData.length - 1]

          setCsvData(result.data);

          console.log(result)
        }
      });
    };

    reader.readAsBinaryString(file);
  }, [])

  const log = (type) => console.log.bind(console, type);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const fileName = files ? (files![0] as any).name : '';
  
  const userData = React.useContext( UserDataContext );
  const role = "packEditor";
  return (

    <Wrapper>
       <Helmet>
          <title> Pack Editor | VEN Games </title>
      </Helmet>
      <div className="main-account">
        <div className="main-header-account">
          <MainHeader darkTheme={true} />
        </div>
        <div className="main-account">
        {
          !userData[role] ? <RequestRolePanel role={role} /> :
          <div>
          <DropboxArea {...getRootProps()}>
            <input {...getInputProps()} />
            <p>{files ? `"${fileName}" uploaded` : ''}</p>
            {
              isDragActive ?
                <p>Drop the files here ...</p> :
                <p> Drag 'n' drop some files here, or click to select files</p>
            }
          </DropboxArea>
            <Form schema={schema}
              formData={formData}
              uiSchema={uiSchema}
              onChange={e => { console.log(e); return setFormData(e.formData) }}
              onSubmit={submit}
              onError={log("errors")}
              noHtml5Validate />
            </div>
        }  

        {/*   
        <div style={{ width: '100%', backgroundColor: 'white' }}>
          <MyReactDataSheet
            data={grid}
            valueRenderer={(cell) => cell.value}
            onCellsChanged={changes => {
              const newGrid = grid.map(row => [...row])
              changes.forEach(({ cell, row, col, value }) => {
                newGrid[row][col] = { ...newGrid[row][col], value }
              })
              setGrid(newGrid)
            }}
          />
        </div> */}

        <footer>
          <div>Virtual Engagement - VEN Games</div>
          <div><a href="mailto:contact@ven.games">Contact@VEN.games</a></div>
          <div><Link to="/termsandconditions">Terms {'&'} Conditions</Link></div>
          <div>Leave Feedback</div>
        </footer>
      </div>
      </div>
    </Wrapper>)
}

const Wrapper = styled.div`
  /* max-width: 1200px; */
  min-height: 100vh;
  min-width: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .main-account{
    background: #eeeeee;
    /* margin: auto; */
  }

 form {
   margin: 40px;
   
   button[type=submit]{
     width: 100%;
     font-size: 1.5rem;
   }
 }
`;

const DropboxArea = styled.div`
  margin: 5%;
  background: lightgray;
  border: dashed 2px black;
  padding: 7%;

  display: flex;
  flex-direction: column;
  align-items: center;
`


export default PackEditor;
