import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from "lodash";

import ChooseHowToPlay from '../ChooseHowToPlay';
import { MainHeader } from '../../organisms/MainHeader';
import { UserDataService } from '@ven/platform/main/services/user/UserDataService';

import teamsTopDesktop from '@ven/platform/main/assets/images/homepage/teams-desktop-top.png';
import teamsTopMobile from '@ven/platform/main/assets/images/homepage/teams-mobile-top.png';

import curveSectionBottom from '@ven/platform/main/assets/images/homepage/curve-section-bottom.svg';
import curveSectionTop from '@ven/platform/main/assets/images/homepage/curve-section-top.svg';

import closeWhite from '../../../assets/images/close_white.svg';
import wiggle from '../../../assets/images/homepage/wiggle.svg';

import backgroundShapes from '@ven/platform/main/assets/images/homepage/background-stuff.png';
import bulletPoint from '@ven/platform/main/assets/images/homepage/bullet-point.png';
import guyHoldingDiv from '@ven/platform/main/assets/images/homepage/guy-holding-div.svg';
import guyHoldingDivArms from '@ven/platform/main/assets/images/homepage/guy-holding-div-arms.svg';

import { ChooseGameCarossel } from '@ven/shared/components/common/atoms/ChooseGameCarossel';
// import './styles.css'
import styled from '@emotion/styled';
import { GreenButton } from '@ven/shared/components/common/atoms/GreenButton';
import { Helmet } from 'react-helmet';

const RoomSession = () => {
    const [vipMsgClosed, setVipMsgClosed] = useState(false);
    const [modalView, setModalView] = useState(0);
    const [chooseModal, setChooseModal] = useState(false);
    const history = useHistory();

    const clickPlayGames = () => {
        UserDataService.clear('choosenGame');
        const uid = UserDataService.get('uid');
        const isAnon = UserDataService.get('isAnon');
        if (uid && isAnon === 'false') {
            window.scroll(0, 0);
        } else {
            window.scroll(0, 0);
            setModalView(0);
            setChooseModal(true);
        }
    }

    const clickedContinueWithoutRegister = () => {
        setChooseModal(false);
        window.scroll(0, 0);
    }

    const openFreebieFlow = (modalView: number) => {
        window.scroll(0, 0);
        UserDataService.clear('choosenGame');
        setModalView(modalView);
        setChooseModal(true);
    }

    const openLoginModal = () => {
        setChooseModal(false);
        const loginButton = document.getElementsByClassName('login')[0];
        loginButton.click();
    }

    const openSignupModal = () => {
        setChooseModal(false);
        const loginButton = document.getElementsByClassName('signup')[0];
        loginButton.click();
    }

    useEffect(() => {
        // const query = window.location.pathname;
        // if (query === '/team') {
        //     localStorage.setItem('team', 'true');
        // }
    });

    return (
        <Wrapper>
            <Helmet>
                <title> Session | VEN Games </title>
            </Helmet>
            <div className="full-container">
                {/* Choose How To Play Modal */}
                {chooseModal && (
                    <div className="choose-modal">
                        <div className="full-screen-close" onClick={() => setChooseModal(false)} />
                        <ChooseHowToPlay chooseCloseCallback={clickedContinueWithoutRegister} modalView={modalView} loginCallback={openLoginModal}
                         signupCallback={openSignupModal}></ChooseHowToPlay>
                    </div>
                )}

                <div className="homepage-container">
                    <div className="homepage-content">
                                    {/* {!vipMsgClosed &&
                                        <div className="vip-header" onClick={() => setVipMsgClosed(true)}>
                                            <div className="cool-message">
                                                <div>
                                                    <p>We’re still working out some kinks, but we’re sure you’ll have fun anyway. Please let us know what you think. Enjoy!</p>
                                                </div>
                                                <img src={closeWhite} alt="close" />
                                            </div>
                                        </div>
                                    } */}
                        <div className="anytime">
                            <img src={wiggle} alt="" />
                            <h1>Play awesome games with friends. Anytime, anywhere.</h1>
                            <p>Play your favorite party games online, now beautifully designed and fun for all ages.</p>
                        </div>
                        <div className="games">
                            <ChooseGameCarossel buttonsOnTop={true} />
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>)
}

const Wrapper = styled.div`
        background-image: none;
        background: #FAFAFA;
        background-size: cover;
        background-repeat: no-repeat;
    
        /* change to 100% */
        margin-top: -10px;

    @media(max-width: 700px) {
        .bottom {
            width: 40.0rem;
        }
        .homepage-container .homepage-content .full-container{
            width: 384px;
        }
    }
    .games::after {
        content: '';
        background-image: url(${curveSectionBottom});
        background-size: 100% 210px;
        background-repeat: no-repeat;
        display: block;
        height: 200px;
        margin-top: -180px;
        
        @media(max-width: 700px) {
            display: none;
        }
    }
  `

const CallToAction = styled.div`
    background-image: url(${teamsTopDesktop});
    background-repeat: no-repeat;
    /* background-position: center; */
    background-size: contain;
    width: 130rem;
    height: 80rem;
    display: grid;
    color: #FFFFFF;
    font-family: Bitter;

    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: 115px 0.7fr 1fr;
    grid-template-areas:
    "main-header main-header"
    "content ."
    ". .";
    gap: 0px 0px;

    h1 {
        margin: 2.0rem;
    }
    .content p {
        font: 2.5rem Oswald;
        margin: 2.0rem;
    }
    .vip-header {
        cursor: pointer;
     }
    .main-header {
        grid-area: main-header;
     }
    .content { 
        grid-area: content;
        margin: auto;

        button {
            margin-top: 3rem;
        }
     }

     
    @media(max-width: 700px) {
        background-image: url(${teamsTopMobile});
        width: initial;
        height: 62rem;
        grid-template-columns: 1fr;
        grid-template-rows: 115px 1fr;
        grid-template-areas:
        "main-header"
        "content";
        .content { 
            grid-area: content;
            margin: 0;
            text-align: center;
            
            button {
                margin: 5rem auto;
            }
        }
     }

  `

const WhyVenContainer = styled.div`
    display: grid;
    grid-template-columns: 0.5fr 700px 110px 1fr;
    grid-template-rows: 140px 90px 60px 1fr 75px;
    gap: 0px 0px;
    .circle-background { grid-area: 1 / 4 / 2 / 5; }
    .why-ven-text { grid-area: 1 / 2 / 3 / 3; z-index: 1; }
    .bullet-points { grid-area: 4 / 2 / 5 / 4; z-index: 1; }
    .background-shapes { grid-area: 2 / 1 / 5 / 3; }
    .guy { grid-area: 2 / 3 / 6 / 5; }

    p{
        font: 2.7rem Oswald
    }
    ul {
        list-style: none;
        padding: 0;
    }
    li {
        font: 2.5rem Oswald;
        padding-left: 1.3em;
        margin: 25px
    }
    li:before {
        content: '';
        background-image: url(${bulletPoint});
        margin-right: 15px;
        display: block;
        height: 43px;
        width: 55px;
        float: left;
    }
    .guy { 
        background-image: url(${guyHoldingDiv});
        display: block;
        background-repeat: no-repeat;
    }
    .guy-arms { 
        background-image: url(${guyHoldingDivArms});
        display: block;
        background-repeat: no-repeat;
        z-index: 2;
    }
    .bullet-points { 
        background-color: #FFFFFF;
        box-shadow: 0 1px 10px lightgrey;
        border-radius: 12px;
        margin: 25px;
        padding: 25px;

        &:after {
            content: '';
            background-image: url(${guyHoldingDiv});
            margin-right: 15px;
            display: block;
            height: 43px;
            width: 55px;
            float: left;
            position:absolute;
        }
    }
    .background-shapes { 
        background-image: url(${backgroundShapes});
        background-repeat: no-repeat;
        background-size: contain;
        background-size: 160px 160px;
    }

    @media(max-width: 700px) {
        grid-template-columns: 1fr;
        grid-template-rows: 0.5fr 0.5fr;
        grid-template-areas:
        "why-ven-text"
        "bullet-points";
        .circle-background { display: none }
        .why-ven-text { grid-area: why-ven-text; z-index: 1; margin: 5rem; margin-top: 0 }
        .bullet-points { grid-area: bullet-points; z-index: 1; }
        .background-shapes { display: none }
        .guy { display: none }
        .guy-arms { display: none }

        li {
            /* font: 2.5rem Oswald; */
            margin: 0px;
            padding: 0;
            margin-top: 25px;
        }
    }
`

export default RoomSession;
