import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from "lodash";

import { UserDataService } from '@ven/platform/main/services/user/UserDataService';

import styled from '@emotion/styled';
import { GreenButton } from '@ven/shared/components/common/atoms/GreenButton';
import { Helmet } from 'react-helmet';
import RoomSession from '.';
import { InitZoom } from '@ven/platform/main/services/RoomSessionService';
import { useMemo } from 'react';
import { PacmanLoader } from 'react-spinners';

const ZoomRoomSession = () => {

  const [zoomAuth, setZoomAuth] = useState(false)
  useMemo(async () => {
    const query = new URLSearchParams(window.location.search);
    const result = await InitZoom(query.get('code'));
    setZoomAuth(result);

  }, [])
  useEffect(() => {

    // const query = window.location.pathname;
    // if (query === '/team') {
    //     localStorage.setItem('team', 'true');
    // }
  });

  return (
    <Wrapper>
      {
        zoomAuth
          ? <RoomSession />
          : <PacmanLoader
            size={35}
            color={"white"}
            loading={true}
          />
      }
    </Wrapper>
  )
}

const Wrapper = styled.div``;

export default ZoomRoomSession;
