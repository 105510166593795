import React from 'react';

import type { PlayerID } from '@ven/core/gameplay/GameSessionData';
import { PlayerAvatar } from '@ven/shared/components/game/molecules/PlayerAvatar';
import { GameStateContext } from '../../gameplay/GameStateContext';

import styled from '@emotion/styled';

type Foul = {
  caller:PlayerID
}

export const FoulBit:React.FC<{ foul:Foul }> = ({ foul }) => 
{
  const { game } = React.useContext( GameStateContext);
  const playerAvatar = game?.data?.players?.[ foul.caller ]?.avatar
  const playerTeam = game?.data?.players?.[ foul.caller ]?.team
  return (
    <Wrapper>
      <FoulBubble>
        Oops, an outlaw word was said!
      </FoulBubble>
      <PlayerAvatar size={100} index={ playerAvatar }
      label={game.getTeamName(playerTeam)}/>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 380px;
  transform: translate(-50%,0%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transform-origin: bottom center;
  @keyframes zoom { 
    from { transform: translate(-50%,0%) scale(.125); } 
  };
  animation: zoom 500ms;
  animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
`

const FoulBubble = styled.div`
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  padding: 20px 10px;

  color: #FC3B68;
  background: #FFE499;
  border: 5px solid #FC3B68;
  border-radius: 12px;

  width: 380px;
  max-width: 80vw;
  
  transform-origin: bottom center;
	position: relative;
  margin-bottom: 28px;
  
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 23px solid transparent;
    border-top-color: #fc3b68;
    border-bottom: 0;
    margin-left: -23px;
    margin-bottom: -23px;
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-bottom: 0;
    margin-left: -15px;
    margin-bottom: -15px;
	  border-top-color: #ffe499;
  }

  /* @keyframes zoom-center { from { opacity: 0; transform: translate(-50%,-50%) scale(.5); } }
  animation: zoom-center 150ms; */
`
