import React from 'react';

import { GameStateContext } from '@ven/games/scribbler/gameplay/GameStateContext';

import { Spacer } from '@ven/shared/components/common/atoms/Separator';
import { PlayerAvatar } from '@ven/shared/components/game/molecules/PlayerAvatar';
import { LargeText, OverlayDialogBase, SmallText } from './OverlayDialogBase';
import styled from '@emotion/styled';
import { MOBILE_BREAK_PX } from '@ven/shared/components/styling/breakpoints';
import { COLOR_GRAY } from '@ven/shared/components/styling/colors';
import { TimerCircleProgress } from '@ven/shared/components/game/molecules/TimerCircleProgress';

export const OverlayDialog_Wait:React.FC = () =>
{
  const { game, data } = React.useContext( GameStateContext )!
  const currentPlayerId = data?.state?.turn?.player || ''
  const currentPlayerName = data?.players?.[ currentPlayerId ]?.username
  const currentPlayerAvatar = data?.players?.[ currentPlayerId ]?.avatar
  const currentPlayerOnline = data?.presences?.[ currentPlayerId ]?.online

  const timeout = data?.state?.turn?.timeout || ''
  const totalTime = game.getConfig()!.wordTime;

  const Hint = SmallText
  const Text = LargeText

  const roundLabelText = !! data?.state?.round?.number ? `Round: ${ data?.state?.round?.number } / ${ data.config.rounds }` : `...`

  return (
    <OverlayDialogBase style={{ 
      width : `405px`,
    }}>
       <TimeoutClock>
            <TimerCircleProgress time={timeout} totalTime={totalTime} 
              radius={35} displayTime={false} stroke={4}
              color={"#303744"} />
              <PlayerAvatar size={52} index={ currentPlayerAvatar }
                transparent={!currentPlayerOnline} />
          </TimeoutClock>


      {/* <PlayerAvatar size={100} index={ currentPlayerAvatar } */}
        {/* transparent={!currentPlayerOnline} /> */}
      <Spacer size={20} />
      <Text> Wait for { currentPlayerName } to choose what to draw... </Text>
      <Hint> Try to guess as quickly as possible to score more points. </Hint>
      <Spacer size={10} />
      <Round> { roundLabelText } </Round>
      <Hint>{ currentPlayerName }'s turn will be skipped {!currentPlayerOnline ? 'soon' : `in ${timeout}` }...</Hint>
    </OverlayDialogBase>
  )
}

const TimeoutClock = styled.div`
  display: grid;
  
  .avatar-container {
    grid-area: 1/1;
  }
  .circle-clock {
    grid-area: 1/1;
  }
`;

const Round = styled.div`

  @media (min-width: ${ MOBILE_BREAK_PX }px) {
    display: none;
  }

  color: ${ COLOR_GRAY };
  width: 100%;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    width: 8rem;
    margin: 1rem -10rem;
    border-top: 1px solid;
  }
  
  &:after {
    content: '';
    position: absolute;
    width: 8rem;
    margin: 1rem 2rem;
    border-top: 1px solid;
  }
`
