import React from 'react';

import styled from '@emotion/styled';

import { getRandomFrom } from '@ven/shared/core/utils/arrays';

import AVATAR_1 from "@ven/shared/components/assets/avatars/1.svg"
import AVATAR_2 from "@ven/shared/components/assets/avatars/2.svg"
import AVATAR_3 from "@ven/shared/components/assets/avatars/3.svg"
import AVATAR_4 from "@ven/shared/components/assets/avatars/4.svg"
import AVATAR_5 from "@ven/shared/components/assets/avatars/5.svg"
import AVATAR_6 from "@ven/shared/components/assets/avatars/6.svg"
import AVATAR_7 from "@ven/shared/components/assets/avatars/7.svg"
import AVATAR_8 from "@ven/shared/components/assets/avatars/8.svg"
import AVATAR_9 from "@ven/shared/components/assets/avatars/9.svg"
import AVATAR_10 from "@ven/shared/components/assets/avatars/10.svg"
import AVATAR_11 from "@ven/shared/components/assets/avatars/11.svg"
import AVATAR_12 from "@ven/shared/components/assets/avatars/12.svg"
import AVATAR_13 from "@ven/shared/components/assets/avatars/13.svg"
import AVATAR_14 from "@ven/shared/components/assets/avatars/14.svg"
import AVATAR_15 from "@ven/shared/components/assets/avatars/15.svg"
import AVATAR_16 from "@ven/shared/components/assets/avatars/16.svg"
import AVATAR_17 from "@ven/shared/components/assets/avatars/17.svg"
import AVATAR_18 from "@ven/shared/components/assets/avatars/18.svg"
import AVATAR_19 from "@ven/shared/components/assets/avatars/19.svg"
import AVATAR_20 from "@ven/shared/components/assets/avatars/20.svg"
import AVATAR_21 from "@ven/shared/components/assets/avatars/21.svg"
import AVATAR_22 from "@ven/shared/components/assets/avatars/22.svg"
import AVATAR_23 from "@ven/shared/components/assets/avatars/23.svg"
import AVATAR_24 from "@ven/shared/components/assets/avatars/24.svg"
import Badge from 'react-bootstrap/esm/Badge';

export const bookOfTheAvatars:Record<number,string> = {

  2 : AVATAR_2,
  3 : AVATAR_3,
  4 : AVATAR_4,
  // 5 : AVATAR_5,
  6 : AVATAR_6,
  7 : AVATAR_7,
  8 : AVATAR_8,
  9 : AVATAR_9,
  10 : AVATAR_10,
  11 : AVATAR_11,
  12 : AVATAR_12,
  13 : AVATAR_13,
  14 : AVATAR_14,
  15 : AVATAR_15,
  16 : AVATAR_16,
  17 : AVATAR_17,
  18 : AVATAR_18,
  19 : AVATAR_19,
  20 : AVATAR_20,
  21 : AVATAR_21,
  22 : AVATAR_22,
  23 : AVATAR_23,
  24 : AVATAR_24,
  25 : AVATAR_1
}

export const getRandomAvatar = () => {
  try {
    return getRandomFrom( Object.keys(bookOfTheAvatars) )
  } catch ( e ) {
    return 2;
  }
}

const defaultAvatarKey = 2

const Image = styled.img<{width?:number,height?:number}>`
  background: #8080802F;
  overflow: visible;
  border-radius: 50%;
  width: ${ props => props.width ? props.width+'px' : 'auto' };
  height: ${ props => props.height ? props.height+'px' : 'auto' };
`

type Props = {
  size?: number,
  src?:string,
  index?:number|string,
  noHeight?:true
  transparent?:boolean
  label?:string
}

export const PlayerAvatar:React.FC<Props> = ({ size, src, index, noHeight, transparent, label, ...props }) => {
  src = src || bookOfTheAvatars[ index! ] || bookOfTheAvatars[defaultAvatarKey]
  return React.useMemo( 
    () => <AvatarWrapper className={"avatar-container"}>
        <Image className={`avatar ${transparent ? 'transparent' : ''}`} src={ src } height={ noHeight ? undefined : size } width={ size } {...props} />
        {label ? <Badge variant="dark">{label}</Badge> : ''}
      </AvatarWrapper>,
    [ src, size, transparent, label ]
  )
}

const AvatarWrapper = styled.div` 

  display: grid;

  img {
    align-self: center;
    justify-self: center;
    grid-area: 1/1;
  }

  img.transparent {
    opacity: 0.5;
  }

  .badge {
    align-self: end;
    justify-self: center;
    grid-area: 1/1;
    margin-bottom: -10px;
  }
` 
