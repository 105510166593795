import React from 'react';

import { UpdateUsernameAvatar } from '@ven/platform/main/services/user/UserDataService';
import { UserDataContext } from '@ven/platform/main/services/user/UserDataContext';
import { bookOfTheAvatars, PlayerAvatar } from '../../game/molecules/PlayerAvatar';
import { rangeFromTo, shuffle } from '@ven/core/utils/arrays';

import type { WithTheme } from '../../prop-types';
import styled from "@emotion/styled";

export const SelectAvatarSlider:React.FC<WithTheme> = ({ theme = "" }) =>
{
  const user = React.useContext( UserDataContext );
  const myAvatarKey = user?.avatar

  const [firstIndex, setFirstIndex] = React.useState(0);
  const itemsPerPage = 6;

  const [allAvatarOptions] = React.useState( shuffle( Object.entries( bookOfTheAvatars ) ).map( ([key,src],i)=>({key,src,order:i}) ) );

  const indicesToShow = rangeFromTo( firstIndex, firstIndex + itemsPerPage );

  const onSelect = avatarKey => {
    UpdateUsernameAvatar( undefined, ''+avatarKey )
    // onChange?.( ''+avatarKey )
  }

  return (
    <Container className={`avatar-catalog ${theme}`}>
      <button onClick={ () => setFirstIndex(Math.max( 
        firstIndex - itemsPerPage, 0
      )) } className={`arrow arrow-left ${firstIndex == 0 ? `hide` : null}`}> <ArrowLeftSVG/> </button>
      {
        indicesToShow.map( (avatarIndex,i) => (
          <div 
          key={ avatarIndex }
          onClick={ () => onSelect( allAvatarOptions[ avatarIndex ].key ) } 
          className={ [
            "avatar-pad",
            "pos-" + ( +allAvatarOptions[ avatarIndex ].order - firstIndex ),
            ( allAvatarOptions[ avatarIndex ].key === myAvatarKey ? 'selected' : '' ),
          ].join(' ') } >
            <PlayerAvatar src={ allAvatarOptions[ avatarIndex ].src } size={ 88 } noHeight={ true } />
          </div>
        ) )
      }
      <button onClick={ () => setFirstIndex(Math.min( 
        firstIndex + itemsPerPage, allAvatarOptions.length - itemsPerPage 
      )) } className={`arrow arrow-right ${firstIndex == allAvatarOptions.length - itemsPerPage  ? `hide` : null}`}> <ArrowRightSVG/> </button>
    </Container>
  )
}

const ArrowLeftSVG = props => (
  <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M11.533 21.916L1.683 12.066L11.683 2.231" strokeWidth="3" strokeLinecap="round"/>
  </svg>
)
const ArrowRightSVG = props => (
  <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M2.467 2.084L12.317 11.934L2.317 21.769" strokeWidth="3" strokeLinecap="round"/>
  </svg>
)

const Container = styled.div`
position: relative;

margin-top: 10px;
width: 100%;
min-height: 216px;
/* overflow: hidden; */
box-sizing: border-box;

display: flex;
flex-direction: row;
justify-content: space-evenly;
align-items: center;
flex-wrap: wrap;

padding: 0 10px;


&.black button.arrow svg {
  stroke: #303744;
}

button.arrow  {
  background: none;
  border: none;
  stroke: white;
  height: 100%;
  width: 20px;
  overflow: visible;
  cursor: pointer;
  &:focus, &:active { 
    outline: none;
  }

  position: absolute;
  &.arrow-left {
    left:-20px;
  }
  &.arrow-right {
    right:-20px;
  }

  &.hide {
    visibility: hidden;
  }
}

.avatar-pad {
  cursor: pointer;
  width: 82px;
  /* height: 82px; */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;

  flex: 1 1 30%;

  margin: 4px 2px;

  @media (min-width: 410px) {
    margin: 10px 0;
  }
  
  .avatar {
    transition: transform 500ms, border 800ms;
    max-width: 15vw;
    
    border: solid #0000 4px;
    border-radius: 50%;
  }

  &.selected {
    .avatar {
      transition: transform 300ms, border 300ms;
      transform: scale( 1.2 );

      border: solid white 4px;
    }
  }
}
`
