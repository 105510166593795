import React from 'react';

import { GAMES } from '@ven/platform/main/games';

import { LoadingView } from '@ven/shared/components/common/organizms/Loading';

import { GameFunctionsService } from '@ven/core/services/GameFunctionsService';
import { UserDataService } from '../services/user/UserDataService';
import { UserDataContext } from '../services/user/UserDataContext';
import { Helmet } from 'react-helmet';
import _ from 'lodash';

export const RoomCodeRouteWrapper:React.FC<{ code: string }> = ({ code }) => 
{
  const { uid } = React.useContext( UserDataContext );

  const userId = uid || UserDataService.get("uid");

  const [{roomId,gameId,error},setRoomPointerData] = React.useState<any>({});
  
  React.useEffect(() => {
    GameFunctionsService.getRoomData( code )
      .then( data => setRoomPointerData( data || { error: "Room missing" } ) );
  }, [code])

  if ( error ) {
    window.location.href = "/"
    return null
  }

  if ( ! roomId ) {
    return <LoadingView />
  }

  const props = { roomCode: code, userId, roomId, gameId };

  const Component:React.FC<any> = GAMES[ gameId ].renderPlayerPage;
  const DataProvider:React.FC<any> = GAMES[ gameId ].provideData!;

  return ( 
    <DataProvider { ...props }>
      <Helmet>
          <title> {_.capitalize(gameId)} {code} | VEN Games </title>
      </Helmet>
      <Component { ...props } />
    </DataProvider>
  )
}

export type GameRoomProps = {
  roomCode: string, 
  userId: string, 
  roomId: string, 
  gameId: string,
}
