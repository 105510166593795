import React from 'react';

import styled from '@emotion/styled';
import CanvasTextWrapper from '@ven/core/utils/canvasTextWrapper';

interface Props {
  config: {
    width: number,
    height: number,
  }
  sentence: string,
  backgroundColor?: string,
}
export const SentenceCanvas: React.FC<Props> = ({ config, sentence, children, backgroundColor }) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [context, setContext] = React.useState<CanvasRenderingContext2D | null>(null);

  const { width, height } = config

  const drawData = () => {
    if (canvasRef.current?.['__done_flag'] === true) {
      console.log(`Warning: [SentenceCanvas] Canvas drawData() was called multiple times!!`);
      return;
    }
    canvasRef.current!['__done_flag'] = true;

    if (context) {
      context.clearRect(0, 0, width, height);

      if (backgroundColor) {
        context.fillStyle = backgroundColor;
        context.fillRect(0, 0, width, height);
      }

      context.fillStyle = "#303744";

      const textWrapperConfig = { 
        font: "18px 'Coming Soon', 'Lucida Console'", 
        textAlign: "center", 
        verticalAlign: 'middle', 
        lineHeight: 2,
        sizeToFill: true,
        paddingX: 25,
        paddingY: 55,
        maxFontSizeToFill: 80
      };
     
      CanvasTextWrapper(canvasRef.current, sentence, textWrapperConfig)
    }
  }

  context && drawData();

  React.useEffect(() => {
    if (!canvasRef.current) {
      return
    }
    if (!context) {
      const ctx = canvasRef.current.getContext('2d');
      ctx && setContext(ctx);
    }
  }, [!!canvasRef.current]);

  return (
    <Wrapper
      className="sentence-canvas "
      width={width}
      style={{
        width: width + "px",
        height: height + "px",
      }}>
      <RealCanvas
        id="canvas"
        ref={canvasRef}
        width={width}
        height={height}
      />
      { children}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ width: number }>`
  position: relative;
  overflow: hidden;
  @media (min-width: ${props => props.width}px) {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  canvas {
    cursor: grabbing;
  }
`

const RealCanvas = styled.canvas<{ width: number }>`
  width: 100%;
  height: 100%;
`
