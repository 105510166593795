import firebase from "firebase";
import { DEVELOPMENT_MODE } from "../debug/consts";

export const VipCodeService = {

    url : process.env.FUNCTION_URL, //'http://localhost:5001/ven-games---staging/us-central1/app'; // ;
    // url: 'http://localhost:5001/ven-games---staging/us-central1/app',

  async getAll(callback) {
    await firebase
      .firestore()
      .collection("vip-codes")
      .orderBy("createdDate", "desc")
      .onSnapshot((data) => {
        const items = data.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))
    
        callback(items)
    });
  },

  async create(data: any) {
    const response = await fetch(`${this.url}/vip/create`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST', body: JSON.stringify(data)
    });
    if (!response.ok) {
      const responseText = await response.text();
      console.log(`Error: ${response.status} - ${responseText}`);
    }

    return response.json;
    // data.createdDate = Date.now();
    // return await firebase
    //   .firestore()
    //   .collection("vip-codes")
    //   .add({
    //     ...data
    //   });
  },

  async delete(id: any) {
    return await firebase
      .firestore()
      .collection("vip-codes")
      .doc(id).delete()
  },

  async get(name: string) {
    const itemsSnapshot = await firebase
      .firestore()
      .collection("vip-codes")
      .where("code", "==", name)
      .get();

    const items = itemsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }))
''
    const data = [...items];
    return data;
  },

  async token(code: string, userId: string) {
    if(!code) {
      return {
        error: "Empty code"
      }
    }
    const response = await fetch(`${this.url}/vip/token`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST', body: JSON.stringify({code, userId})
    });
    const json = await response.json();
    if (!response.ok) {
      console.log(`Error: ${response.status} - ${json.error}`);
      // console.log(json);
    }
    return {...json, ...response}
  },

  async check(token: string) {
    const response = await fetch(`${this.url}/vip`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST', body: JSON.stringify({token : token})
    });
    if (!response.ok) {
      const json = await response.json();
      // alert(`Error: ${response.status} - ${responseText}`);
      console.log(json);
    }

    return response.ok;
  },
}



DEVELOPMENT_MODE && (window['VipCodeService'] = VipCodeService);
