import React from 'react';

import { UserDataContext } from '@ven/platform/main/services/user/UserDataContext';
import { getReferrerIdFromUrl } from '@ven/platform/main/services/user/UserDataService';

import { GameStateContext } from '../../../gameplay/GameStateContext';

import { Spacer } from '@ven/shared/components/common/atoms/Separator';
import { CustomGameConfigurationContent } from './CustomGame';
import { PadContent_ShareGame, PadContent_StandBy, PregamePageWrapper, UsernameAndAvatarForm } from '@ven/shared/components/common/pregame/common';
import { CircledAvatarAtTheTop, HowToPlayLink, InvitedText, JoinButton, PleaseEnterNameTip } from '@ven/shared/components/common/pregame/JoinModal';

import { HowToPlay_Scribbler } from '../desktop/HowToPlay';

import styled from "@emotion/styled";
import { MainHeader } from '@ven/platform/main/components/organisms/MainHeader';
import { CustomGamePad, PlayersList_Basic } from '@ven/shared/components/common/pregame/CustomGame';
import { SwitchViewMode } from '@ven/shared/components/common/SwitchViewMode';

export const PreGameContent:React.FC = () => 
{
  const { game } = React.useContext( GameStateContext )
  const myUserData = React.useContext( UserDataContext );

  const referer = getReferrerIdFromUrl()
  const refererData = game.data.players[ referer! ]

  const [ showHowToPlay, setShowHowToPlay ] = React.useState( false )

  const myUserId = game.myUserId!;
  const players = Object.keys( game.data.players || {} )

  const MINIMUM_PLAYERS_COUNT = 2
  const missingPlayersCount = Math.max( 1, MINIMUM_PLAYERS_COUNT - players.length )

  const isWatchUrl = window.location.href.includes('/watch/');
  if(isWatchUrl) {
    game.forceSpectatorMode = true;
    if(!game.amHost() && (!myUserData.eventHost && game.data.eventHost))
      return <div>
          <MainHeader />
          <SwitchViewMode game={game} isSpectator={true}/>
      </div>
  }

  return (
    <PregamePageWrapper>
      <MainHeader />

      {
        showHowToPlay &&
        <HowToPlay_Scribbler handleClose={ () => setShowHowToPlay( false ) } absolute={ false } />
      }

      <Spacer size={ 70 } />
      {
         myUserData.eventHost && game.data.eventHost
         ?
         // EventHost
         <CustomGameConfigurationContent openHowToPlay={() => setShowHowToPlay(false)} />
        :
        ! game.haveJoined()
        ? 
        // Not joined
        <BluePad> 
          <CircledAvatarAtTheTop index={ refererData?.avatar || myUserData?.avatar } />

          <Spacer size={ 48 } />

          <InvitedText gameName="Scribbler" refererData={ refererData } />

          <UsernameAndAvatarForm />
          <div>
            <div className="column">
              <div className="label">Players: </div>
            </div>
            <PlayersList_Basic game={ game } />
            {
              ! game.canStartGame() && <>
                <Spacer size={ 28 } />
                <div className="hint"> Waiting for at least { missingPlayersCount } more player{ missingPlayersCount > 1 && 's' }. </div> 
                <Spacer size={ 28 } />
              </>
            }
          </div>

          <JoinButton username={ myUserData?.username } game={ game } />
          
          <Spacer size={ 50 } />

          <HowToPlayLink openHowToPlay={ () => setShowHowToPlay( true ) }/>
        </BluePad>
        :
        game.data.host === myUserId && !game.data?.eventHost
        ? 
        // Joined, Host
        <CustomGameConfigurationContent openHowToPlay={ () => setShowHowToPlay( true ) } />
        :
        // Joined, not Host
        <BluePad> 
          <CircledAvatarAtTheTop index={ refererData?.avatar || myUserData?.avatar } />

          <Spacer size={ 48 } />

          <InvitedText gameName="Scribbler" refererData={ refererData } />

          <UsernameAndAvatarForm />

          <div>
            <div className="column">
              <div className="label">Players: </div>
            </div>
            <PlayersList_Basic game={ game } />
            {
              ! game.canStartGame() && <>
                <Spacer size={ 28 } />
                <div className="hint"> Waiting for at least { missingPlayersCount } more player{ missingPlayersCount > 1 && 's' }. </div> 
                <Spacer size={ 28 } />
              </>
            }
          </div>
      
          <PadContent_StandBy game={ game } />

          { !game.data?.eventHost && <PadContent_ShareGame refererId={ myUserId } game={ game } /> }

          <Spacer size={ 100 } />

          <HowToPlayLink openHowToPlay={ () => setShowHowToPlay( true ) }/>
        </BluePad>
      }
    </PregamePageWrapper>
  )
}

const BluePad= styled.div`
  width: 475px;
  /* height: 773px; */
  max-width: 90vw;

  background: #3148BF;
  box-shadow: -6px 7px 28px rgba(0, 0, 0, 0.0819766);
  border-radius: 12px;
  position: relative;

  text-align: center;
  box-sizing: border-box;
  padding: 52px;
  margin-bottom: 140px;

  user-select: none;

  &::before {
    content: '';
    border: 6px solid #FFFFFF;
    border-radius: 11px;
    position: absolute;
    top: 26px;
    bottom:26px;
    left: 22px;
    right: 22px;
    pointer-events: none;
  }

  .avatar-wrapper {
    width: 100px;
    height: 100px;
    border: 6px solid #FFFFFF;
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 20px;
    left: 50%;
    border-radius: 50%;
    background: #3148BF;
  }

  .label {
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF;
    padding-top: 30px;
    padding-left: 5px;
    text-align: left;
  }

  .team-selector {
    padding-top: 10px;
    display: inline-block;
    width: 100%;

    .Dropdown-control {
      transition: none;
      height: 67px;
      border-radius: 7px;
      padding: 0;
      
      max-width: 385px;
      width: 100%;

      font-family: Bitter;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 67px;
      text-align: center;

      overflow: visible;

      color: #3148BF;
    }

    .Dropdown-menu {
      overflow: hidden;
      border-radius: 7px;
    }

    .Dropdown-option {
      font-family: Bitter;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 50px;
    }

    .Dropdown-arrow {
      right: 20px;
      top: 30px;
    }
  }

  .button-play {
    width: 310px;
    display: inline-block;
  }

  .how-to-tip {
    position: absolute;
    left: 50%;
    bottom: 26px;
    transform: translate(-50%, 0%);
    width: 100%;
    pointer-events: none;

    font-family: Bitter;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 100px;
    text-align: center;

    color: #FFFFFF;

    a {
      color: #2FB586;
      cursor: pointer;
      pointer-events: all;
    }
    a:hover {
      text-decoration: underline;
    }
  }

  .share-label-large {
    font-family: Bitter;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
  }

  .share-label-small {
    font-family: Bitter;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
  }

  .playersList .playersColumn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    flex: 1 1;
    padding-bottom: 30px;

    .playerListItem {
      padding-top: 30px;
      flex-basis: 50%;

    .name {
        padding-top: 8px;
        font-family: Oswald;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
      }
    }
  }
`
