import React from 'react'

import styled from '@emotion/styled';
import { MOBILE_BREAK_PX } from '@ven/shared/components/styling/breakpoints';

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  overflow: hidden;
  
  /* position: relative; */
  box-sizing: border-box;
  overflow: auto;

  padding-top: 0px;
  transition: padding-top 200ms;

  &.pregame{
    height: auto;
    overflow-x: hidden;
  }

  @media (min-width: ${ MOBILE_BREAK_PX }px) and (min-height: ${ MOBILE_BREAK_PX }px) {
    /* margin-top: 120px;
    height: calc(100vh - 120px); */
    /* padding-top: 120px; */
  }
`

export const MainContent = props => <StyledMain { ...props } className={ "main-content " + (props.className??'') }/>
