import React from 'react';

import venGameLogo from '../../../assets/images/homepage/logo_vengames.png';
import closeWhite from '../../../assets/images/close_white.svg';

import './styles.scss';
import { UserDataService } from '@ven/platform/main/services/user/UserDataService';
import { Link } from 'react-router-dom';

interface MobileMenuProps {
    close: Function,
    outlaw: Function,
    scribbler: Function,
    brokenMsg: Function,
    login: Function,
    signup: Function,
    logout: Function,
    account: Function,
}

const MobileMenu: React.FC<MobileMenuProps> = ({
    close,
    outlaw,
    scribbler,
    brokenMsg,
    login,
    signup,
    logout,
    account
}) => {
    const isAnon = UserDataService.get('isAnon');

    return (
        <div className="menu-landing">
            <div className="menu-content">
                <img className="close" src={closeWhite} alt="close" onClick={() => close()} />
                <div className="menu-items">
                    <span onClick={() => { window.location.assign('/'); }} className="logo-image">
                        <img className="logo" src={venGameLogo} alt="logo" />
                    </span>
                    <div>
                        <button>Game Night</button>
                    </div>
                    {(!isAnon || isAnon === 'false') &&
                    <div>
                        <button onClick={() => account()}>Account</button>
                    </div>
                    }
                        {/* <button>About VEN</button> */}
                    <div>
                        <button onClick={() => outlaw()}>Play Outlaw</button>
                    </div>
                    <div>
                        <button onClick={() => scribbler()}>Play Scribbler</button>
                    </div>
                    <div>
                        <button onClick={() => brokenMsg()}>Play BrokenMsg</button>
                    </div>
                </div>
                <footer>
                    {(!isAnon || isAnon === 'false') &&
                        <button className="login" onClick={() => logout()}>Logout</button>
                    }
                    {(!isAnon || isAnon === 'true') &&
                        <button className="signup" onClick={() => signup()}>Sign Up</button>
                    }
                    {(!isAnon || isAnon === 'true') &&
                        <button className="login" onClick={() => login()}>Login</button>
                    }
                </footer>

            </div>
        </div>
    )
};

export default MobileMenu;
