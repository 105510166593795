import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import wiggleYellow from '@ven/platform/main/assets/images/homepage/wiggle_yellow.svg';
import yellowCurve from '@ven/platform/main/assets/images/homepage/yellow_curve.png';
import buttonOutlaw from '@ven/platform/main/assets/images/homepage/button_outlaw.png';
import buttonScribbler from '@ven/platform/main/assets/images/homepage/button_scribbler.svg';
import buttonBknmsg from '@ven/platform/main/assets/images/homepage/button_bknmsg.png';
import imgOutlaw from '@ven/platform/main/assets/images/homepage/img_outlaw.svg';
import imgScribbler from '@ven/platform/main/assets/images/homepage/img_scribbler.png';
import imgBknmsg from '@ven/platform/main/assets/images/homepage/img_bknmsg.svg';
import venGameLogo from '@ven/platform/main/assets/images/homepage/logo_vengames.png';
import { UserDataService } from '@ven/platform/main/services/user/UserDataService';
import { useHistory } from 'react-router-dom';
import ChooseHowToPlay from '@ven/platform/main/components/pages/ChooseHowToPlay';
import { GameFunctionsService } from '@ven/shared/core/services/GameFunctionsService';
import { DEFAULT_CONFIG as BrokenMsgDefaultConfig } from '@ven/games/brokenmsg/gameplay/GameStateController';
import { DEFAULT_CONFIG as OutlawDefaultConfig } from '@ven/games/wordplay/gameplay/GameStateController';


type Props = {
    buttonsOnTop?: boolean
}
export const ChooseGameCarossel: React.FC<Props> = ({ buttonsOnTop }) => {
    ////TODO refactor this to use useEffect() and events out of the audioService
    const [vipMsgClosed, setVipMsgClosed] = useState(false);
    const [view, setView] = useState(1);
    const [modalView, setModalView] = useState(0);
    const [chooseModal, setChooseModal] = useState(false);
    const [hoverPlay, setHoverPlayer] = useState(false);

    const [isRedirecting, setRedirecting] = useState(false);

    const history = useHistory();
    const [disablePlayButton, setDisablePlayButton] = useState(false);

    const changeView = () => {
        const currentView = view;
        let nextView = currentView + 1;
        if (nextView > 3) nextView = 1;
        setView(nextView);
    }
    
    useEffect(() => {
        const interval = setInterval(() => {
            if(chooseModal || disablePlayButton || isRedirecting || hoverPlay)
                return;

            changeView();
        }, 5000);
        return () => clearInterval(interval);
    });

    const getTopClass = () => {
        if (view === 1) return 'top top-outlaw';
        if (view === 2) return 'top top-scribbler';
        if (view > 2) return 'top top-bknmsg';
        return 'top top-outlaw';
    }

    const getGameNameClass = () => {
        if (view === 1) return 'game-name game-name-outlaw';
        if (view === 2) return 'game-name game-name-scribbler';
        if (view > 2) return 'game-name game-name-bknmsg';
        return 'game-name game-name-outlaw';
    }

    const forceChangeView = (newView: number) => {
        setView(newView);
    }

    useEffect(() => {
        setDisablePlayButton(chooseModal);
    }, [chooseModal]);

    const gameButtons = (top: boolean = false) => {
        return <div className={`game-buttons ${top ? "top" : null}`}>
            <div className={`button-outlaw ${view === 1 ? 'selected' : ''}`} onClick={() => forceChangeView(1)}>
                <img src={buttonOutlaw} alt="Outlaw" />
            </div>
            <div className={`button-scribbler ${view === 2 ? 'selected' : ''}`} onClick={() => forceChangeView(2)}>
                <img src={buttonScribbler} alt="Scribbler" />
            </div>
            <div className={`button-bknmsg ${view > 2 ? 'selected' : ''}`} onClick={() => forceChangeView(3)}>
                <img src={buttonBknmsg} alt="BrokenMsg" />
            </div>
        </div>
    }

    const playGame = (view: number) => {

        setDisablePlayButton(true);
        if(isRedirecting)
            return;

        const views = ['outlaw', 'scribbler', 'brokenmsg']
        const choosenGame = views[view - 1] || views[0];

        UserDataService.set('choosenGame', choosenGame);

        const uid = UserDataService.get('uid');
        const isAnon = UserDataService.get('isAnon');

        if (uid && isAnon && isAnon === 'false') {
            setRedirecting(true)
            GameFunctionsService.createRoom(views[view - 1], uid)
                .then((data) => data && history.push(`/play/${data.code}`));
            return;
        }
        window.scroll(0, 0);
        setModalView(0);
        setChooseModal(true);
    }

    const clickPlayGames = () => {
        UserDataService.clear('choosenGame');
        const uid = UserDataService.get('uid');
        const isAnon = UserDataService.get('isAnon');
        if (uid && isAnon === 'false') {
            window.scroll(0, 0);
        } else {
            window.scroll(0, 0);
            setModalView(0);
            setChooseModal(true);
        }
    }

    const clickedContinueWithoutRegister = () => {
        setChooseModal(false);
        window.scroll(0, 0);
    }

    const openFreebieFlow = (modalView: number) => {
        window.scroll(0, 0);
        UserDataService.clear('choosenGame');
        setModalView(modalView);
        setChooseModal(true);
    }

    const nextView = () => {
        let nxtView = view === 3 ? 1 : view + 1;
        forceChangeView(nxtView);
    }

    const previousView = () => {
        let nxtView = view === 1 ? 3 : view - 1;
        forceChangeView(nxtView);
    }

    const openLoginModal = () => {
        setChooseModal(false);
        const loginButton = document.getElementsByClassName('login')[0];
        loginButton.click();
    }

    const openSignModal = () => {
        setChooseModal(false);
        const loginButton = document.getElementsByClassName('signup')[0];
        loginButton.click();
    }

    return (
        <div className="full-container">
            <div className="preload1"></div>
            <div className="preload2"></div>
            <div className="preload3"></div>
            <div className="preload4"></div>
            <div className="preload5"></div>
            <div className="preload6"></div>
            <div className="preload7"></div>
            <div className="preload8"></div>
            <div className="preload9"></div>

            {/* Choose How To Play Modal */}
            {chooseModal && (
                <div className="choose-modal">
                    <div className="full-screen-close" onClick={() => setChooseModal(false)} />
                    <ChooseHowToPlay chooseCloseCallback={clickedContinueWithoutRegister} modalView={modalView}
                    loginCallback={openLoginModal}
                    signupCallback={openSignModal}
                    ></ChooseHowToPlay>
                </div>
            )}

            <div className={getTopClass()}>
                {buttonsOnTop ? gameButtons(true) : null}
                <div className="logo-container">
                    {buttonsOnTop ? <OurGames>Our Games</OurGames> : <img src={venGameLogo} alt="VEN GAMES" />}
                    {!buttonsOnTop ?
                        <p className="logo-message">Play awesome games with friends. <br /> Anytime, anywhere.</p> : null}
                </div>
                <div className="game-description">
                    <div className="title">
                        <div className={`title ${view === 2 ? 'title-scribbler' : ''}`}>
                            <img src={wiggleYellow} alt="" />
                            <div className={getGameNameClass()}></div>
                            <div className="game-text">
                                {view === 1 &&
                                    <p>Get your teammates to guess as many words as possible without saying any of the forbidden words listed on the card. <br />
                                        From {OutlawDefaultConfig.minPlayersCount} to {OutlawDefaultConfig.maxPlayersCount} players.
                                    </p>
                                }
                                {view === 2 &&
                                    <p>In this quick-draw classic, the guesses can be just as hilarious as the sketches, making it the perfect way to get the party started. <br />
                                        From 2 to 16 players.
                                    </p>
                                }
                                {view > 2 &&
                                    <p>The visual version of the classic "telephone" game... where your sketches {'&'} guesses can create laugh-out-loud party fun! <br />
                                        From {BrokenMsgDefaultConfig.minPlayersCount} to {BrokenMsgDefaultConfig.maxPlayersCount} players.
                                    </p>
                                }
                            </div>
                        </div>
                        <button className="play-game"  disabled={disablePlayButton || isRedirecting} onClick={() => playGame(view)}
                           onMouseEnter={() => setHoverPlayer(true)}
                           onMouseLeave={() => setHoverPlayer(false)}>
                            {isRedirecting ? "Redirecting..." : "Play"}
                        </button>
                        <div className="steppers">
                            <div className="back-arrow" onClick={previousView}><p>{'<'}</p></div>
                            <div className={`step ${view === 1 ? 'dot-selected' : ''}`} onClick={() => forceChangeView(1)}>{' '}</div>
                            <div className={`step ${view === 2 ? 'dot-selected' : ''}`} onClick={() => forceChangeView(2)}>{' '}</div>
                            <div className={`step ${view > 2 ? 'dot-selected' : ''}`} onClick={() => forceChangeView(3)}>{' '}</div>
                            <div className="forward-arrow" onClick={nextView}><p>{'>'}</p></div>
                        </div>
                    </div>
                    <div className="image">
                        <img src={imgOutlaw} className={view === 1 ? 'showOutlawImg' : ''} alt="" />
                        <img src={imgScribbler} className={view === 2 ? 'showScribblerImg' : ''} alt="" />
                        <img src={imgBknmsg} className={view > 2 ? 'showBknmsgImg' : ''} alt="" />
                    </div>
                </div>
                {!buttonsOnTop ? gameButtons() : null}
            </div>
        </div>
    )
}

const OurGames = styled.div`
  @media(min-width: 700px) {
      display: none;
  }

  color: #FFE499;
  width: 100%;
  text-align: center;
  margin: 10px 0px;
  font-family: Oswald;
  font-size: 2rem;

  &:before {
    content: '';
    position: absolute;
    width: 10rem;
    margin: 1.5rem -12rem;
    border-top: 3px solid;
  }
  
  &:after {
    content: '';
    position: absolute;
    width: 10rem;
    margin: 1.5rem 2rem;
    border-top: 3px solid;
  }
`
