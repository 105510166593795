import React, { useState } from 'react';

import { GameStateContext } from '../../../gameplay/GameStateContext';
import { Team } from '../../../gameplay/GameStateController';

import { Spacer } from '@ven/shared/components/common/atoms/Separator';
import { CustomGamePad, CustomGamePlayButton, PlayersList_TeamColumns, SelectTeamJoined, UpsellBit } from '@ven/shared/components/common/pregame/CustomGame';
import { PadContent_ShareGame, UsernameAndAvatarForm } from '@ven/shared/components/common/pregame/common';
import styled from '@emotion/styled';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { GreenButton } from '@ven/shared/components/common/atoms/GreenButton';
import { MOBILE_BREAK_PX } from '@ven/shared/components/styling/breakpoints';
import { PackDeckDropdown } from '@ven/shared/components/common/atoms/PackDeckDropdown';
import { UserDataService } from '@ven/platform/main/services/user/UserDataService';
import { HostPowers } from '@ven/shared/components/game/molecules/HostPowers';
import { UserDataContext } from '@ven/platform/main/services/user/UserDataContext';

export const CustomGameConfigurationContent:React.FC<any> = ({ openHowToPlay }) => 
{
  const { game } = React.useContext( GameStateContext )
  const myUserData = React.useContext( UserDataContext );
  
  const myUserId = myUserData.uid!
  const myPlayerState = game.data.players[ myUserId! ]
  const defaultTeam = myPlayerState?.team || ( Object.keys( game.data.players || {} ).length % 2 === 0 ? Team.Red : Team.Blue )

  const [ myTeam, setMyTeam ] = React.useState( defaultTeam );

  const [ pack, setPack ] = React.useState(Object);
  
  const setMyTeamAndUpdateGameState = team => {
    setMyTeam(team)
    !!game.haveJoined() && game.update.player(myUserId, { team })
  }

  if(!localStorage.configOutlaw) 
    localStorage.configOutlaw = JSON.stringify(game.getConfig());

  const config = JSON.parse(localStorage.configOutlaw);

  const isAnon = UserDataService.get('isAnon') == 'true';

  const [showHostPowers, setShowHostPowers] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(Object);
 
  const handleShow = (userdata) => {
    setSelectedPlayer(userdata)
    setShowHostPowers(true);
  }

  return (<>
    <HostPowers game={game} selectedPlayer={selectedPlayer} show={showHostPowers} setShow={setShowHostPowers} changeTeams={true}></HostPowers>
    {/* <GridWrapper> */}
    <CustomGamePad headerColor="#5A91EF" className='player'> 

      <div className="title"> 
        <div className="game-title">
          Outlaw
        </div>
        <div className="how-to" onClick={ openHowToPlay }>
          How to play
        </div>
      </div>

    {
      !game.data?.eventHost && <div className="inner-content">
        <UsernameAndAvatarForm spacing={28} theme="black"/>
      </div>
    }
     
    </CustomGamePad>

    <Spacer size={ 28 } />

    <CustomGamePad headerColor="#5A91EF" overflowHidden={false} className='settings' > 
      <div className="title title-teams"> 
        <div className="column">
          <div className="bigHeading">Configuration</div>
        </div>
      </div>
      <OptionGrid className="inner-content">
        <span className="label packs-label">Packs</span>
        <PackDeckDropdown 
          gameId="outlaw" userId={myUserId}
          initialPackId={config.pack}
          onChange={ async option => { 
            if(!option) {
              return;
            }

            setPack(option);
            config.pack = option.id;
             localStorage.configOutlaw = JSON.stringify(config);
           } }  />

        { pack.price && !isAnon ? <a onClick={() => {
              const urlBase = window.location.origin.toString()
              const link =`${ urlBase }/pack-init?name=${ pack.name }&id=${ pack.id }&price=${ pack.price }`;
              window.open(link, '_blank')
           }} className="packs-information">Click here to buy the pack</a> : null}

        { pack.price && isAnon ? <a onClick={() => {
           (document.querySelector('button.login')! as any).click() //dirty hack
           }} className="packs-information pack-login">Click here to login/signup to buy the pack</a> : null}

        <span className="label rounds-label">Rounds</span>
        <Dropdown 
          className="rounds-input"
          options={ ['1', '2', '3', '4'] } 
          onChange={ v => { 
            config.rounds = +v.value;
            localStorage.configOutlaw = JSON.stringify(config);
          } } 
          value={ config.rounds.toString() } 
        />
        <span className="label time-label">Turn Time (seconds)</span>
        <Dropdown 
          className="time-input"
          options={ [ '45', '60', '75', '90' ] } 
          onChange={ v => { 
            config.turnTime = +v.value;
            localStorage.configOutlaw = JSON.stringify(config);
          } } 
          value={ config.turnTime.toString() } 
        />
        <span className="label timeout-label">Timeout (seconds)</span>
        <Dropdown
          className="timeout-input"
          options={['120', '60', '45', '30', '15']}
          onChange={v => {
            config.timeout = +v.value;
            localStorage.configScribbler = JSON.stringify(config);
          }}
          value={config.timeout ? config.timeout.toString() :  game.getConfig().timeout.toString()}
        />
      </OptionGrid>
    </CustomGamePad>

    <Spacer size={ 28 } />

    <CustomGamePad headerColor="#5A91EF" className='teams'> 
      <div className="title title-teams"> 
        <div className="column">
          <div className="bigHeading">{ game.getTeamName(1) }</div>
        </div>
        <div className="column">
          <div className="bigHeading">{ game.getTeamName(2) }</div>
        </div>
      </div>
      <p style={{padding: '5px'}}>To manage players, click in their avatar</p>
      <PlayersList_TeamColumns game={ game } teams={[ Team.Red, Team.Blue ]}
        cursorPointer={(playerData) => playerData.uid != myUserId}
        onClickPlayer={(playerData) => { if(playerData.uid != myUserId) handleShow(playerData) }} />
      
      <Spacer size={ 28 } />

      {!game.data?.eventHost && <GreenButton
          style={{margin: '0 auto' }}
          className="button-play"
          onClick={() => setMyTeamAndUpdateGameState(myTeam == Team.Red ? Team.Blue : Team.Red)}>
          Switch Teams
      </GreenButton>}

      {
        ! game.canStartGame() && <>
          <Spacer size={ 28 } />
          <div className="hint"> Waiting for at least two players on each team. </div> 
        </>
      }

      <Spacer size={ 28 } />

      <UpsellBit game={ game }/>
    </CustomGamePad>

    <Spacer size={ 28 } />

    <CustomGamePad headerColor="#303744" className='code'> 
      <PadContent_ShareGame refererId={ myUserId } game={ game } />
    </CustomGamePad>

    {/* <div className='start'> */}

    <Spacer size={ 28 } />
    Outlaw requires a voice chatting app (ie Zoom, Discord, etc.)
    <Spacer size={ 28 } />
    
    <CustomGamePlayButton  game={ game } config={ config } otherError={ pack.price ? "Buy the pack or select other" : null}/>
    {/* </div> */}
    {/* </GridWrapper> */}
  </>)
}


const GridWrapper = styled.div`
 @media (min-width: ${ MOBILE_BREAK_PX }px) {
  display: grid; 
  grid-template-columns: 2.5fr 2.5fr; 
  grid-template-rows: 2.5fr 2.5fr 2.3fr 1.2fr; 
  gap: 2.5% 2.5%; 
  grid-template-areas: 
    "player teams"
    "settings teams"
    "code teams"
    "start start"; 
  .teams { grid-area: teams; }
  .settings { grid-area: settings; }
  .player { grid-area: player; }
  .start { grid-area: start; }
  .code { grid-area: code; }
 }
  /* @media (min-width: ${ MOBILE_BREAK_PX }px) {
    /* display: none; */
  /* } */


`

export const OptionGrid = styled.span`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 5fr;
  gap: 10px 10px;
  grid-template-areas:
    "packs-label packs-label"
    "packs-input packs-input"
    "packs-information packs-information"
    "rounds-label time-label"
    "rounds-input time-input"
    "timeout-label ."
    "timeout-input .";

  @media (max-width: ${ MOBILE_BREAK_PX }px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    gap: 10px 10px;
    grid-template-areas:
      "packs-label"
      "packs-input"
      "packs-information"
      "rounds-label"
      "rounds-input" 
      "time-label"
      "time-input"
      "timeout-label"
      "timeout-input";
  }

  .packs-label { grid-area: packs-label; }
  .packs-dropdown { grid-area: packs-input; }
  .packs-information { grid-area: packs-information; cursor: pointer; }
  
  .rounds-label { grid-area: rounds-label; }
  .rounds-input { grid-area: rounds-input; }
  
  .time-label { grid-area: time-label; }
  .time-input { grid-area: time-input; }

  .timeout-label { grid-area: timeout-label; }
  .timeout-input { grid-area: timeout-input; }
`
