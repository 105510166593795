import React from 'react';

import { GameStateContext } from '../../gameplay/GameStateContext';

import { InGameContent } from '../templates/InGameContent';
import { PreGameContent } from '../templates/pregame/PreGameContent';
import { MainContent } from '@ven/platform/main/components/organisms/MainContent';

import { MOBILE_BREAK_PX } from '@ven/shared/components/styling/breakpoints';

import styled from '@emotion/styled';

//@ts-ignore
import MobileBackground from "@ven/shared/components/assets/brokenmsg/bg-mobile.png";
//@ts-ignore
import DesktopBackground from "@ven/shared/components/assets/brokenmsg/bg-desktop.png";
//@ts-ignore
import HolidayBackground from "@ven/shared/components/assets/brokenmsg/bg-holiday.png";

import { DEVELOPMENT_MODE } from '@ven/core/debug/consts';
import { useDebugKeys } from '@ven/core/debug/useDebugKeys';
import { UserDataService } from '@ven/platform/main/services/user/UserDataService';

export const PlayerViewPage:React.FC = () => 
{
  const { data, game } = React.useContext( GameStateContext )
  
  const started = data?.state?.started

  const gameId = 'brokenmsg'
  
  DEVELOPMENT_MODE && useDebugKeys(game)

  const isHoliday = UserDataService.get('holiday') ? 'holiday' : '';

  return (
    <Wrapper>
      <MainContent className={`${game.gameId} ${( started ? 'ingame' : 'pregame' )} ${isHoliday}`}>
        {
          ! started
          ? <PreGameContent/>
          : <InGameContent/>
        }
      </MainContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #044000;
  background-image: url(${ DesktopBackground });
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
    
  .main-content.holiday{
    background-image: url(${ HolidayBackground });
    background-position: top;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
  }

  width: 100vw;
  
  user-select: none;

  transition: background-image 200ms;

  overflow: hidden;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media (max-width: ${ MOBILE_BREAK_PX }px) {
    background-image: url(${ MobileBackground });

    .main-content.holiday {
      background-image: url(${ HolidayBackground });
      background-position: top;
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  p, h1, h2 {
    margin: 0;
    padding: 0;
  }

  input {
    user-select: auto;
  }

  .main-content.pregame {
    height: auto !important;
  }

  .main-content.ingame {
    overflow: hidden;
  }
`
