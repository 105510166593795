import React, { useState } from 'react';

import { GameStateContext } from '../../../gameplay/GameStateContext';

import { Spacer } from '@ven/shared/components/common/atoms/Separator';
import { CustomGamePad, CustomGamePlayButton, PlayersList_Basic, UpsellBit } from '@ven/shared/components/common/pregame/CustomGame';
import { PadContent_ShareGame, UsernameAndAvatarForm } from '@ven/shared/components/common/pregame/common';
import { DEFAULT_CONFIG } from '@ven/games/brokenmsg/gameplay/GameStateController';
import styled from '@emotion/styled';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { MOBILE_BREAK_PX } from '@ven/shared/components/styling/breakpoints';
import { HostPowers } from '@ven/shared/components/game/molecules/HostPowers';

export const CustomGameConfigurationContent:React.FC<any> = ({ openHowToPlay }) => 
{
  const { game } = React.useContext( GameStateContext )

  const myUserId = game.myUserId!
  const players = Object.keys( game.data.players || {} )

  const MINIMUM_PLAYERS_COUNT = DEFAULT_CONFIG.minPlayersCount;
  const missingPlayersCount = Math.max( 1, MINIMUM_PLAYERS_COUNT - players.length )

  if(!localStorage.configBrokenmsg) 
    localStorage.configBrokenmsg = JSON.stringify(game.getConfig());

  const config = JSON.parse(localStorage.configBrokenmsg);

  if ( game.data.state.started ) {
    return <div> This game has already started... </div>
  }

  const [showHostPowers, setShowHostPowers] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(Object);
 
  const handleShow = (userdata) => {
    setSelectedPlayer(userdata)
    setShowHostPowers(true);
  }

  return (<>
    <HostPowers game={game} selectedPlayer={selectedPlayer} show={showHostPowers} setShow={setShowHostPowers}></HostPowers>
   
    <CustomGamePad headerColor="#FFE499" headerTextColor="#303744"> 
      <div className="title"> 
        <div className="game-title">
          BrokenMsg
        </div>
        <div className="how-to" onClick={ openHowToPlay }>
          How to play
        </div>
      </div>
      
    {
      !game.data?.eventHost && <div className="inner-content">
        <UsernameAndAvatarForm spacing={28} theme="black"/>
      </div>
    }
      
    </CustomGamePad>
    
    <Spacer size={ 28 } />

    <CustomGamePad headerColor="#FFE499" headerTextColor="#303744" overflowHidden={false} > 
      <div className="title title-teams"> 
        <div className="column">
          <div className="bigHeading">Configuration</div>
        </div>
      </div>
      <OptionGrid className="inner-content">
        <span className="label draw-label">Draw Time (seconds)</span>
        <Dropdown 
          className="draw-input"
          options={ ['35', '45', '55' ] } 
          onChange={ v => { 
            config.drawingTime = +v.value;
            localStorage.configBrokenmsg = JSON.stringify(config);
          } } 
          value={ config.drawingTime.toString() } 
        />
        <span className="label phrase-label">Phrase Time (seconds)</span>
        <Dropdown 
          className="phrase-input"
          options={ [ '20', '30', '40' ] }
          onChange={ v => { 
            config.sentenceTime = +v.value;
            localStorage.configBrokenmsg = JSON.stringify(config);
          } } 
          value={ config.sentenceTime.toString() } 
        />
      </OptionGrid>
    </CustomGamePad>

    <Spacer size={ 28 } />

    <CustomGamePad headerColor="#FFE499" headerTextColor="#303744"> 
      <div className="title title-teams"> 
        <div className="column">
          <div className="bigHeading">Players</div>
        </div>
      </div>
      <p style={{padding: '5px'}}>To manage players, click in their avatar</p>
      <PlayersList_Basic game={ game }
        cursorPointer={(playerData) => playerData.uid != myUserId}
        onClickPlayer={(playerData) => { if(playerData.uid != myUserId) handleShow(playerData) }} />
      {
        ! game.canStartGame() && <>
          <Spacer size={ 28 } />
          <div className="hint"> Waiting for at least { missingPlayersCount } more player{ missingPlayersCount > 1 && 's' }. </div> 
          <Spacer size={ 28 } />
        </>
      }
      
      <UpsellBit game={ game }/>
    </CustomGamePad>

    <Spacer size={ 28 } />

    <CustomGamePad headerColor="#FFE499" headerTextColor="#303744"> 
      <PadContent_ShareGame refererId={ myUserId } game={ game } theme="dark"/>
    </CustomGamePad>

    <Spacer size={ 28 } />
    
    <CustomGamePlayButton game={ game } config={ config } />
  </>)
}


export const OptionGrid = styled.span`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 5fr;
  gap: 10px 10px;
  grid-template-areas:
    "draw-label phrase-label"
    "draw-input phrase-input";

  @media (max-width: ${ MOBILE_BREAK_PX }px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 5fr auto 5fr;
    gap: 10px 10px;
    grid-template-areas:
      "draw-label"
      "draw-input" 
      "phrase-label"
      "phrase-input";
  }

  .draw-label { grid-area: draw-label; }
  .draw-input { grid-area: draw-input; }
  .phrase-label { grid-area: phrase-label; }
  .phrase-input { grid-area: phrase-input; }
`
