import { PlayerAvatar } from "@ven/shared/components/game/molecules/PlayerAvatar";
import * as React from "react";
import "./Notepad.scss";

type NotepadProps = React.PropsWithChildren<{
  cover?: boolean;
  header?: string | Function;
  body?: string | Function;
  avatar?: string;
  placeholder?: string;
  bodyInputMaxLength?: number
}>;

const Notepad: React.FC<NotepadProps> = ({ children, ...props }) => {
  const handleSubmit = (event: any, callback: Function) => {
    const [value] = event.target;
    callback(value);
    event.preventDefault();
  };

  const handleEvents = (value: string, callback: Function) => {
    callback(value);
  };

  const pin = (
    <div className="top">
      <div className="pin"></div>
      <div className="pin"></div>
    </div>
  );

  const input = (className: string, callback: Function, autofocus = true) => 
    (<div className={`${className} input`}>
        <form onSubmit={(e) => handleSubmit(e, callback)}>
          <textarea 
          autoFocus={ autofocus }
          onChange={ e => handleEvents( e.target.value, callback) }
          onBlur={ e => handleEvents( e.target.value, callback) }
          placeholder={props.placeholder}
          maxLength={ props.bodyInputMaxLength ? props.bodyInputMaxLength : 55 }
          onKeyDown={ e => { if(e.key == "Enter") e.preventDefault() } }
          ></textarea>
        </form>
      </div>
    );

  const label = (
    <div className="top label">
      <h2>{props.header}</h2>
    </div>
  );

  const bodyLabel = (
    <div className="body label">
      <h2>{props.body}</h2>
    </div>
  );
 
  const empty = <div className="body empty"></div>;

  const createHeader = () => {
    const getHeaderType = (headerType: String | Function) => {
      return typeof headerType === "string" 
      ? label : input('top', props.header as Function, false);
    };

    return !props.header ? pin : getHeaderType(props.header);
  };

  const createBody = () => {
    if (children) return;

    const getBodyType = (headerType: String | Function) => {
      return typeof headerType === "string" 
      ? bodyLabel : input('body', props.body as Function);
    };

    return !props.body ? empty : getBodyType(props.body);
  };

  const createAvatar = (avatarIndex? : string) => {
    const avatarWrapper = (
      <div className="avatar-wrapper">
         <PlayerAvatar size={100} index={ avatarIndex }/>
      </div>
    );

    return props.avatar ? avatarWrapper : "";
  };

  return (
    <div className={`Notepad ${props.cover ? "cover" : ""}`}>
      {createHeader()}
      {createBody()}
      {children}
      {createAvatar(props.avatar)}
    </div>
  );
};

export default Notepad;
