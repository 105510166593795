import React from 'react';

import { database } from '@ven/core/data/firebase';

import { UserDataContext } from '@ven/platform/main/services/user/UserDataContext';
import type { GameStateController } from '@ven/core/gameplay/GameStateController';
import type { GameSessionData } from '@ven/core/gameplay/GameSessionData';
import { GetMaximumGameAllowedPlayer, GetMaximumUserAccountAllowedPlayer as GetMaximumUserAccountAllowedPlayers } from '@ven/platform/main/services/user/UserDataService';

export const useSynchronizePlayerData = ( game: GameStateController<GameSessionData> ) => 
{
  const { uid } = React.useContext( UserDataContext );

  const joined = uid && !! game.data?.players?.[ uid ]

  const updateRoomMaxUsers = async (userData) => {
    if(game.data?.eventHost) {
      game.ref.room().update({ maxPlayers : GetMaximumGameAllowedPlayer(game.gameId) })
      return;
    }

    const maxPlayersAccount = await GetMaximumUserAccountAllowedPlayers(userData);
    game.ref.room().update({ maxPlayers : Math.min(maxPlayersAccount, GetMaximumGameAllowedPlayer(game.gameId)) })
  }

  React.useEffect( () => 
  {
    if (!joined)
      return;
    
    const ref = database.ref(`/users/${uid}`)
    const onDataChange = ( a:any ) => {
      const newUserData = a.val();

      console.log(`Detected a change to user-data. Updating player data to reflect.`)
      game.update.player( uid!, { ...newUserData })

      if ( game.data.host === uid ) {
        updateRoomMaxUsers(newUserData);
      }
    }
    ref.on( "value", onDataChange )

    return () => ref.off( "value", onDataChange )
  }, [ uid, joined, game ] )

  React.useEffect( () => 
  {
    if(!game)
      return;

    if(!game.amHost()) {
      return;
    }

    updateRoomMaxUsers(game.data?.players?.[ game.myUserId! ] );
  
  }, [ (game.amHost()) ])
}
