import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from '@emotion/styled';

import * as orientationPolyfill  from 'o9n';


//@ts-ignore
import wiggle from '../../assets/wiggle.svg';
//@ts-ignore
import arrow from '../../assets/Back.png';
//@ts-ignore
import phoneImage from '../../assets/phone.png';

type Props = {
  lock?: string,
  callbacks?: {
    onOrientationChange : (any) => any
  }
}
export const OrientationChecker:React.FC<Props> = ({ callbacks, lock = 'portrait' }) => 
{
  const [currentOrientation] = useState(() => orientationPolyfill.getOrientation() );
  const [showOverlay, setOverlay] = useState(false);

  const checkOrientation = orientation => {
    return !orientation.type.includes(lock);
  }

  const onOrientationCallback = React.useCallback(() => { 
    setOverlay(checkOrientation(currentOrientation))
    callbacks?.onOrientationChange(currentOrientation);
  }, []);

  orientationPolyfill.orientation.addEventListener('change', onOrientationCallback);

  useMemo(() => {
    onOrientationCallback()
  }, []);

  return (
    <Overlay className={`${!showOverlay ? 'hidden' : ''}`}>
      <Warning>
        <img src={wiggle}/>
        <span>{`Please turn your device to ${lock == 'portrait' ? 'portrait' : 'landscape' } mode to play!`}</span>
        <div className="orientation-wrapper">
          <img className={`${lock == 'portrait' ? 'rotate' : 'null' }`} src={phoneImage}/>
          <img className="arrow" src={arrow}/>
          <img className={`${lock == 'landscape' ? 'rotate' : 'null' }`} src={phoneImage}/>
        </div>
      </Warning>
    </Overlay>
  )
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1999;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.hidden {
    visibility: hidden;
    pointer-events: none;
  }

  
  @media (max-width: 300px) {
    height: 110vh;
  }
`
const Warning = styled.div`
  left: 0;
  width: 65vw;
  height: 50vh;
  max-height: 380px;
  max-width: 380px;
  background: white;
  z-index: 2000;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 35px;
  /* margin: 5vw; */
  
  color: #303744;
  
  font-family: Bitter;
  font-style: normal;
  font-weight: normal;
  font-size: 4rem;
  text-align: center;

  @media (max-width: 500px) {
    font-size: 2rem;
  }
  
  box-shadow: -6px 7px 28px rgb(255 255 255 / 28%);
  border-radius: 12px;

  .rotate {
    transform: rotate(90deg);
  }
  & > .orientation-wrapper {
    display: flex;
    flex-direction: row;

    .arrow {
      margin: 20px;
    }
  }

`
