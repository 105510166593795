import firebase from "firebase";
import { DEVELOPMENT_MODE } from "../debug/consts";
import { GameFunctionsService } from "./GameFunctionsService";

export const HostEventsService = {
  async getHostEvents(callback) {
    await firebase
      .firestore()
      .collection("events")
      .orderBy("createdDate", "desc")
      .onSnapshot((data) => {
        const items = data.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))
    
        callback(items)
    });
  },

  async createHostEvent(data: any) {
    data.createdDate = Date.now();
    return await firebase
      .firestore()
      .collection("events")
      .add({
        ...data
      });
  },

  async deleteHostEvent(id: any) {
    return await firebase
      .firestore()
      .collection("events")
      .doc(id).delete()
  },

  async getHostEvent(name: string) {
    const itemsSnapshot = await firebase
      .firestore()
      .collection("events")
      .where("name", "==", name)
      .get();

    const items = itemsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }))
''
    const data = [...items];
    return data;
  },

  async getHostEventSessions(name, callback) {
    await firebase
      .firestore()
      .collection("events/"+name+"/sessions")
      // .orderBy("createdDate", "desc")
      .onSnapshot((data) => {
        const items = data.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))
    
        callback(items)
    });
  },

  async getHostEventSessionsByGame(name: string, game: string, owner: string = "") {
    const itemsSnapshot = await firebase
      .firestore()
      .collection("events/"+name+"/sessions")
      .where("game", "==", game)
      .get();

    const items = itemsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }))

    const data = [...items];
    return data;
  },

  async getHostEventSession(name: string, nameSession: string) {
    const itemsSnapshot = await firebase
      .firestore()
      .collection("events/"+name+"/sessions")
      .where("nameSession", "==", nameSession)
      .get();

    const items = itemsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }))

    const data = [...items];
    return data;
  },

  async createHostEventSession(eventName: string, data: any) {
    const games = new Array<any>();
    for (let index = 0; index < data.groups; index++) {
     const gameData = await GameFunctionsService.createRoom(data.game.toLowerCase(), data.creator, true)
     if(gameData)
      games.push({
        code : gameData.code,
        sessionPath : gameData.sessionPath,
      })
    } 
    data.createdDate = Date.now();
    data.games = games;

    return await firebase
      .firestore()
      .collection("events/"+eventName+"/sessions")
      .add({
        ...data
      });
  },

  async updateHostEventSessionGameInfo(eventName: string, session: string, index: number, data: any) {
    const value = await firebase
      .firestore()
      .collection(`events/${eventName}/sessions`)
      .doc(session)
      .get();

    const sessionData : any = value.data();
    sessionData.games[index].info = data;

    await firebase
    .firestore()
    .collection(`events/${eventName}/sessions`)
    .doc(session)
    .update(sessionData);

    return true;
  },

  async deleteHostEventSession(eventId: any, sessionId: any) {
    return await firebase
      .firestore()
      .collection("events/"+eventId+"/sessions")
      .doc(sessionId).delete()
  },
}

DEVELOPMENT_MODE && (window['HostEventsService'] = HostEventsService);
