import { database } from '@ven/core/data/firebase';
import { CouponService } from '@ven/shared/core/services/CouponService';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { JSONSchema7 } from "json-schema";
import Form from "@rjsf/core";
import { GetCurrentUserData } from '@ven/platform/main/services/user/UserDataService';
import firebase from 'firebase';

const ProfileTab: React.FC = () => {
    const schema: JSONSchema7 = {
        type: "object",
        required: ['username', 'name', 'email', 'password'],
        properties: {
          username: { type: "string", title: "Username", },
          name: { type: "string", title: "Name" },
          email: { type: "string", title: "E-mail", format: "email"},
          password: { type: "string", title: "Password" },
        }
      };
    
      const uiSchema =  {
        password: {
          "ui:widget": "password"
        }
      };

      
  const [formData, setFormData] = useState(Object);

  
  useEffect(() => {
    GetCurrentUserData().then(userData => {
      setFormData({
        ...userData
      })
    });
  }, []);


  const submit = async (schemeData) => {
    const { formData : submittedFormData } = schemeData;
  }
  
  const log = (type) => console.log.bind(console, type);

  const reauthenticate = (currentPassword) => {
    var user = firebase.auth().currentUser;
    var cred = firebase.auth.EmailAuthProvider.credential(
        user?.email!, currentPassword);
    return user?.reauthenticateWithCredential(cred);
  }

  const changePassword = (currentPassword, newPassword) => {
    reauthenticate(currentPassword).then(() => {
      var user = firebase.auth().currentUser;
      user?.updatePassword(newPassword).then(() => {
        log("Password updated!");
      }).catch((error) => { console.log(error); });
    }).catch((error) => { console.log(error); });
  }
  const changeEmail = (currentPassword, newEmail) => {
    reauthenticate(currentPassword).then(() => {
      var user = firebase.auth().currentUser;
      user?.updateEmail(newEmail).then(() => {
        log("Email updated!");
      }).catch((error) => { console.log(error); });
    }).catch((error) => { console.log(error); });
  }

  return (
      <div>
          <Helmet>
              <title> Account - Profile | VEN Games </title>
          </Helmet>
          <Form schema={schema}
          formData={formData}
          uiSchema={uiSchema}
          onChange={e => { console.log(e); return setFormData(e.formData) }}
          onSubmit={submit}
          onError={log("errors")}
          noHtml5Validate />
      </div>
      
  )
}

export default ProfileTab;
