import React from 'react';

import styled from '@emotion/styled';

export const TimerCircleProgress:React.FC<
  {
    time:number|string,
    color?:string,
    radius?: number,
    stroke?: number,
    totalTime?: number,
    displayTime?: boolean,
    interpolate?: boolean
  }> = ({ time, radius = 50, stroke = 7, totalTime, displayTime = true, interpolate = true, color="white"}) => 
{
  const currentTime = +time;
  // const [currentTime, setCurrentTime] = React.useState(+time);
  // const interval = React.useRef<any>();
  // React.useEffect(() => {
  //   interval.current = setInterval(() => {
  //     if (currentTime === 0) {
  //       setCurrentTime(totalTimeRef.current!)
  //       // clearInterval(interval.current);
  //       return;
  //     }
  //     setCurrentTime(currentTime - 0.2)
  //     return clearInterval(interval.current);
  //   }, 200);
  // }, [currentTime])

  const totalTimeRef = React.useRef<number>();
  if(!totalTime) {
    if(!totalTimeRef.current || time > totalTimeRef.current){
      totalTimeRef.current = currentTime;
    }
  } else {
    totalTimeRef.current = totalTime;
  }

  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = (
    isNaN(currentTime) ? (100 * circumference) : ( circumference - ((100 / totalTimeRef.current!) * currentTime) / 100 * circumference )
  ) || 0;

  
  
  
  const timeText = displayTime ? ( currentTime || 0 ).toString().padStart( 2, '0' ) : "";

  return (
    
    <Wrapper className="circle-clock">
      <svg
        height={radius * 2}
        width={radius * 2}
        >
        <circle
          className={`${interpolate ? "interpolate" : null}`}
          stroke={color}
          fill="transparent"
          strokeWidth={ stroke }
          strokeDasharray={ circumference + ' ' + circumference }
          style={ { strokeDashoffset } }
          r={ normalizedRadius }
          cx={ radius }
          cy={ radius }
          />
      </svg>
  
  <div>
    <div className="time-text-wrapper" style={{color: color}}>
    { timeText }
    </div>
  </div>
     
    </Wrapper>
  )
}

const Wrapper = styled.div`
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  position: relative;
  
  text-align: center;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;

  circle {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
  circle.interpolate {
    transition: stroke-dashoffset 0.3s;
  }
  
  div.time-text-wrapper{
    left: 50%; 
    transform: translate(-50%, -50%); 
    position: absolute;
  }


`
