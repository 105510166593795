import React, { useState } from "react";
import { useEffect } from "react";
import Notepad from "@ven/games/brokenmsg/components/notepad/Notepad";
import { GreenButton } from "@ven/shared/components/common/atoms/GreenButton";
import styled from "@emotion/styled";
import { CouponService } from "@ven/shared/core/services/CouponService";
import { UserDataService } from "@ven/platform/main/services/user/UserDataService";
import { database } from '@ven/shared/core/data/firebase';
import { Helmet } from "react-helmet";
import { analyticsService } from '@ven/platform/main/services/AnalyticsService';

const PackConfirmation = () => {

    const [hasError, setError] = useState(false);

    const [hasCanceled, setCanceled] = useState(false);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const code = query.get('success') || '';
        const id = query.get('id') || '';
        const name = query.get('name') || '';
        const price = query.get('price') || 0;

        if(!code){
            setError(true);
        }
        if(code == 'false') {
            setCanceled(true);
            setTimeout(() => {
                window.close();
                return;
            }, 1000)
            return;
        }

        setLoading(true);
        CouponService.IsUnclaimedCode(code)
            .then(async (unclaimed) => {
                if (!unclaimed) {
                    setLoading(false);
                    setError(true);
                    console.log("Already claimed code")
                    return;
                }
                const type = 'Words Pack';
                const done = await CouponService.PutCodeOnUserInfo('Words Pack', code, +price, 'ACTIVE', id, name, true);
                if (!done) {
                    setLoading(false);
                    setError(true);
                    console.error("Error");
                    return;
                }

                const claimedResult = await CouponService.SetCodeAsClaimed(code);
                if(!claimedResult) {
                    setLoading(false);
                    setError(true);
                    console.error("unable to claim")
                    return;
                }

                setLoading(false);
                const uid = UserDataService.get('uid');
                const dbRef = database.ref(`/users/${uid}`);
                const data = await dbRef.once('value');
                const userValue = data.val();
                await fetch(`${process.env.FUNCTION_URL}/send-email/pack/${'id'}?name=${userValue.username}&dest=${userValue.email}&code=${code}`, {
                    method: 'GET',
                });
                
                analyticsService.purchase({
                    currency: 'USD',
                    items: [{
                        item_id: code,
                        item_name: name || type,
                        item_category: type,
                        price,
                        currency: 'USD',
                        quantity: 1
                    }],
                    value: price
                });

                setTimeout(() => {
                    window.close();
                    console.log('DONE');
                    return;
                }, 500)
        })
    }, []);

    const cancelView = (<>
        <span>Oh! we are sorry hear that!</span>
        <h1>But we are here in case you change your mind</h1>
        <span>redirecting back to game...</span>
        <span>You can close this screen</span>
        <GreenButton onClick={() => window.close()}>
            Close
        </GreenButton>
        </>)

    const errorView = (<>
        <h1>Oh no! Something went wrong!</h1>
        <span>Try again or contact VEN Games about the issue</span>
        <span>You can close this screen</span>
        <GreenButton onClick={() => window.close()}>
            Close
        </GreenButton>
        </>)

    const wrappingThingsUp = (<>
        <h1>All right! Almost there!</h1>
        <span>Wrapping up your purchase...</span>
        </>)


    const successfulView = (<>
        <h1>Thanks for supporting VEN GAMES</h1>
        <span>redirecting back to game...</span>
        <span>You can close this screen</span>
        <GreenButton onClick={() => window.close()}>
            Close
        </GreenButton>
        </>)

    const showView = () => {
        if(hasCanceled)
            return cancelView;
        if(hasError)
            return errorView;
        if(loading)
            return wrappingThingsUp;
        
        return successfulView;
    }
    return (
        <Wrapper>
            <Helmet>
                <title> Pack Confirmation | VEN Games </title>
            </Helmet>
            <div>
                <Notepad>
                    <div className="body">
                        { showView() }
                    </div>
                </Notepad>
            </div>
        </Wrapper>
    );
}
export default PackConfirmation;


const Wrapper = styled.div`
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;

    .Notepad > .body {
        margin: 24px;
        margin-top: 0;
    }
    
    span {
        font-size: 1.5rem;
    }

    h1 {
        text-align: center;
    }
`