import React, { useContext, useEffect, useState } from 'react';
import { JSONSchema7 } from "json-schema";
import Form from "@rjsf/core";
import { GetCurrentUserData } from '@ven/platform/main/services/user/UserDataService';
import { HostEventsService } from '@ven/shared/core/services/HostEvents';
import { Card } from 'react-bootstrap';
import styled from '@emotion/styled';
import { HostEventContext } from '.';

const CreateEvent: React.FC = () => {
  const schemaEvent: JSONSchema7 = {
    title: "Create Event",
    type: "object",
    required: ['name'],
    properties: {
      name: { type: "string", title: "Name" },
      // description: { type: "string", title: "Description" },
    }
  };

  const [formData, setFormData] = useState(Object);
  const [userData, setUserData] = useState(Object);

  
  const {setEventData, setSession} = useContext(HostEventContext);

  useEffect(() => {
    GetCurrentUserData().then(userData => {
      setUserData({
        ...userData
      })
    });
  }, []);

  const submit = async (schemeData) => {
    const { formData: submittedFormData } = schemeData;
    submittedFormData.creator = userData.uid;
    const eventData = await HostEventsService.createHostEvent(submittedFormData);
    setEventData({...submittedFormData, id: eventData.id});
    setSession(null)
  }

  const log = (type) => console.log.bind(console, type);

  return (
    <Wrapper>
      <Card>
        <Card.Header>
          Create Event
        </Card.Header>
        <Card.Body>
          <Form schema={schemaEvent}
            formData={formData}
            // uiSchema={uiSchema}
            onChange={e => { console.log(e); return setFormData(e.formData) }}
            onSubmit={submit}
            onError={log("errors")}
            noHtml5Validate />
        </Card.Body>
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled.div`
legend {
  display: none;
}
fieldset {
  border: 0;
}
`

export default CreateEvent;
