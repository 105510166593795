export const configuration = 
{
  canvas : 
  {
    width : 650, 
    height : 628,
    minLineLength : 5,
  },
  tools : 
  {
    brushColors : [ 0xa19ac6, 0x303744, 0xffe499, 0xf47c63, 0x2fb586, 0x5a91ef, 0xfc3b68, 0x3148bf, 0xef6bfc ],
    brushSizes : [ 8, 16, 24, 48 ],
  }
}
