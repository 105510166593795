import { database } from '@ven/core/data/firebase';
import { GetCurrentUserData, UserDataService } from '@ven/platform/main/services/user/UserDataService';
import { CouponService } from '@ven/shared/core/services/CouponService';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';
import { MainHeader } from '../../organisms/MainHeader';
import ActivationModal from './ActivationModal';
import CouponItem from './CouponItem';

const PurchasesPage: React.FC = () => {
    const history = useHistory();
    let types: any[] = [];
    const [username, setUsername] = useState('');
    const [activeCount, setActiveCount] = useState(0);
    const [expiredCount, setExpiredCount] = useState(0);

    const [view, setView] = useState(1);
    const [elements, setElements] = useState(new Array);
    const [activeCoupons, setActiveCoupons] = useState(new Array);
    const [expiredCoupons, setExpiredCoupons] = useState(new Array);
    const [parts, setParts] = useState(0);

    // useEffect(() => {
    //     console.log(auth.currentUser?.uid)
    // }, [auth.currentUser?.uid])
    
    useEffect(() => {
        const interval = setInterval(() => {
            if (parts === 1) {
                GetCurrentUserData().then((userData) => {
                    if (userData) {
                        setParts(0);
                    }
                });
            }
        }, 2000);
        if (parts === 0) {
            GetCurrentUserData().then((userData) => {
                if (!userData || UserDataService.get('isAnon') == 'true') {
                    setParts(1);
                    return;
                }
                setUsername(userData.username);
                if (!userData.packages) {
                    setParts(2);
                    return;
                }
                CouponService.checkAndExpireActiveCode(userData.uid).then(() => {
                    types = Object.keys(userData.packages);
                    let coupons: any[] = [];
                    for (const t of types) {
                        if (!t.toLocaleLowerCase().includes('vip' )) {
                            for (const v of Object.keys(userData.packages[t])) {
                                coupons.push(userData.packages[t][v]);
                            }
                        }
                    }
    
                    separateCoupons(coupons);
    
                    if (view === 1 && activeCoupons.length > 0) {
                        setCouponElements(activeCoupons);
                    } else if (view === 2 && expiredCoupons.length > 0) {
                        setCouponElements(expiredCoupons);
                    } else {
                        setParts(2);
                    }
                })
            });
        }
        return () => clearInterval(interval);
    });

    const separateCoupons = (c: Array<any>) => {
        let availableCoupons = new Array;
        let userExpiredCoupons = new Array;
        for (const key of Object.keys(c)) {
            c[key].state === 'UNUSED' || c[key].state === 'ACTIVE'
                ? availableCoupons.push(c[key])
                : userExpiredCoupons.push(c[key]);
        }
        if (availableCoupons.length) {
            setActiveCoupons(availableCoupons);
        }
        if (userExpiredCoupons.length) {
            setExpiredCoupons(userExpiredCoupons);
        }
        setActiveCount(availableCoupons.length);
        setExpiredCount(userExpiredCoupons.length);
    }

    const [isActivating, setIsActivating] = useState(false);
    const [activatingCodeObj, setActivatingCodeObj] = useState({});
    const [isThereActiveCode, setIsThereActiveCode] = useState(false);
    const activateCode = (value) => {
        const uid = UserDataService.get('uid');
        CouponService.checkAndExpireActiveCode(uid).then(validation => {
            setIsThereActiveCode(!!validation);
        });

        setActivatingCodeObj(value);
        setIsActivating(true);
    }

    const setCouponElements = (arrayList) => {
        const unusedDateFormat = { year: 'numeric', month: 'long', day: 'numeric' };
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute:'2-digit' };
        let couponElements: Array<any> = [];
        for (const [index, value] of arrayList.entries()) {
            let date2 = new Date(value.activationDate);
            date2.setTime(date2.getTime() + CouponService.getBundleExpirationTime());
            couponElements.push(
                <CouponItem coupon={value} key={index} 
                dateFormat={options} unusedDateFormat={unusedDateFormat}
                onActiveButton={() => activateCode(value)} ></CouponItem>
            );
        }
        setElements(couponElements);
        setParts(2);
    }

    const changeView = (newView) => {
        if (newView === view) {
            return
        }
        setView(newView);
        // setParts(0);
        if (newView === 1 && activeCoupons.length) {
            setCouponElements(activeCoupons);
        } else if (newView === 2 && expiredCoupons.length) {
            setCouponElements(expiredCoupons);
        } else {
            setCouponElements([]);
        }
    }

    const closeModal = () => {
        setIsActivating(false);
    }

    const activationConfirmed = (coupon) => {
        const uid = UserDataService.get('uid');
        const dbRef = database.ref(`/users/${uid}/packages/${coupon.type.split(' ').join('')}/${coupon.code}`);
        dbRef.once('value').then(async (data) => {
            dbRef.set({
                ...data.val(),
                activationDate: new Date().toISOString(),
                state: 'ACTIVE',
            });
            const userRef = database.ref(`/users/${uid}/activeCoupon`);
            userRef.set({
                ...data.val(),
                activationDate: new Date().toISOString(),
                state: 'ACTIVE',
            });
            setParts(0);
            closeModal();
        });
    }

    const openLoginModal = () => {
        const loginButton = document.getElementsByClassName('login')[0];
        (loginButton as any).click();
    }

    return (
        <div>
            <Helmet>
                <title> Account - Purchases | VEN Games </title>
            </Helmet>
            { parts === 0 &&
                <div className="spinner-wrapper">
                    <MoonLoader
                        size={50}
                        color={"#303744"}
                        loading={true}
                    />
                </div>
            }
            { parts === 1 &&
                <div className="not-logged">
                    <p>Login to see your codes.</p>
                    <button onClick={openLoginModal}>Log In</button>
                </div>
            }
            { parts === 2 &&
                    <div className="codes">
                    {isActivating &&
                        <ActivationModal couponValue={activatingCodeObj} close={closeModal} activate={activationConfirmed} isThereActiveCode={isThereActiveCode} />
                    }
                        <div className="header">
                            <div className="status">
                                <button className={view === 1 ? 'selected' : 'not-selected'} onClick={() => changeView(1)}>ACTIVE ({activeCount})</button>
                                <button className={view === 2 ? 'selected' : 'not-selected'} onClick={() => changeView(2)}>USED/EXPIRED ({expiredCount})</button>
                            </div>
                        </div>
                        <div className="code-list">
                            {((view === 1 && activeCount < 1) || (view === 2 && expiredCount < 1)) &&
                                <div className="no-codes">
                                    <button onClick={() => history.push('/gamenight?back_url=\\account')}>Buy Game Night Bundle!</button>
                                </div>
                            }

                            {elements}
                        </div>
                    </div>
            }
        </div>
    )
}

export default PurchasesPage;
