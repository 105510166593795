import React from "react";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

export const ClipboardCopy = ({ copyText, copiedText = "Copied!", buttonText = "Copy" }) => {
  const [isCopied, setIsCopied] = useState(false);

  // useEffect(() => {
  // if(callback)
  //   callback(isCopied);
  // }, [isCopied])

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  return (
    <div>
      <input type="text" value={copyText} readOnly style={{ display: 'none' }} />
      {/* Bind our handler function to the onClick button property */}
      <Button onClick={handleCopyClick}>
        {isCopied ? copiedText : buttonText}
      </Button>
    </div>
  );
}