import React from 'react';

import styled from '@emotion/styled';
import { COLOR_TOOL_IDLE } from '@ven/shared/components/styling/colors';
import { SendIcon } from '../assets/icons';
import { isMobile } from '@ven/core/utils/deviceDetectionUitl';

const InputPanel = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  height: 52px;
  border-radius: 12px;
  background: white;

  & input {
    height: 100%;
    padding: 20px;
    flex: 1 1;
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 17px; /* To avoid zooms bugs on IOS, never do an input font-size less than 17px */
    line-height: 22px;
    outline: none;
    border: none;
    
    color: #FC3B68;
        
    ::placeholder {
      color: #A19AC6FF;
      transition: color 400ms;
    }
  }

  & input:focus, & input:active {
    outline: none;
    border: none;
    color: #303744;
    
    ::placeholder {
      color: #A19AC666;
    }
  }
`

const IconButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 12px;
  transition: transform 300ms;
  background: ${ COLOR_TOOL_IDLE };

  svg {
    width: 20px;
    height: 20px;
  }

  &:active {
    transform: scale(.9);
    transition: transform 100ms;
  }
`

export const SendButton = styled( props => 
  <IconButton
    component="span" 
    onMouseDown={ e => e.preventDefault() }
    {...props}>
    <SendIcon color={ "#FFFFFF" } />
  </IconButton> )`
  animation: slide-left 250ms;
`

interface Props
{ 
  handleSend : (message:string) => any
  setFocused? : (v:boolean) => any
}
export const MessageInput:React.FC<Props> = ({ handleSend, setFocused, ...props }) =>
{
  const [text, setText] = React.useState('')

  const submitText = () => {
    if ( !! text )
    {
      handleSend( text )  
      setText( '' )

      isMobile(true) &&
      document.activeElement &&
      document.activeElement['blur'] &&
      document.activeElement['blur']()
    }
  }

  return (
    <InputPanel className="chat-message-input-panel" {...props}>

      <input 
        type="text" 
        // placeholder={ `What is ${ currentPlayer } drawing?` } 
        placeholder="Type your guess here"
        value={ text }
        maxLength={35}
        onChange={ e => setText( e.target.value ) }
        onKeyUp={ e => e.key == "Enter" && submitText() }
        onFocus={ () => setFocused && setFocused( true ) }
        onBlur={ () => setFocused && setFocused( false ) }
      />

      { 
        ! text.length ? null :
        <SendButton
          aria-label="Submit your guess"
          onClick={ submitText }
        />
      }

    </InputPanel>
  )
}
