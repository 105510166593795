

import styled from "@emotion/styled";
import * as React from "react";
import throttle from "lodash.throttle";

const CardScrollLayout: React.FC<React.PropsWithChildren<{ 
  onIndexChange?: Function
  onPause?: Function
  onResume?: Function
  autoscrollTime?: number
  loopBackToStart?: boolean
}>> = ({ children, onIndexChange, onPause, onResume, autoscrollTime = 2000, loopBackToStart = false }) => {

  const [index, setIndex] = React.useState(-1);

  const interval = React.useRef<any>();

  const getItems = (children) => {
    if (!children)
      return [];
    if (Array.isArray(children))
      return children;

    return [children[0]]
  }

  const items = getItems(children);
  const itemsLength = items.length;
  let itemContainer;

  const goTo = (newIndex, instante = false) => {
    if (!itemContainer || newIndex < 0 || newIndex >= itemsLength)
      return;

    var width = itemContainer.clientWidth / 2;
    var itemWidth = itemContainer.clientWidth / itemsLength;
    itemContainer.style.transform = `translateX(${width + - (itemWidth / 2) - itemWidth * (newIndex)}px)`
    itemContainer.style.transition = `transform ${instante ? 0 : 1}s`
    itemContainer.style.visibility = `visible`
    setIndex(newIndex);
  }

  const onClick = (e: any) => {
    e.pageX > 0.5 * window.innerWidth
      ? (index < itemsLength - 1 && setIndex((index + 1) % itemsLength))
      : (index > 0 && setIndex(index <= 0 ? itemsLength - 1 : index - 1));
  };

  const throttleCallback = throttle(index => { goTo(index) }, 250, { 'trailing': false });
  const throttledGoTo = React.useCallback(throttleCallback, []);
  React.useEffect(throttleCallback.cancel, [index]);

  const wheel = (e) => {
    const goToIndex = e.deltaY > 0 ? (index + 1) : (index - 1)
    throttledGoTo(goToIndex);
  }

  const keyDown = (e) => {
    if (e.key == "ArrowRight") {
      throttledGoTo(index + 1);
      return
    }

    if (e.key == "ArrowLeft") {
      throttledGoTo(index - 1);
      return
    }
  }

  React.useEffect(() => {
    if (index == -1) {
      goTo(0, true);
      return;
    }
    
    if(onIndexChange)
      onIndexChange(index)
  }, [index]);

  
  const createAutoScrollInterval = () => {
    return setInterval(() => {
      if (index < itemsLength - 1)
        goTo(index + 1);
      else if(loopBackToStart) {
         goTo(0);
      }
        
    }, autoscrollTime);
  };

  React.useEffect(() => {
    interval.current = createAutoScrollInterval();
    return () => clearInterval(interval.current);
  }, [items.length, index]);


  React.useEffect(() => {
    document.body.addEventListener("pointerdown", keyDown, false);
    return () => document.body.removeEventListener("pointerdown", keyDown, false);
  }, [index]);

  
  const onPointerDown = () => {
    if(onPause) onPause();
    clearInterval(interval.current);
  };
  const onPointerUp = () => {
    if(onResume) onResume();
    clearInterval(interval.current);
    interval.current = createAutoScrollInterval();
  };

  React.useEffect(() => {
    document.body.addEventListener("pointerdown", onPointerDown, false);
    return () => document.body.removeEventListener("pointerdown", onPointerDown, false);
  }, [index]);

  React.useEffect(() => {
    document.body.addEventListener("pointerup", onPointerUp);
    return () => document.body.removeEventListener("pointerup", onPointerUp);
  }, [index]);

  return (
    <Wrapper >
      <div className={`con`}
        onWheel={wheel}

        ref={(e: any) => {
          if (!e || itemContainer) { return };
          itemContainer = e;
        }}>
        {items.map((child, i) => {
          const cls = "normal";
          const style = {
            opacity: index === i ? 1 : 0.35,
          };
          return (
            <div className={`item ${cls}`} style={style} key={i} onPointerDown={(e) => { throttledGoTo(i); }}>
              { child }
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
}


const Wrapper = styled.div`

.con {
  display: flex;
  flex-direction: row;
  transform: translateX(0);
  visibility: hidden;
}

.item {
  background-color: transparent;

  margin: 0 20px;
  transition: opacity 250ms, left 250ms ease-out, transform 250ms ease-out;

  display: flex;
  align-items: center;
  justify-content: center;
}
`

export default CardScrollLayout;
