export const isPromise = (subject: any):subject is PromiseLike<any>|Promise<any> => typeof subject?.then === 'function';

export const delay = ( seconde:number ) => new Promise( done => setInterval( done, seconde * 1000 ) )

export const nextFrame = () => new Promise(resolve => requestAnimationFrame(resolve));

/**
 * @param condition primise will resolve the first time this returns true
 * @param timeout in seconds
 */
export const waitFor = ( condition:()=>boolean, timeout?:number ) => {
  if ( !! timeout ) {
    return new Promise<void>( resolve => {
      const done = (timedOut=false) => {
        timedOut && ( console.warn( `waitFor() timed out...` ) )
        clearTimeout( timeoutHandle );
        resolve();
      }
      const timeoutHandle = setTimeout( () => done(true), timeout * 1000 )
      const onEnterFrame = () => condition() ? done() : requestAnimationFrame(onEnterFrame);
      onEnterFrame();
    } );
  } else {
    return new Promise<void>( resolve => {
      const onEnterFrame = () => condition() ? resolve() : requestAnimationFrame(onEnterFrame);
      onEnterFrame();
    } );
  }
}
