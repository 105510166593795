import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/analytics';

const config = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  databaseURL: process.env.DATABASE_URL,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  appId: process.env.APP_ID,
  measurementId: process.env.MEASUREMENT_ID,
}

firebase.initializeApp( config );
firebase.analytics();

export const database = firebase.database();

export const googleProvider = new firebase.auth.GoogleAuthProvider();

export const facebookProvider = new firebase.auth.FacebookAuthProvider();

export const auth = firebase.auth();

export default firebase;

// auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

//// Debug shit

import { DEVELOPMENT_MODE } from '../debug/consts';
DEVELOPMENT_MODE && ( window['firebase'] = firebase );
DEVELOPMENT_MODE && ( window['database'] = database );
DEVELOPMENT_MODE && ( window['auth'] = auth );
DEVELOPMENT_MODE && ( window['dbget'] = async (path:string) => (await database.ref(path).once('value')).val() );
