import React from 'react';

import styled from '@emotion/styled';
import { COLOR_LIGHT } from '@ven/shared/components/styling/colors';
import { MOBILE_BREAK_PX } from '@ven/shared/components/styling/breakpoints';

//@ts-ignore
import Confetti from "@ven/components/assets/scribbler/confetti.png"

export const OverlayDialogBase:React.FC<any> = ({ children, dimColor="#0009", confetti=false, popAnimation=true, ...props }) =>
{
  return (
    <Wrapper color={ dimColor }>
      <FullScreen>
        {
          confetti &&
          <img className={`confetti ${popAnimation ? 'animation' : null}`} src={ Confetti } />
        }
        <Pad className={`${popAnimation ? 'animation' : null}`} {...props}>
          { children }
        </Pad>
      </FullScreen>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  position: absolute;
  background: ${ props => props.color };
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  overflow: hidden;

  min-height: 500px;

  @media (max-width: 650px) {
    position: fixed;
    height: 200%;
    width: 250%;
    left: -100%;
  }
  @media (min-width: 650px) {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .confetti {
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;

    &.animation {
      @keyframes zoom5050 { from { opacity: 0; transform: translate(-50%,-50%) scale(.5); } }
      animation: zoom5050 250ms;
    }
  }
`

export const FullScreen = styled.div`
  /* background: #F003; */
  top:0;
  left:0;
  right:0;
  bottom:0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: ${ MOBILE_BREAK_PX }px) {
    position: fixed;
  }
  @media (min-width: ${ MOBILE_BREAK_PX }px) {
    position: absolute;
  }
`

export const Pad = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;

  background: #FFFFFF;
  /* background: ${ COLOR_LIGHT }; */
  box-sizing: border-box;
  box-shadow: -6px 7px 28px rgba(0, 0, 0, 0.31);
  border-radius: 12px;
  margin: 75px;
  padding: 38px 12px;
  
  &.animation {
    animation: zoom 600ms cubic-bezier(.36,1.92,.44,.75);
  }

  @media (max-width: ${ MOBILE_BREAK_PX }px) {
    margin: 65px 38px;
    width: 80%;
    max-width: 500px;
  }
  @media (min-width: ${ MOBILE_BREAK_PX }px) {
    width: 500px;
  }
`

export const SmallText = styled.div`
  padding-top: 8px;
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: #A19AC6;
  text-align: center;
`

export const LargeText = styled.div`
  padding-top: 8px;
  padding-bottom: 28px;
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #303744;
`
