import React, { FormEvent, useState } from 'react';
import {
    SignInWithGoogle,
    SignInWithFacebook,
    LogInWithEmailAndPassword,
    SignUpWithEmailAndPassword,
    ResetPassword
} from '../../../services/AuthenticationService';

import closeImg from '../../../assets/images/close_black.svg';
import emailIcon from '../../../assets/images/email_icon.svg';
import userIcon from '../../../assets/images/user_icon.svg';
import passwordIcon from '../../../assets/images/password_icon.svg';
import facebookButton from '../../../assets/images/facebook_button.png';
import googleButton from '../../../assets/images/google_button.png';
import footerLeft from '../../../assets/images/login_footer_1.svg';
import footerRight from '../../../assets/images/login_footer_2.svg';
import line from '../../../assets/images/line.svg';

import './styles.scss'
import { useHistory } from 'react-router-dom';
import { UserDataService } from '@ven/platform/main/services/user/UserDataService';

const Login = ({ loginCloseCallback, activeView }) => {
    const history = useHistory();
    const [view, changeView] = useState(activeView);
    const [username, setUsername] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [keepInformed, setKeepInformed] = useState(true);

    const [emailError, setEmailError] = useState(false);
    const [emailTextError, setEmailTextError] = useState('This is not a valid email.');
    const [passwordError, setPasswordError] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [somethingChanged, setSomethingChanged] = useState(false);

    const [passwdEmail, setPasswdEmail] = useState('');
    const [emailPasswdError, setEmailPasswdError] = useState(false);

    const [loadingLogin, setLoadingLogin] = useState(false);

    const closeModal = () => {
        loginCloseCallback()
    }

    const login = async (e: FormEvent, type: string) => {
        e.preventDefault();
        let logged: boolean | string | undefined = false;
        try {
            setLoadingLogin(true)
        
            if (type === 'g') {
                logged = await SignInWithGoogle(keepInformed);
            } else if (type === 'f') {
                logged = await SignInWithFacebook(keepInformed);
            } else if (type === 'ep') {

                if(!userEmail) {
                    setEmailError(true);
                }
                if(!userPassword) {
                    setPasswordError(true);
                }
                logged = await LogInWithEmailAndPassword(userEmail, userPassword);
                if (typeof logged === 'string') {
                    if (logged === 'auth/user-not-found') {
                        setEmailTextError('There is no account for that email address.');
                        setEmailError(true);
                        setLoadingLogin(false)
                        
                    }
                    if (logged === 'auth/wrong-password') {
                        setPasswordError(true);
                        setLoadingLogin(false)
                    }
                }
                setLoadingLogin(false) 
        }
        if (typeof logged === 'boolean' && logged === true) {
            closeModal();
            // history.push('/');
        }
        } catch (error) {
            setLoadingLogin(false) 
        }
    }

    const signup = async (e: FormEvent, type: string) => {
        e.preventDefault();
        try {
            setLoadingLogin(true)
            
            let logged: boolean | string | undefined = false;
            if (type === 'g') {
                logged = await SignInWithGoogle(keepInformed);
            } else if (type === 'f') {
                logged = await SignInWithFacebook(keepInformed);
            } else if (type === 'ep') {
                checkEmailError(userEmail);
                checkPasswordError(userPassword);
                checkUsernameError(username);

                if (somethingChanged) {
                    if (!usernameError && !emailError && !passwordError) {
                        logged = await SignUpWithEmailAndPassword(username, userEmail, userPassword, keepInformed);
                        if (!logged) {
                            setEmailTextError('Email already registered.');
                            setEmailError(true);
                            setLoadingLogin(false) 
                        }
                    }
                }
                setLoadingLogin(false) 
            }
            if (logged) {
                // const username = UserDataService.get('username') || '';
                // AccountCreated(username,type,'SignUpModal');
                closeModal();
            }
        } catch (error) {
            setLoadingLogin(false) 
        }
    }

    const goToSignUp = (e: FormEvent) => {
        changeView('signup');
        e.preventDefault();
    }

    const goToLogin = (e: FormEvent) => {
        changeView('login');
        e.preventDefault();
    }

    const goToTerms = (e: FormEvent) => {
        e.preventDefault();
        history.push('/termsandconditions');
        closeModal();
    }

    const checkEmailError = (emailValue: string) => {
        setSomethingChanged(true);
        setUserEmail(emailValue);
        if (!userEmail) {
            setEmailTextError('This is not a valid email.');
            setEmailError(true);
        }

        if (typeof userEmail !== 'undefined') {
            let lastAtPos = userEmail.lastIndexOf('@');
            let lastDotPos = userEmail.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && userEmail.indexOf('@@') == -1 && lastDotPos > 2 && (userEmail.length - lastDotPos) > 2)) {
                setEmailTextError('This is not a valid email.');
                setEmailError(true);
            } else {
                setEmailError(false);
            }
        }
    }

    const checkPassEmailError = (emailValue: string) => {
        setPasswdEmail(emailValue);
        if (!passwdEmail) {
            setEmailPasswdError(true);
        }

        if (typeof passwdEmail !== 'undefined') {
            let lastAtPos = passwdEmail.lastIndexOf('@');
            let lastDotPos = passwdEmail.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && passwdEmail.indexOf('@@') == -1 && lastDotPos > 2 && (passwdEmail.length - lastDotPos) > 2)) {
                setEmailPasswdError(true);
            } else {
                setEmailPasswdError(false);
            }
        }
    }

    const checkUsernameError = (value: string) => {
        setSomethingChanged(true);
        setUsername(value);
        if (value.length === 0 || value === '') {
            setUsernameError(true);
        } else {
            setUsernameError(false);
        }
    }

    const checkPasswordError = (passwd: string) => {
        setSomethingChanged(true);
        setUserPassword(passwd);
        const passwordArray = passwd.split('');
        let condition1 = false;
        let condition2 = false;
        let condition3 = false;
        let condition4 = passwd.length > 5;
        for (const password of passwordArray) {
            // Have upper case letter
            if ((password === password.toUpperCase()) &&
                (isNaN(parseInt(password, 10) * 1))) {
                condition1 = true;
            }
            // Have lower case letter
            if ((password === password.toLowerCase()) &&
                (isNaN(parseInt(password, 10) * 1))) {
                condition2 = true;
            }
            // Have one number
            if (!isNaN(parseInt(password, 10) * 1)) {
                condition3 = true;
            }
        }

        if (!condition1 || !condition2 || !condition3 || !condition4) {
            setPasswordError(true);
        } else {
            setPasswordError(false);
        }
    }

    const forgotPasswd = (e: FormEvent) => {
        e.preventDefault();
        changeView('passwd');
    }

    const [showCheckEmailMsg, setShowCheckEmailMsg] = useState(false);

    const sendResetPasswdEmail = async (e: FormEvent) => {
        e.preventDefault();
        checkPassEmailError(passwdEmail);
        if (!emailPasswdError) {
            await ResetPassword(passwdEmail);
            setShowCheckEmailMsg(true);
            setTimeout(() => {
                setShowCheckEmailMsg(false);
                closeModal();
            }, 5000);
        }
    }

    const passwordChange = (e) => {
        setUserPassword(e.target.value);
        setPasswordError(false);
    }

    return (
        <div className="modal-landing">
            <div className="modal-content">
                <div className="close-button">
                    <img src={closeImg} onClick={() => closeModal()} alt="Close" />
                </div>
                <div className="modal-items">
                    {/* DIV for login */}
                    {view === 'login' &&
                        <div>
                            <h1>Login to VEN Games</h1>
                            <form>
                                <div className={`input-box ${emailError ? 'error-input' : ''}`}>
                                    <img className="input-icon" src={emailIcon} alt="" />
                                    <input
                                        type="email"
                                        name="userEmail"
                                        placeholder="E-mail"
                                        id="userEmail"
                                        autoComplete="off"
                                        onBlur={(e) => checkEmailError(e.target.value)}
                                        onChange={(e) => setUserEmail(e.target.value)}
                                    />
                                    <div></div>
                                </div>
                                {emailError &&
                                    <p className="error">{emailTextError}</p>
                                }
                                <div className={`input-box ${passwordError ? 'error-input' : ''}`}>
                                    <img className="input-icon" src={passwordIcon} alt="" />
                                    <input
                                        type="password"
                                        name="userPassword"
                                        placeholder="Password"
                                        id="userPassword"
                                        autoComplete="off"
                                        onChange={(e) => passwordChange(e)}
                                    />
                                    <div></div>
                                </div>
                                {passwordError &&
                                    <p className="error">Wrong password.</p>
                                }
                                <div className="forgot-password-button">
                                    <div onClick={(e) => forgotPasswd(e)}>
                                        Forgot your password?
                                    </div>
                                </div>
                                <input type="submit" value={`${loadingLogin ? 'Processing...' : 'Log In'}`} className="login-button" onClick={(e) => login(e, 'ep')}/>
                            </form>
                        </div>
                    }
                    {/* DIV for sign up */}
                    {view === 'signup' &&
                        <div className="signup">
                            <h1>Sign Up to VEN Games</h1>
                            <form>
                                <div className={`input-box ${usernameError ? 'error-input' : ''}`}>
                                    <img className="input-icon" src={userIcon} alt="" />
                                    <input
                                        type="text"
                                        name="username"
                                        maxLength={30}
                                        placeholder="Username"
                                        id="username"
                                        autoComplete="off"
                                        onBlur={(e) => checkUsernameError(e.target.value)}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                    <div></div>
                                </div>
                                {usernameError &&
                                    <p className="error">You must have a username.</p>
                                }
                                <div className={`input-box ${emailError ? 'error-input' : ''}`}>
                                    <img className="input-icon" src={emailIcon} alt="" />
                                    <input
                                        type="email"
                                        name="userEmail"
                                        placeholder="E-mail"
                                        id="userEmail"
                                        autoComplete="off"
                                        onBlur={(e) => checkEmailError(e.target.value)}
                                        onChange={(e) => setUserEmail(e.target.value)}
                                    />
                                    <div></div>
                                </div>
                                {emailError &&
                                    <p className="error">{emailTextError}</p>
                                }
                                <div className={`input-box ${passwordError ? 'error-input' : ''}`}>
                                    <img className="input-icon" src={passwordIcon} alt="" />
                                    <input
                                        type="password"
                                        name="userPassword"
                                        placeholder="Password"
                                        id="userPassword"
                                        autoComplete="off"
                                        onBlur={(e) => checkPasswordError(e.target.value)}
                                        onChange={(e) => passwordChange(e)}
                                    />
                                    <div></div>
                                </div>
                                {passwordError &&
                                    <p className="error">Password needs to be at least 6 characters in length, must include at least one lower case letter, one upper case letter and one number.</p>
                                }
                                <div className="check-box">
                                    <div>
                                        <input
                                            type="checkbox"
                                            name="sendUpdates"
                                            id="sendUpdates"
                                            checked={keepInformed}
                                            onChange={(e) => { setKeepInformed(e.target.value != "on"); e.target.value = e.target.value == "on" ? 'off' : 'on' ; }}
                                        />
                                        <div className="one-space">{' '}</div>
                                    </div>
                                    <p>Please send me promotions, coupon codes and updates of new games!</p>
                                </div>
                                <p className="terms">By signing up, I agree to the {" "}
                                    <span onClick={(e) => goToTerms(e)}>
                                        Terms of Service and Privacy Policy
                                        </span>
                                        , and I affirm I am at least 13 years old.</p>
                                <div>
                                    <button className="login-button" onClick={(e) => signup(e, 'ep')}>
                                        {`${loadingLogin ? 'Processing...' : 'Sign Up'}`} 
                                    </button>
                                </div>
                            </form>
                        </div>
                    }
                    {/* DIV for Google and Facebook buttons */}
                    {view !== 'passwd' &&
                        <div>
                            <div className="or-container">
                                <div>
                                    <img className="line" src={line} alt="" />
                                </div>
                                <div>
                                    <p className="or-text">OR</p>
                                </div>
                                <div>
                                    <img className="line" src={line} alt="" />
                                </div>
                            </div>
                            <div className="button-container">
                                {/* <button onClick={(e) => signup(e, 'f')}><img src={facebookButton} alt="Continue with Facebook" /></button> */}
                                <button onClick={(e) => signup(e, 'g')}><img src={googleButton} alt="Sign in with Google" /></button>
                            </div>
                            <div className="login">
                                <p>(Please allow your browser to show the popup)</p>
                                {
                                    view === 'login' &&
                                    <p>
                                        Don't have an account?{" "}
                                        <button onClick={(e) => goToSignUp(e)}>
                                            Signup here
                                        </button>
                                    </p>
                                }
                                {
                                    view === 'signup' &&
                                    <p>
                                        Already have an account?{" "}
                                        <button onClick={(e) => goToLogin(e)}>
                                            Login here
                                        </button>
                                    </p>
                                }
                            </div>
                        </div>
                    }
                    {/* DIV for forgot password functionality */}
                    {view === 'passwd' &&
                        <div>
                            <h1>Enter your email</h1>
                            <form>
                                <div className={`input-box ${emailPasswdError ? 'error-input' : ''}`}>
                                    <img className="input-icon" src={emailIcon} alt="" />
                                    <input
                                        type="email"
                                        name="passwdEmail"
                                        placeholder="E-mail"
                                        id="passwdEmail"
                                        autoComplete="off"
                                        onChange={(e) => setPasswdEmail(e.target.value)}
                                        onBlur={(e) => checkPassEmailError(e.target.value)}
                                    />
                                    <div></div>
                                </div>
                                {emailPasswdError &&
                                    <p className="error">This is not a valid email.</p>
                                }
                                <div>
                                    <button className="login-button" disabled={emailPasswdError} onClick={(e) => sendResetPasswdEmail(e)}>
                                        Submit
                                    </button>
                                </div>
                            </form>
                            {showCheckEmailMsg &&
                                <div className="check-email">
                                    Check your email! We sent you a link to reset your password!
                                </div>
                            }
                        </div>
                    }
                </div>
                <div className="footer">
                    <div className="footer-image1">
                        <img src={footerLeft} />
                    </div>
                    <div className="footer-image2">
                        <img src={footerRight} />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Login;
