import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from "lodash";

import './styles.scss'

import Bottom from './Bottom';
import ChooseHowToPlay from '../ChooseHowToPlay';
import { MainHeader } from '../../organisms/MainHeader';
import { GetCurrentUserRef, UserDataService } from '@ven/platform/main/services/user/UserDataService';


//@ts-ignore
import wiggleYellow from '../../../assets/images/homepage/wiggle_yellow.svg';
//@ts-ignore
import buttonOutlaw from '../../../assets/images/homepage/button_outlaw.png';
//@ts-ignore
import buttonScribbler from '../../../assets/images/homepage/button_scribbler.svg';
//@ts-ignore
import buttonBknmsg from '../../../assets/images/homepage/button_bknmsg.png';
//@ts-ignore
import imgOutlaw from '../../../assets/images/homepage/img_outlaw.svg';
//@ts-ignore
import imgScribbler from '../../../assets/images/homepage/img_scribbler.png';
//@ts-ignore
import imgBknmsg from '../../../assets/images/homepage/img_bknmsg.svg';
//@ts-ignore
import venGameLogo from '../../../assets/images/homepage/logo_vengames.png';
//@ts-ignore
import computer from '../../../assets/images/homepage/computer.svg';

//@ts-ignore
import closeWhite from '../../../assets/images/close_white.svg';
import { GameFunctionsService } from '@ven/shared/core/services/GameFunctionsService';
import { DEFAULT_CONFIG as BrokenMsgDefaultConfig } from '@ven/games/brokenmsg/gameplay/GameStateController';
import { DEFAULT_CONFIG as OutlawDefaultConfig } from '@ven/games/wordplay/gameplay/GameStateController';
import { DEFAULT_CONFIG as ScribblerDefaultConfig } from '@ven/games/scribbler/gameplay/GameStateController';
import { Modal, Button } from 'react-bootstrap';
import { VipCodeInput } from './Vip/VipCodeInput';
import { UserDataContext } from '@ven/platform/main/services/user/UserDataContext';
import { BeatLoader } from 'react-spinners';
import { LetsSnow } from '@ven/games/scribbler/components/LetsSnow';

const PostRelease = () => {
    const [vipMsgClosed, setVipMsgClosed] = useState(true);
    const [view, setView] = useState(1);
    const [modalView, setModalView] = useState(0);
    const [chooseModal, setChooseModal] = useState(false);
    const [hoverPlay, setHoverPlayer] = useState(false);
    const history = useHistory();
    const [isRedirecting, setRedirecting] = useState(false);
    
    const [disablePlayButton, setDisablePlayButton] = useState(false);

    const userData = React.useContext( UserDataContext );
    const [isVIP, setVIP] = useState(userData.isVip);

    const location = useLocation();
    const hasVipCodeQueryParam = new URLSearchParams(location.search).get('vipCode');
    // const [userData, setUserData] = useState(Object);
    // useEffect(() => {
    //   auth.onAuthStateChanged(async _ => {
    //     GetCurrentUserRef().then(ref => {
    //       if (!ref) {
    //         return;
    //       }
    //       ref.on('value', o => {
    //         const userData = o.val();
       
    //         setUserData({
    //           ...userData
    //         });
    //       });
    //     });
    //   });
    // }, [auth.currentUser?.uid]);
    
    const changeView = () => {
        const currentView = view;
        let nextView = currentView + 1;
        if (nextView > 3) nextView = 1;
        setView(nextView);
    }

    useEffect(() => {
        const query = window.location.pathname.toLowerCase();
        if (query === '/vip' || hasVipCodeQueryParam) {
            handleShowVipModal();
        }

    }, []);
    
    useEffect(() => {
        const query = window.location.pathname.toLowerCase();
      
        if (query !== '/team') {
            localStorage.removeItem('team');
        }
        const interval = setInterval(() => {
            if(chooseModal || disablePlayButton || isRedirecting || hoverPlay)
                return;
            changeView();
        }, 5000);
        return () => clearInterval(interval);
    });

    useEffect(() => {
        setDisablePlayButton(chooseModal);
    }, [chooseModal]);
    
    const getTopClass = () => {
        if (view === 1) return 'top top-outlaw';
        if (view === 2) return 'top top-scribbler';
        if (view > 2) return 'top top-bknmsg';
        return 'top top-outlaw';
    }

    const getGameNameClass = () => {
        if (view === 1) return 'game-name game-name-outlaw';
        if (view === 2) return 'game-name game-name-scribbler';
        if (view > 2) return 'game-name game-name-bknmsg';
        return 'game-name game-name-outlaw';
    }

    const forceChangeView = (newView: number) => {
        setView(newView);
    }

    const playGame = (view: number) => {
        setDisablePlayButton(true);
        if(isRedirecting)
            return;
        const views = ['outlaw', 'scribbler', 'brokenmsg']
        const choosenGame = views[view - 1] || views[0];

        UserDataService.set('choosenGame', choosenGame);

        const uid = UserDataService.get('uid');
        const isAnon = userData.providerId == 'Anonymous';
        
        if (uid && !isAnon || uid && isVIP) {
            setRedirecting(true);
            GameFunctionsService.createRoom(views[view - 1], uid)
                .then((data) => data && history.push(`/play/${data.code}`));
            return;
            // history.push(`/${choosenGame}`)
        } 

        window.scroll(0, 0);
        setModalView(0);
        setChooseModal(true);
    }

    const clickPlayGames = () => {
        UserDataService.clear('choosenGame');
        const uid = UserDataService.get('uid');
        const isAnon = UserDataService.get('isAnon');
        if (uid && isAnon === 'false') {
            window.scroll(0, 0);
            return;
        }

        window.scroll(0, 0);
        setModalView(0);
        
        isVIP
            ? playGame(view)
            : setChooseModal(true);
    }

    const clickedContinueWithoutRegister = () => {
        setChooseModal(false);
        window.scroll(0, 0);
    }

    const openFreebieFlow = (modalView: number) => {
        window.scroll(0, 0);
        UserDataService.clear('choosenGame');
        setModalView(modalView);
        setChooseModal(true);
    }

    const nextView = () => {
        let nxtView = view === 3 ? 1 : view + 1;
        forceChangeView(nxtView);
    }

    const previousView = () => {
        let nxtView = view === 1 ? 3 : view - 1;
        forceChangeView(nxtView);
    }

    const openLoginModal = () => {
        setChooseModal(false);
        const loginButton = document.getElementsByClassName('login')[0];
        (loginButton as any).click();
    }
    const openSignupModal = () => {
        setChooseModal(false);
        const loginButton = document.getElementsByClassName('signup')[0];
        (loginButton as any).click();
    }
    
  const isHoliday = !!UserDataService.get('holiday');
  
  const [showVipModal, setShowVipModal] = useState(false);

  const [showVipModalError, setShowVipModalError] = useState('');

  const handleClose = () => setShowVipModal(false);
  const handleShowVipModal = () => setShowVipModal(true);
  const [showVipModalSpinner, setShowVipModalSpinner] = useState(false);
  const [showVipModalSuccess, setShowVipModalSuccess] = useState(false);
  const [vipExpireIn, setVipExpireIn] = useState("");
    return (
        <div className={`full-container ${UserDataService.get('holiday') ? 'holiday' : ''}`}>
            <div className="preload1"></div>
            <div className="preload2"></div>
            <div className="preload3"></div>
            <div className="preload4"></div>
            <div className="preload5"></div>
            <div className="preload6"></div>
            <div className="preload7"></div>
            <div className="preload8"></div>
            <div className="preload9"></div>

           <LetsSnow isHoliday={isHoliday}/>
            
            {/* Choose How To Play Modal */}
            {chooseModal && (
                <div className="choose-modal">
                    <div className="full-screen-close" onClick={() => setChooseModal(false)} />
                    <ChooseHowToPlay chooseCloseCallback={clickedContinueWithoutRegister} modalView={modalView} loginCallback={openLoginModal}
                    signupCallback={openSignupModal}></ChooseHowToPlay>
                </div>
            )}

            <Modal show={showVipModal} onHide={handleClose} size='lg' centered={true}>
                {/* <Modal.Header closeButton> */}
                {/* <Modal.Title>Vip</Modal.Title> */}
                {/* </Modal.Header> */}
                <Modal.Body>
                    <div className="vip-body">
                        <div className="icon">👑</div>
                        {showVipModalSuccess &&
                            <div className="text success">
                                <p> All right! Now you are a VIP! </p>
                                <p className='mb-5'> (your VIP access will expires in <b>{vipExpireIn}</b>) </p>
                            </div>
                        }
                        {!showVipModalSuccess &&
                        <div className="text">
                            <p>{ !isVIP ? <span>Type your VIP code</span> : <span>You are a VIP already!</span>}</p>
                            <p>{ !isVIP ? <span> Are you a VIP? type and get VEN with all batteries included! </span> : <span className='vip-code-error'>Remember: Use a new VIP will replace your current code!</span>}</p>
                           
                            <div className="form-container">
                            <div className='form'>
                                <VipCodeInput data={{
                                    uid: userData.uid, 
                                    vipCode: hasVipCodeQueryParam,
                                    callback: (result) => {
                                        if(!result.token) {
                                            setShowVipModalError(result.error);
                                            return;
                                        }
                                        setVipExpireIn(result.expiresIn);
                                        setShowVipModalSuccess(true);
                                        setTimeout(handleClose, 2000);
                                        setVIP(true);
                                        UserDataService.set("vip", result.token);
                                    },
                                    callbackSpinner: setShowVipModalSpinner }}>
                                </VipCodeInput>
                                <div className='spinner'>{ showVipModalSpinner && <BeatLoader size={10}></BeatLoader>}</div>   
                            </div>
                            <div className='vip-code-error'>
                                { showVipModalError && <span>{showVipModalError}</span>}
                            </div>
                        </div>
                    </div>
                    }
                </div>
                </Modal.Body>
            </Modal>
      
            <div className="homepage-container">
                <div className="homepage-content">
                    {!vipMsgClosed &&
                        <div className="vip-header">
                            <div className="cool-message">
                                <div>
                                    {isVIP && <p>Someone cool gave you VIP Access ;)</p>}
                                </div>
                                <img src={closeWhite} alt="close" onClick={() => setVipMsgClosed(true)} />
                            </div>
                        </div>
                    }
                    <div className={getTopClass()}>
                        <MainHeader />
                        <div className="logo-container">
                            <img src={venGameLogo} alt="VEN GAMES" />
                            {
                                isVIP && <div className="vip-tag">
                                    <p>VIP</p>
                                </div>
                            }
                            <p className="logo-message">Play awesome games with friends. <br /> Anytime, anywhere.</p>
                        </div>
                        <div className="game-description">
                            <div className="title">
                                <div className={`title ${view === 2 ? 'title-scribbler' : ''}`}>
                                    {
                                        isVIP && <div className="vip-tag">
                                            <p>VIP</p>
                                        </div>
                                    }
                                    <img src={wiggleYellow} alt="" />
                                    <div className={getGameNameClass()}></div>
                                    <div className="game-text">
                                        {view === 1 &&
                                            <p>Get your teammates to guess as many words as possible without saying any of the forbidden words listed on the card. <br />
                                                From { OutlawDefaultConfig.minPlayersCount } to { OutlawDefaultConfig.maxPlayersCount } players.
                                            </p>
                                        }
                                        {view === 2 &&
                                            <p>In this quick-draw classic, the guesses can be just as hilarious as the sketches, making it the perfect way to get the party started. <br />
                                                 From { ScribblerDefaultConfig.minPlayersCount } to { ScribblerDefaultConfig.maxPlayersCount } players.
                                            </p>
                                        }
                                        {view > 2 &&
                                            <p>The visual version of the classic "telephone" game... where your sketches {'&'} guesses can create laugh-out-loud party fun! <br />
                                                From { BrokenMsgDefaultConfig.minPlayersCount } to { BrokenMsgDefaultConfig.maxPlayersCount } players.
                                            </p>
                                        }
                                    </div>
                                </div>
                                <button className="play-game" disabled={disablePlayButton || isRedirecting} onClick={() => playGame(view)}
                                onMouseEnter={() => setHoverPlayer(true)}
                                onMouseLeave={() => setHoverPlayer(false)}>
                                    { isRedirecting ? "Redirecting..." : "Play" }
                                    </button>
                                <div className="steppers">
                                    <div className="back-arrow" onClick={previousView}><p>{'<'}</p></div>
                                    <div className={`step ${view === 1 ? 'dot-selected' : ''}`} onClick={() => forceChangeView(1)}>{' '}</div>
                                    <div className={`step ${view === 2 ? 'dot-selected' : ''}`} onClick={() => forceChangeView(2)}>{' '}</div>
                                    <div className={`step ${view > 2 ? 'dot-selected' : ''}`} onClick={() => forceChangeView(3)}>{' '}</div>
                                    <div className="forward-arrow" onClick={nextView}><p>{'>'}</p></div>
                                </div>
                            </div>
                            <div className="image">
                                <img src={imgOutlaw} className={view === 1 ? 'showOutlawImg' : ''} alt="" />
                                <img src={imgScribbler} className={view === 2 ? 'showScribblerImg' : ''} alt="" />
                                <img src={imgBknmsg} className={view > 2 ? 'showBknmsgImg' : ''} alt="" />
                            </div>
                        </div>
                        <div className="game-buttons">
                            <div className={`button-outlaw ${view === 1 ? 'selected' : ''}`} onClick={() => forceChangeView(1)}>
                                <img src={buttonOutlaw} alt="Outlaw" />
                            </div>
                            <div className={`button-scribbler ${view === 2 ? 'selected' : ''}`} onClick={() => forceChangeView(2)}>
                                <img src={buttonScribbler} alt="Scribbler" />
                            </div>
                            <div className={`button-bknmsg ${view > 2 ? 'selected' : ''}`} onClick={() => forceChangeView(3)}>
                                <img src={buttonBknmsg} alt="BrokenMsg" />
                            </div>
                        </div>
                    </div>
                    <Bottom clickPlayGames={clickPlayGames} openFreebieFlow={openFreebieFlow} />
                </div>
            </div>
        </div>
    )
}

export default PostRelease;
