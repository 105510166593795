// import { GameNotImplementedPage } from '@ven/platform/main/components/pages/GameNotImplemented';

import { PlayerViewPage as PlayerViewPage_Scribbler } from '@ven/games/scribbler/components/pages/PlayerView';
import { GameDataProvider as DataProvider_Scribbler } from '@ven/games/scribbler/gameplay/GameDataProvider';

import { PlayerViewPage as PlayerViewPage_Outlaw } from '@ven/games/wordplay/components/pages/PlayerView';
import { GameDataProvider as DataProvider_Outlaw } from '@ven/games/wordplay/gameplay/GameDataProvider';

import { PlayerViewPage as PlayerViewPage_BrokenMsg } from '@ven/games/brokenmsg/components/pages/PlayerView';
import { GameDataProvider as DataProvider_BrokenMsg } from '@ven/games/brokenmsg/gameplay/GameDataProvider';

import type { GameID } from '@ven/core/games';

export interface GameComponents
{
  renderPlayerPage:React.FC<{ 
    gameId : string,
    roomId : string,
    userId : string,
  }>
  provideData?:React.FC<{ 
    gameId : string,
    roomId : string,
    roomCode : string,
    userId : string,
  }>
}

export const GAMES:Record<GameID,GameComponents> = {
  "scribbler":
  {
    renderPlayerPage : PlayerViewPage_Scribbler,
    provideData : DataProvider_Scribbler,
  },
  "outlaw":
  {
    renderPlayerPage : PlayerViewPage_Outlaw,
    provideData : DataProvider_Outlaw,
  },
  "brokenmsg":
  {
    renderPlayerPage : PlayerViewPage_BrokenMsg,
    provideData : DataProvider_BrokenMsg,
  },
}
