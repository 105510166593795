import { analyticsService } from "@ven/platform/main/services/AnalyticsService";
import { UserDataService } from "@ven/platform/main/services/user/UserDataService";
import { DEVELOPMENT_MODE } from "../debug/consts";

import type { GameID } from "../games";

const URL_BASE = process.env.FUNCTION_URL;

export type RoomPointerData = {
  code: string,
  created: number,
  gameId: string,
  roomId: string,
  sessionPath: string,
  eventHost: string,
}

export const GameFunctionsService = {
  async post( route:string, body:any ) {
    try {
      // const response = await fetch(
        // `${ route.includes('update') || route.includes('join') || route.includes('create') 
        // || route.includes('joinPuppets') || route.includes('cleanPuppets') ?
                // 'http://localhost:5001/ven-games---staging/us-central1/app' : URL_BASE }/game/${ route }`, {
      const response = await fetch(`${ URL_BASE }/game/${ route }`, {
      method: 'post',
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" }
      });
      const data = await response.json();
      console.log(`Got fbf data via /${ route }: ${ JSON.stringify( data, null, 2 ) }`)
      if ( data.error ) {
        throw new Error( "FB Game Function Error: " + data.error );
      }
      return data.data as RoomPointerData;
    } catch ( error ) {
      console.error( error );
      return false;
    }
  },
  async createRoom( gameId:GameID, userId:string, isEventHost?: boolean ) {
    analyticsService.GameCreated(gameId, userId);

    let body:any = { gameId, userId };

    if(isEventHost)
      body.eventHost = userId
   
    return await this.post('create', body)
  },
  async joinRoom( code:string, userId:string ) {
    return await this.post('join', { code, userId })
  },
  async joinPuppets(code:string, amount:number, puppetId:string = "puppet" ) {
    return await this.post('joinPuppets', { code, amount, puppetId })
  },
  async cleanPuppets(code:string) {
    return await this.post('cleanPuppets', { code })
  },
  async getRoomData( code:string ) {
    return await this.post('get', { code })
  },
  async updateUserId( userId:string, oldId:string ) {
    return await this.post('update', { userId, oldId })
  },
  playAgainGame(gameName: string, nextGameUrl: string | undefined, isHost: boolean, updateState: Function, setRedirecting: Function, isRedirecting, history, isSpectator) {
    if(isRedirecting)
      return;

    if(nextGameUrl) {
      analyticsService.PlayAgainPressed()
      setRedirecting(true);
      if(isSpectator) {
        nextGameUrl = nextGameUrl.replace('/play/', '/watch/')
      }
      window.location.assign(nextGameUrl);
      // return history.push(nextGameUrl);
    } 
    
    if(!isHost || isSpectator)
      return;

    UserDataService.set('choosenGame', gameName);
    const uid = UserDataService.get('uid');

    if (!uid) {
      return;
    }

    GameFunctionsService.createRoom(gameName, uid)
      .then((data) => { 
        if(!data)
          return false;
        
        // const isSpectator = window.location.href.includes('/watch/');
        const gameUrl = `/play/${data.code}`;
        updateState({ nextGameUrl: gameUrl })
        setRedirecting(true);
        window.location.assign(gameUrl);
      })
  }
}

// import firebase from "firebase"
// import { app } from "../data/firebase"
// const data = { code : "127101", userId: 'PlayerFour', gameId: 'scribbler' }
// var foo = firebase.functions(app).httpsCallable("app/game/create")
// foo({ data }).then(function(result) {
//   console.log(result)
// })

DEVELOPMENT_MODE && ( window['GameFunctionsService'] = GameFunctionsService );
