import React, { useMemo, useRef } from "react";

import { useWindowSize } from "../../hooks/useWindowSize";
import { DEVELOPMENT_MODE } from "@ven/core/debug/consts";

import styled from "@emotion/styled";

const DEBUG = true && DEVELOPMENT_MODE;
type Dimensions = { width:number, height:number, insetX?:number, insetY?:number }

type Props = React.PropsWithChildren<{
  designDimensions:Dimensions,
  className?:string,
  noUpscale?:boolean,
  fixed?:boolean,
  disabled?:boolean;
  alignTop?:boolean;
}>

export const SelfScalingContent:React.FC<Props> = ({ 
  designDimensions, children, fixed, disabled, alignTop, ...props 
}) => 
{
  if ( disabled ) {
    return <>{ children }</>;
  }

  const scale = calculateScale( useWindowSize(), designDimensions, props.noUpscale )
  const style = { transform : `scale(${ scale.toPrecision(4) })` }

    
  return (
    <Wrapper {...props} className={ fixed ? 'fixed' : '' } designDimensions={ designDimensions } alignTop={alignTop}>
      <ScaledContent style={ style } dimensions={ designDimensions } alignTop={alignTop}>
        { children }
      </ScaledContent>
    </Wrapper>
  );
};

const Wrapper = styled.div<any>`
  overflow: hidden;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${ props => props.alignTop ? 'flex-start' : 'center' };

  transition: top 300ms;

  border: ${ DEBUG ? `red solid 2px` : `none` };

  &.fixed {
    position: fixed;
    top: ${ props => props.designDimensions.insetY || 0 }px;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

const ScaledContent = styled.div<{ dimensions:Dimensions, alignTop }>`
  width: ${ props => props.dimensions.width }px;
  height: ${ props => props.dimensions.height }px;
  position: absolute;
  box-sizing: border-box;
  outline: ${ DEBUG ? `yellow dashed 1px` : `none` };
  transform-origin:  ${ props => props.alignTop ? 'top' : 'center' }; ;
`


interface ViewSize { width:number, height:number }
interface IDimensions { width:number, height:number, insetX?:number, insetY?:number }

export const calculateScale = ( view:ViewSize, dimensions:IDimensions, noUpscale=false) => {
  return Math.min(
    (view.width - (dimensions.insetX || 0)) / dimensions.width,
    (view.height - (dimensions.insetY || 0)) / dimensions.height,
    noUpscale ? 1.0 : Number.POSITIVE_INFINITY
  );
}
