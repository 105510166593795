import React, { useCallback, useEffect, useMemo, useState } from 'react';

import type { CanvasAction, CanvasAction_BrushStart, CanvasActoin_BrushMove } from '@ven/games/scribbler/drawing/CanvasAction';
import { DrawingSettingsContext, DrawingTool } from './DrawingSettings';
import { colorIntegerToHashTag } from '@ven/shared/core/utils/colors';

import styled from '@emotion/styled';
import { DrawingPalette } from './DrawingPalette';
import { configuration } from './configuration';

type Coordinates = {
  x: number;
  y: number;
};

interface Props 
{
  data : CanvasAction[],
  update : (data:CanvasAction[]) => any,
  config : {
    width: number,
    height: number,
    minLineLength: number,
  
  }
  readonly?: boolean
}
export const DrawingCanvas:React.FC<Props> = ({ config, children, data, update, readonly }) => 
{
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const canvasTempRef = React.useRef<HTMLCanvasElement>(null);
  const [context, setContext] = React.useState<CanvasRenderingContext2D | null>(null);
  const [contextTemp, setContextTemp] = React.useState<CanvasRenderingContext2D | null>(null);

  const { width, height } = config

  ////TODO this is redrawing the entire canvas, must fix ! ! ! ! !
  const { settings } = React.useContext( DrawingSettingsContext )

  ////TODO rename this, or better split all this stuff
  //// into someone to handle in/out drawing data
  //// and someone to handle actual local input 
  let prevPointActive = {x: 0, y: 0}; 

  const prevPoint = {x: 0, y: 0};
  const points:Coordinates[] = [];

  const tempData:CanvasAction[] = []

  const clearCanvasContext = ( context:CanvasRenderingContext2D ) =>
  {
    context.clearRect( 0, 0, width, height );
  }

  const onRealAction = ( action:CanvasAction ) =>
  {
    tempData.push( action );
    if ( action[0] === 2 ) {
      data.push( ...tempData );
      update( data );
      tempData.length = 0;
    }
    handleAction( action );
  }

  const handleAction = ( action:CanvasAction ) =>
  {
    if ( context && contextTemp )
    {
      const [ actionType ] = action
      
      const onPaint = () => {
        points.push({ ...prevPoint });
        
        if (points.length < 3) {
          const b = points[0];
          contextTemp.beginPath();
          contextTemp.arc(b.x, b.y, contextTemp.lineWidth / 2, 0, Math.PI * 2, !0);
          contextTemp.fill();
          contextTemp.closePath();
          return;
        }
        
        clearCanvasContext( contextTemp )
        contextTemp.beginPath();
        contextTemp.moveTo(points[0].x, points[0].y);
        
        for (var i = 1; i < points.length - 2; i++) {
          const c = (points[i].x + points[i + 1].x) / 2;
          const d = (points[i].y + points[i + 1].y) / 2;
          contextTemp!.quadraticCurveTo(points[i].x, points[i].y, c, d);
        }
        
        contextTemp!.quadraticCurveTo(
          points[i].x,
          points[i].y,
          points[i + 1].x,
          points[i + 1].y
        );

        contextTemp.stroke();
      };

      if ( actionType === 0 )
      {
        const [ , x, y, color, size ] = action as CanvasAction_BrushStart
        contextTemp.strokeStyle = colorIntegerToHashTag( color < 0 ? 0xFFFFFF : color )
        contextTemp.fillStyle = colorIntegerToHashTag( color < 0 ? 0xFFFFFF : color )
        contextTemp.lineWidth = size;
        contextTemp.lineCap = "round";
      
        prevPoint.x = x
        prevPoint.y = y
        onPaint()
        
        context.globalCompositeOperation = color < 0 ? "destination-out" : "source-over"
      }
      if ( actionType === 1 )
      {
        const [ , x, y ] = action as CanvasActoin_BrushMove

        prevPoint.x = x
        prevPoint.y = y
        onPaint()
      }
      if ( actionType === 2 )
      {
        context.drawImage( canvasTempRef.current!, 0, 0 )
        clearCanvasContext( contextTemp )
        points.length = 0;
      }
    }
  }

  const getXY = (e:MouseEvent|Touch) =>
  {
    const el = canvasRef.current!
    const rect = el.getBoundingClientRect()
    const { clientX, clientY } = e as any

    const result = {
      x: width * ( clientX - rect.x ) / rect.width,
      y: height * ( clientY - rect.y ) / rect.height,
    }

    return result
  }

  const drawData = () => {
    /** A nice check to have in general, but some tools
     *  like the undo and trash tool really have to 
     *  rerender the canves with the new data.
     */
    // if ( canvasRef.current?.['__done_flag'] === true ) {
    //   console.warn( `Canvas drawData() was called multiple times!!` );
    //   return;
    // } 
    // canvasRef.current!['__done_flag'] = true;
    
    clearCanvasContext(context!);
    
    for ( const action of data ) {
      handleAction( action )
    }
  }
  
  context && drawData();

  React.useEffect(() => 
  {
    if ( ! canvasRef.current || ! canvasTempRef.current )
    {
      return
    }

    if ( ! context ) {
      const ctx = canvasRef.current.getContext('2d');
      if( ctx ) {
        setContext(ctx);
      }
    }

    if ( ! contextTemp ) {
      const ctx = canvasTempRef.current.getContext('2d');
      if ( ctx ) {
        setContextTemp(ctx);
      }
    }

    const isInRange = ( a:Coordinates, b:Coordinates, range:number ) =>
    {
      return ( a.x - b.x ) ** 2 + ( a.y - b.y ) ** 2 < range ** 2
    }

    const isInCanvasBounds = ( { x, y }:Coordinates ) => ( x > 0 && x < width && y > 0 && y < height )

    let mouseDown: boolean = false;

    const handleMouseDown = (evt: MouseEvent) =>
    {
      evt.preventDefault();
      if(readonly)
        return;

      mouseDown = true;

      const { x, y } = getXY( evt )
      const color = settings.tool === DrawingTool.Draw ? settings.brushColor : -1
      onRealAction( [ 0, x, y, color, settings.brushSize ] )
      prevPointActive = { x, y }
    }

    const handleMouseUp = (evt: MouseEvent) =>
    {
      if(readonly)
        return;

      if (mouseDown)
      {
        evt.preventDefault();
        mouseDown = false;
        onRealAction( [ 2 ] )
      }
    }

    const handleMouseMove = (evt: MouseEvent) =>
    {
      if(readonly) {
        return;
      }

      if (!mouseDown) 
        return;

      evt.preventDefault();
      const { x, y } = getXY( evt )
      if ( isInCanvasBounds({ x, y }) || isInCanvasBounds( prevPointActive ) )
      {
        if ( ! isInRange( prevPointActive, { x, y }, config.minLineLength ) )
        {
          onRealAction( [ 1, x, y ] )
          prevPointActive = { x, y }
        }
      }
    }

    const onTouchStart = (evt: TouchEvent) =>
    {
      if(readonly)
        return;

      // document.body.requestFullscreen();
      evt.preventDefault();
      mouseDown = true;

      const { x, y } = getXY( evt.touches.item(0)! )
      const color = settings.tool === DrawingTool.Draw ? settings.brushColor : -1
      onRealAction( [ 0, x, y, color, settings.brushSize ] )
    }

    const onTouchEnd = (evt: TouchEvent) =>
    {
      if(readonly)
        return;

      if (mouseDown && context) {
        evt.preventDefault();
        mouseDown = false;
        onRealAction( [ 2 ] )
      }
    }

    const onTouchMove = (evt: TouchEvent) => 
    {
      if(readonly) {
        return;
      }

      if (mouseDown && context) {
        evt.preventDefault();
        const { x, y } = getXY( evt.touches.item(0)! )
        onRealAction( [ 1, x, y ] )
      }
    }

    if ( canvasRef.current ) {
      canvasRef.current.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);

      canvasRef.current.addEventListener("touchstart", onTouchStart);
      canvasRef.current.addEventListener('touchmove', onTouchMove);
      document.addEventListener('touchend', onTouchEnd);

      data.forEach( a => handleAction( a ) )

      return () => {
        if (canvasRef.current) {
          canvasRef.current.removeEventListener('mousedown', handleMouseDown);
          document.removeEventListener('mousemove', handleMouseMove);
          document.removeEventListener('mouseup', handleMouseUp);
          
          canvasRef.current.removeEventListener("touchstart", onTouchStart);
          canvasRef.current.removeEventListener('touchmove', onTouchMove);
          document.removeEventListener('touchend', onTouchEnd);
        }
      }
    }

    return
	
  }, [ !! canvasRef.current && !! canvasTempRef.current, settings, data.length, readonly ]);

  return (
    <Wrapper
    className="dropping-the-shadow drawing-canvas"
    width={ width }
    style={{ 
      width: width + "px",
      height: height + "px",
    }}>
      <DrawingPalette colors={configuration.tools.brushColors} sizes={configuration.tools.brushSizes} />
      <RealCanvas
        id="canvas"
        ref={canvasRef}
        width={width}
        height={height}
      />
      <TempCanvas
        id="canvas-temp"
        ref={canvasTempRef}
        width={width}
        height={height}
      />
      { children }
    </Wrapper>
  );
}

const Wrapper = styled.div<{ width:number }>`
  position: relative;
  overflow: hidden;
  @media (min-width: ${ props => props.width }px) {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  canvas {
    cursor: grabbing;
  }
`

const RealCanvas = styled.canvas`
  width: 100%;
  height: 100%;
`

const TempCanvas = styled.canvas`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  cursor: crosshair;
  opacity: 0.6;
  touch-action: none;
  pointer-events: none;
  width: 100%;
  height: 100%;
`
