import React, { useEffect } from 'react';

import { UserDataContext } from '@ven/platform/main/services/user/UserDataContext';
import { UpdateUsernameAvatar } from '@ven/platform/main/services/user/UserDataService';
import { UserDataService } from '@ven/platform/main/services/user/UserDataService';
import debounce from "lodash.debounce";

import styled from "@emotion/styled";
import Marquee from 'react-fast-marquee';

//@ts-ignore
import group0 from '@ven/platform/main/assets/images/teams/Mask-group.svg';
//@ts-ignore
import group1 from '@ven/platform/main/assets/images/teams/Mask-group-1.svg';
//@ts-ignore
import group2 from '@ven/platform/main/assets/images/teams/Mask-group-2.svg';
//@ts-ignore
import group3 from '@ven/platform/main/assets/images/teams/Mask-group-3.svg';
//@ts-ignore
import group4 from '@ven/platform/main/assets/images/teams/Mask-group-4.svg';
//@ts-ignore
import group5 from '@ven/platform/main/assets/images/teams/Mask-group-5.svg';
//@ts-ignore
import group6 from '@ven/platform/main/assets/images/teams/Mask-group-6.svg';
//@ts-ignore
import group7 from '@ven/platform/main/assets/images/teams/Mask-group-7.svg';
//@ts-ignore
import group8 from '@ven/platform/main/assets/images/teams/Mask-group-8.svg';
//@ts-ignore
import group9 from '@ven/platform/main/assets/images/teams/Mask-group-9.svg';
//@ts-ignore
import group10 from '@ven/platform/main/assets/images/teams/Mask-group-10.svg';
//@ts-ignore
import group11 from '@ven/platform/main/assets/images/teams/Mask-group-11.svg';
//@ts-ignore
import group12 from '@ven/platform/main/assets/images/teams/Mask-group-12.svg';
//@ts-ignore
import group13 from '@ven/platform/main/assets/images/teams/Mask-group-13.svg';
//@ts-ignore
import group14 from '@ven/platform/main/assets/images/teams/Mask-group-14.svg';
//@ts-ignore
import group15 from '@ven/platform/main/assets/images/teams/Mask-group-15.svg';
//@ts-ignore
import group16 from '@ven/platform/main/assets/images/teams/Mask-group-16.svg';
//@ts-ignore
import group17 from '@ven/platform/main/assets/images/teams/Mask-group-17.svg';
//@ts-ignore
import group18 from '@ven/platform/main/assets/images/teams/Mask-group-18.svg';
//@ts-ignore
import group19 from '@ven/platform/main/assets/images/teams/Mask-group-19.svg';
//@ts-ignore
import group20 from '@ven/platform/main/assets/images/teams/Mask-group-20.svg';
//@ts-ignore
import group21 from '@ven/platform/main/assets/images/teams/Mask-group-21.svg';

const logos = [
  group0,
  group1,
  group2,
  group3,
  group4,
  group5,
  group6,
  group7,
  group8,
  group9,
  group10,
  group11,
  group12,
  group13,
  group14,
  group15,
  group16,
  group17,
  group18,
  group19,
  group20,
  group21,
]
export const LogosMarquee:React.FC<{}> = () => {
  const user = React.useContext( UserDataContext );
  const [myName,setMyName] = React.useState( user.username );

  if ( user?.username && myName === undefined ) {
    setMyName( user.username );
  }

  useEffect(()=> {
    if ( user?.username ) {
      setMyName( user.username );
    }
  
  }, [ user ])

  const updateDebounce = debounce(
    name => {
      UserDataService.set("username", name)
      UpdateUsernameAvatar( name, undefined )
      // onChange?.( name )
    }, 
    250
  );
  const update = React.useCallback(updateDebounce, []);

  const handleChange = name => {
    setMyName(name)
    name && update(name) 
  }

  return (
    <Marquee>
      { logos.map((logo, i) => 
        <LogoMarqueeWrapper key={'logo'+i} >
          <img src={logo} style={{padding: "0 10px"}}></img>
        </LogoMarqueeWrapper>
        
      )
      }
    </Marquee>
  )
}

const LogoMarqueeWrapper = styled.div`
margin: 10px;

`
