import React from 'react';

import * as ReactRouter from "react-router-dom";

import { GreenButton } from '../../common/atoms/GreenButton';
import { GameFunctionsService } from '@ven/core/services/GameFunctionsService';


interface Props 
{
    gameName: string,
    nextGameUrl?: string,
    isHost : boolean,
    isEventHost? : string,
    isSpectator? : boolean,
    isRedirecting : boolean,
    setRedirecting : Function,
    updateState: Function,
}

export const PlayAgainButton:React.FC<Props> = ({ gameName, nextGameUrl, isEventHost, isHost, isSpectator, updateState, isRedirecting, setRedirecting }) => 
{
    const history = ReactRouter.useHistory()
    const waitHostMessage = 'Ask the host to start the game again!';
    const RedirectingMessage = 'Redirecting...';
    const playAgainMessage = 'Play Again';
    const goToNextGameMessage = 'Go to next game';

    const buttonText = 
        isRedirecting ? RedirectingMessage : 
        nextGameUrl && isSpectator ? goToNextGameMessage :
        nextGameUrl || isHost ? playAgainMessage :
        waitHostMessage 
    
        if(nextGameUrl && isHost && isSpectator) {
            GameFunctionsService.playAgainGame(gameName, nextGameUrl, isHost, updateState, setRedirecting, isRedirecting, history, isSpectator);
        }
    return (
        isEventHost ? <></> : <GreenButton 
        className="play-again-button"
        style={{whiteSpace: 'normal', marginBottom: 0, padding: '5px'}}
        disabled={ !nextGameUrl && !isHost || !nextGameUrl && isHost && isSpectator } 
        onClick={() => {
        GameFunctionsService.playAgainGame(gameName, nextGameUrl, isHost, updateState, setRedirecting, isRedirecting, history, isSpectator)
    }}>
    {`${ buttonText }`}
    </GreenButton>
    )
}
