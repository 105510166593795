import React, { useEffect } from 'react';

import { UserDataContext } from '@ven/platform/main/services/user/UserDataContext';
import debounce from "lodash.debounce";

import styled from "@emotion/styled";
import { LogService } from '@ven/platform/main/services/log/LogService';
import { HostEventsService } from '@ven/shared/core/services/HostEvents';

export const ScoreInput:React.FC<{updateCallback : Function, currentValue: any}> = (props) => {
  const [info,setInfo] = React.useState( props.currentValue );

//   const infoData = props.data.session?.games[props.data?.index-1]?.info;

//   useEffect(()=> {
//     if ( infoData !== undefined ) {
//       setInfo( infoData );
//     }
//   }, [  ])

  useEffect(()=> {
    setInfo(props.currentValue)
  }, [props.currentValue])


  const updateDebounce = debounce(
    currentValue => {
      Update( currentValue )
    }, 
    500
  );
  const update = React.useCallback(updateDebounce, []);

  const handleChange = currentValue => {
    setInfo(currentValue)
    update(currentValue) 
  }

  const Update = async (info?: string) => {
  
    try {
      await props.updateCallback(info);
      return true;
    } catch (err) {
      await LogService.log(err, 'Error: Could not update info.')
      console.log(err);
      return false;
    };
  };

  return (
    <Input
    type="text"
    className="score-input"
    maxLength={5}
    onBlur={ () => !info }
    onChange={ e => handleChange(e.target.value) }
    onKeyPress={ e => e.key === "Enter" && e.target['blur']() }
    value={ info }
    />
  )
}

const Input = styled.input`
/* margin-top: 10px; */

/* padding-top: 10px; */
/* display: inline-block; */
width: 100%;

transition: none;
/* height: 67px; */
/* border-radius: 7px; */
padding: 0;

border: 1px solid;
height: 35px;
width: 80px;
max-width: 385px;
/* width: 100%; */

font-family: Bitter;
font-style: normal;
font-weight: bold;
font-size: 17px; /* To avoid zooms bugs on IOS, never do an input font-size less than 17px */
line-height: 67px;
text-align: center;

color: #303744;

&:focus {
  outline: none;
}
`

