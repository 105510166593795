import type { GameStateController } from "../../gameplay/GameStateController";
import { LogService } from '@ven/platform/main/services/log/LogService'

export const undo = async ( game:GameStateController ) => {
  try
  {
    const canvas = game?.data?.state?.turn?.canvas || {}
    const entries = Object.entries( canvas ).reverse();
    
    let stops = 0
    for ( const [key,action] of entries ) {
      if ( action[0] === 2 ) {
        if ( stops < 1 ) {
          stops++
        } else {
          break
        }
      }
      delete canvas[key]
    }
    await game.ref.canvas().set( canvas )
  } catch (err) {
    await LogService.log(err, 'Error: Undo Canvas Action.')
    console.warn(err);
  }
}
