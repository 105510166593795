import styled from '@emotion/styled';

export const Separator = styled.div<{size:number}>`
  flex: 0 0 ${ props => props.size }px;
  width: ${ props => props.size }px;
  height: ${ props => props.size }px;
`

export const Spacer = Separator

export const InlineSpacer = styled.div<{size:number}>`
  display: inline-block;
  flex: 0 0 ${ props => props.size }px;
  width: ${ props => props.size }px;
  height: ${ props => props.size }px;
`
