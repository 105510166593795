import React from 'react';

import type { GameStateController } from '@ven/core/gameplay/GameStateController';

//@ts-ignore
import TimerSound from "@ven/shared/components/assets/sounds/Timeisup_7Seconds.mp3"
import { audioService } from '@ven/core/services/SoundService';

export const LastSecondsBuzzer:React.FC<{ game:GameStateController<any> }> = ({ game }) =>
{
  React.useEffect( () => 
  {
    const ref = game.ref.state().child('turn/time')
    const onTick = async ( data:firebase.database.DataSnapshot ) => {
      if ( data.val() == 5 ) {
        audioService.play( TimerSound, .5 );
      }
    }
    ref.on( "value", onTick )
    return () => ref.off( "value", onTick )
  }, [] )

  return null
}
