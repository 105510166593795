import React, { useEffect, useState } from 'react';

import type { GameRoomProps } from '@ven/platform/main/components/RoomCodeRouteWrapper';

import { GameStateController } from './GameStateController';
import { GameStateContext } from './GameStateContext';

import { LoadingView } from '@ven/shared/components/common/organizms/Loading';
import { InactiveTabView } from '@ven/shared/components/common/InactiveTabView';

import { useSynchronizePlayerData } from '@ven/shared/components/hooks/useSynchronizePlayerData';
import { useGameStateAndData } from '@ven/shared/components/hooks/useGameStateAndData';
import { useSingleTabSession } from '@ven/shared/components/hooks/useSingleTabSession';
import { useUserOnlineStatus } from '@ven/shared/components/hooks/useUserOnlineStatus';
import { SwitchViewMode } from '@ven/shared/components/common/SwitchViewMode';

export const GameDataProvider:React.FC<React.PropsWithChildren<GameRoomProps>> = 
({ children, roomCode, gameId, roomId, userId }) => 
{
  const { game, data } = useGameStateAndData( GameStateController, gameId, roomId, userId, roomCode );
  const isWatchUrl = window.location.href.includes('/watch/');
  if(isWatchUrl) {
    game.forceSpectatorMode = true;
  }

  const isActiveTab = useSingleTabSession( game );

  useSynchronizePlayerData( game )

  useUserOnlineStatus( game )

  if ( ! roomId ) {
    return null;
  }

  if ( isActiveTab === false) {
    return <InactiveTabView game={game}/>
  }

  if ( ! data || ! game.myUserId )
  {
    return <LoadingView />
  }

  if (data?.state.started && game.amSpectator() && !isWatchUrl ) {
    return <SwitchViewMode game={game}/>
  }

  game.data = Object.freeze(data!);
  game.code = roomCode || roomId;

  return (
    <GameStateContext.Provider value={{ game, data } } >
      { children }
    </GameStateContext.Provider>
  )
}
