import { database } from '@ven/shared/core/data/firebase';

import type { GameSessionData, PlayerID, RoomID } from '@ven/shared/core/gameplay/GameSessionData';
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';
import { DEVELOPMENT_MODE } from '../debug/consts';
import type { GameID } from '../games';
import { GameFunctionsService } from '../services/GameFunctionsService';

export abstract class GameStateController<TGameSessionData extends GameSessionData>
{
  data!: TGameSessionData;
  code!: string;

  forceSpectatorMode = false;
  //#region -- SHORTHANDS

  protected async loadDataValue<T>( dataPath:string ):Promise<T> {
    const ref = database.ref( `/game-sessions/${ this.gameId }/${ this.roomId! }/${ dataPath }` )
    return (await ref.once( "value" )).val() as T;
  }

  public readonly ref = {
    game : () => database.ref( `/game-sessions/${ this.gameId }` ),
    room : () => this.ref.game().child( this.roomId! ),
    config : () => this.ref.room().child( `config` ),
    host : () => this.ref.room().child( `host` ),
    state : () => this.ref.room().child( `state` ),
    players : () => this.ref.room().child( `players` ),
    player : playerId => this.ref.players().child( playerId ),
    me : () => this.ref.players().child( this.myUserId! ),
    presences : () => this.ref.room().child(`presences`),
    presence : playerId => this.ref.room().child(`presences`).child( playerId ),
    round : () => this.ref.state().child('round'),
    turn : () => this.ref.state().child('turn'),
  }

  public readonly get = {
    room : async () => await this.loadDataValue<TGameSessionData>( '' ),
    state : async () => await this.loadDataValue<TGameSessionData['state']>( 'state' ),
    round : async () => await this.loadDataValue<TGameSessionData['state']['round']>( `state/round` ),
    turn : async () => await this.loadDataValue<TGameSessionData['state']['turn']>( `state/turn` ),
    players : async () => await this.loadDataValue<Record<string,TGameSessionData['players'][0]>>( 'players' ),
    presences : async () => await this.loadDataValue<Record<string,TGameSessionData['presences'][0]>>( 'presences' ),
    myPresence : async () => await this.loadDataValue<TGameSessionData['presences'][0]>( `presences/${ this.myUserId! }` ),
    me : async () => await this.loadDataValue<TGameSessionData['players'][0]>( `players/${ this.myUserId! }` ),
  }

  public readonly update = {
    state : ( data:Partial<TGameSessionData['state']> ) => this.ref.state().update( data ),
    round : ( data:Partial<TGameSessionData['state']['round']> ) => this.ref.state().child('round').update( data ),
    turn : ( data:Partial<TGameSessionData['state']['turn']> ) => this.ref.state().child('turn').update( data ),
    player : ( playerId:PlayerID, data:Partial<TGameSessionData['players'][0]> ) => this.ref.players().child( playerId ).update( data ),
    me : ( data:Partial<TGameSessionData['players'][0]> ) => this.ref.players().child( this.myUserId! ).update( data ),
  }

  public everyPlayer = async ( callback:(props:{id:PlayerID,data:any}) => any ) =>
    Object.entries( await this.get.players() ).every( ([id,data]) => callback({ id, data }) )

  public getMyState = () => this.data.players[ this.myUserId! ] as TGameSessionData['players'][0]

  public getPlayers = () => Object.values( this.data.players ) //.sort( (a,b) => parseInt( a.joined, 36 ) - parseInt( b.joined, 36 ) );
  
  public getPlayersCount = () => Object.values( this.data.players ).length;

  //#endregion

  //#region -- GLOBAL: Core

  constructor ( 
    public gameId:GameID,
    public roomId?:RoomID,
    public myUserId?:PlayerID,
  ) 
  {
    // console.log({ gameId, roomId, myUserId });

    this.initialize();

    DEVELOPMENT_MODE && ( window['game'] = this );
  }

  async initialize() {
    window['game'] = this;
  }

  async destroy() {
  }

  async reset() 
  {
    await this.ref.room().set( 
    {
      players : {},
      state : 
      {
        started : false,
      }
    } )
  }

  async start( initialStateProps : Partial<TGameSessionData['state']> = {}, config:any = {} ) 
  {
    await Promise.all([
       this.ref.state().update({ started : true, ...initialStateProps }),
       this.ref.config().set(config),
    ]);
  }

  public canStartGame = () => true

  public haveJoined = () => !! ( this.myUserId && this.data?.players && this.data.players[ this.myUserId ] !== undefined )

  public amHost = () => !! this.myUserId && this.myUserId == this.data?.host

  public amSpectator = () => this.forceSpectatorMode || !(!!this.myUserId && !!this.data.players[ this.myUserId ])

  //#endregion

  //#region -- GLOBAL: Room Management

  async createRoom()
  {
    // const name = 
    //   new Date().valueOf().toString( 36 ) + 
    //   ( ~~( Math.random() * Number.MAX_SAFE_INTEGER ) ).toString( 36 )
    const name = ( ~~( Math.random() * 1000000 ) ).toString().padStart( 6, '0' )
    await this.ref.game().update({ [ name ] : { state : { started : false } } } )

    return name
  }

  async clearRooms()
  {
    await this.ref.game().set({})
  }

  //#endregion

  //#region -- PLAYER ACTIONS
  
  async join( initialPlayerProps:Partial<TGameSessionData['players'][0]> = {} )
  {
    await GameFunctionsService.joinRoom(this.code, this.myUserId! );
    Promise.all([
      this.ref.me().update(initialPlayerProps),
      ! this.data.host && 
        this.ref.room().update({ host : this.myUserId }),
    ])
  }

  getMaxPlayers():number {
    return this.data.maxPlayers || Number.POSITIVE_INFINITY;
  }

  hasMaxPlayers() {
    const playersCount = Object.keys( this.data?.players || {} ).length;
    return playersCount >= this.getMaxPlayers();
  }

  //#endregion
}

export interface WithTeams {
  getTeamName: (team:any)=>string;
}
