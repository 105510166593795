import React, { useEffect, useMemo } from 'react';

import { UserDataContext } from '@ven/platform/main/services/user/UserDataContext';
import { UpdateUsernameAvatar } from '@ven/platform/main/services/user/UserDataService';
import { UserDataService } from '@ven/platform/main/services/user/UserDataService';
import debounce from "lodash.debounce";

import styled from "@emotion/styled";
import Marquee from 'react-fast-marquee';

//@ts-ignore
import kickstarter from '@ven/platform/main/assets/images/teams/kickstartericonn.png';
//@ts-ignore
import docker from '@ven/platform/main/assets/images/teams/dockericon-1.png';
//@ts-ignore
import amazon from '@ven/platform/main/assets/images/teams/amazoniconn.png';
//@ts-ignore
import crowdiconn from '@ven/platform/main/assets/images/teams/crowdiconn.png';


const _items = [
    {
        desc: 'The games were easy to understand and explained well. Rachel has an electric personality and is exactly the right person to be doing this job. She kept things fun and light and almost felt like an extended member of the team! Bravo!.',
        image: docker,
        title: 'docker'
    },
    {
        desc: "This was a great experience!  Our host was super engaging and did a fantastic job.",
        image: amazon,
        title: 'amazon',
    },
    {
        desc: 'It was super fun and Rachel was a great host. Thanks so much 🙂',
        image: kickstarter,
        title: 'kickstarter'
    },
    {
        desc: 'This was executed perfectly. On schedule and lots of fun. My face hurts from smiling so much. Kudos to @Trevor for making it so much fun for us.',
        image: crowdiconn,
        title: 'crowdiconn'
    }
];

const length = _items.length;
_items.push(..._items);


const CarouselSlideItem = ({ pos, idx, slideWidth, activeIdx }) => {

    const createItem = (position, idx) => {
        const item = {
            styles: {
                transform: `translateX(${position * slideWidth}rem)`,
            },
            player: _items[idx],
        };

        switch (position) {
            case length - 1:
            case length + 1:
                item.styles = { ...item.styles }//, filter: 'grayscale(1)'};
                break;
            case length:
                break;
            default:
                item.styles = { ...item.styles , opacity: 0};
                break;
        }

        return item;
    };

    const item = createItem(pos, idx);

    return (
        <li className="carousel__slide-item" style={item.styles} key={idx}>
            <div className="carousel-slide-item__body">
                <p>{item.player.desc}</p>
            </div>
            <div className="carousel__slide-item-img-link">
                <img width="42" height="42" src={item.player.image} alt={item.player.title} />
            </div>
        </li>
    );
};


export const TestimonalCarrosel: React.FC<{}> = () => {

    const slideWidth = 32;

    const sleep = (ms = 0) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    };

    const keys = Array.from(Array(_items.length).keys());

    const [items, setItems] = React.useState(keys);
    const [isTicking, setIsTicking] = React.useState(false);
    const [activeIdx, setActiveIdx] = React.useState(0);
    const bigLength = items.length;

    const prevClick = (jump = 1) => {
        if (!isTicking) {
            setIsTicking(true);
            setItems((prev) => {
                return prev.map((_, i) => prev[(i + jump) % bigLength]);
            });
        }
    };

    const nextClick = (jump = 1) => {
        if (!isTicking) {
            setIsTicking(true);
            setItems((prev) => {
                return prev.map(
                    (_, i) => prev[(i - jump + bigLength) % bigLength],
                );
            });
        }
    };

    const handleDotClick = (idx) => {
        if (idx < activeIdx) prevClick(activeIdx - idx);
        if (idx > activeIdx) nextClick(idx - activeIdx);
    };

    React.useEffect(() => {
        if (isTicking) sleep(300).then(() => setIsTicking(false));
    }, [isTicking]);

    React.useEffect(() => {
        setActiveIdx((length - (items[0] % length)) % length) // prettier-ignore
    }, [items]);

    return (
        <CarouselWrap count={length} width={slideWidth}>
            <div className="carousel__wrap">
                <div className="carousel__inner">
                    <button className="carousel__btn carousel__btn--prev" onClick={() => prevClick()}>
                        <i className="carousel__btn-arrow carousel__btn-arrow--left" />
                    </button>
                    <div className="carousel__container">

                        <ul className="carousel__slide-list">
                            {items.map((pos, i) => (
                                <CarouselSlideItem
                                    key={i}
                                    idx={i}
                                    pos={pos}
                                    activeIdx={activeIdx}
                                    slideWidth={slideWidth}
                                />
                            ))}
                        </ul>
   
                    </div>
                    <button className="carousel__btn carousel__btn--next" onClick={() => nextClick()}>
                        <i className="carousel__btn-arrow carousel__btn-arrow--right" />
                    </button>
                    <div className="carousel__dots">
                        {items.slice(0, length).map((pos, i) => (
                            <button
                                key={i}
                                onClick={() => handleDotClick(i)}
                                className={i === activeIdx ? 'dot active' : 'dot'}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </CarouselWrap>
    );
};

const CarouselWrap = styled.div<{count, width}>`
/* https://codepen.io/ryasan86/pen/QXwEbM */

--slide-count: ${props => props.count};
--slide-width: ${props => props.width}rem;

ul {
  list-style-type: none;
  padding: 0;
}

li{
    transition: all 0.3s;
}

a {
  text-decoration: none;
  color: inherit;
}

.carousel__wrap {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
}

.carousel__inner {
  height: 40rem;
  position: relative;
  /* width: calc(90rem); */
  width: calc(var(--slide-width) * 3);
  transition: all 0.3s;
}

.carousel__container {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel__slide-list {
  height: 100%;
  left: 50%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translateX(-50%);
  /* width: calc(270rem); */
  /* width: calc(#{($slide-count + 0.5) * $slide-width * 2}); */
  width: calc(calc(var(--slide-count) + 0.5) * calc(var(--slide-width) * 2));
}

.carousel__slide-item {
  /* display: inline-block; */
  /* height: 30rem; */
  background-color: rgb(255, 255, 255);
  border-bottom-color: rgba(0, 0, 0, 0);
  border-bottom-style: solid;
  border-bottom-width: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 10px 0px;
  box-sizing: border-box;
  color: rgb(64, 64, 64);
  
  border-radius: 10px;
  margin: 0 1rem;
  padding: 1rem;
  position: absolute;
  transition: all 0.3s;
  
  /* width: 28rem; */
  height: var(--slide-width);
  width: ${props => props.width - 2}rem;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;

}

.carousel__slide-item-img-link {
  /* display: flex; */
  text-justify: center
  /* height: 100%; */
  /* overflow: hidden; */
  /* position: relative; */
  /* width: 100%; */
} 
.carousel__slide-item-img-link img {
  /* height: 100%; */
  /* object-fit: cover; */
  transition: all 0.5s ease;
  /* width: 100%; */
}

.carousel-slide-item__body {
  /* bottom: -2.5rem; */
  height: 10%;

  font: 2.5rem Oswald;
  font-size: 18px;
  font-weight: 900;
  /* height: 393px; */
  hyphens: manual;
  line-height: 26px;
  padding: 30px; 

  /* text-align center;
  text-size-adjust 100%;
  */
  transition-behavior normal;
  transition-delay 0s;
  transition-duration 0.4s;
  transition-property all;
  transition-timing-function ease; 
}
/* .carousel-slide-item__body h4 { */
  /* margin: 0.7rem 0 0; */
  /* text-transform: uppercase; */
/* } */
/* .carousel-slide-item__body p { */
  /* font-size: 1.2rem; */
  /* line-height: 1.3; */
  /* margin: 0.7rem 0 0; */
/* } */

.carousel__btn {
  align-items: center;
  background: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.carousel__btn--prev {
  left: -10rem;
}
.carousel__btn--next {
  right: -10rem;
}

.carousel__btn-arrow {
  border: solid #f47c63;
  border-width: 0 0.4rem 0.4rem 0;
  height: 6rem;
  padding: 3px;
  width: 6rem;
  z-index: 10;
}
.carousel__btn-arrow--left {
  transform: rotate(135deg);
}
.carousel__btn-arrow--right {
  transform: rotate(-45deg);
}

.carousel__dots {
  display: inline-block;
  left: 50%;
  margin-top: 2rem;
  position: absolute;
  transform: translateX(-50%);
}
.carousel__dots .dot {
  background: #6e5afd;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 2rem;
  margin: 0 0.3rem;
  outline: none;
  transform: scale(0.5);
  width: 2rem;
}
.carousel__dots .dot.active {
  background: #f47c63;
}

`
