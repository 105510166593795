import React from 'react';

import { GameStateContext } from '../../gameplay/GameStateContext';

import styled from '@emotion/styled';

export const EmptyCanvasFilling:React.FC<any> = () =>
{
  const { data } = React.useContext( GameStateContext )

  const roundLabelText = !! data?.state.round.number ? `Round: ${ data.state.round.number } / ${ data.config.rounds }` : `...`

  const turnPlayerId = data?.state?.turn?.player || ''
  const turnPlayerName = data?.players?.[ turnPlayerId ]?.username

  return (
    <Container>
      <div className="rounds">
        { roundLabelText } 
      </div>
      <div className="greeting">
        <p>Guess what { turnPlayerName }</p>
        <p> is drawing! </p>
      </div>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .greeting {
    width: 80%;
    margin: 0;
    padding: 0;

    font-family: Bitter;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 56px;
    /* or 140% */
  
    text-align: center;
  
    color: #A19AC6;
  }
  .rounds {
    font-family: "Bitter";
    font-weight: 900;
    font-size: 15px;
    color: #A19AC6;
    margin-bottom: 20px;
  }
`













