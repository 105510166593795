import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from '@emotion/styled';

type Props = {
	name: string,
	link?: string,
	onClick?: Function
	options: Array<
		{ name: string, link?: string, onClick?: Function }
	>
}
export const SimpleCombobox: React.FC<Props> = ({ name, link, onClick, options }) => {

	return (
		<Wrapper>
			<ul className={`combo-options combo-options-${name}`}>
				<li className={`combo-option-header combo-option-header-${name} ${name.length > 15 ? 'big-name' : ''}`}> 
				<a className={`${name.length > 12 ? 'big-name' : ''}`} href={link ? link : '#'} onClick={() => onClick ? onClick() : () => { }}>{name}</a>
					<ul>
						{options.map(item =>
							<li key={item.name} className={`combo-option-item combo-option-item-${item.name}`}>
								<a href={item.link ? item.link : '#'} onClick={() => item.onClick ? item.onClick() : () => { }}>{item.name}</a>
							</li>
						)}
					</ul>
				</li>
			</ul>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	text-align: center;
	* {
		transition: all .2s ease-out 0s;
		-o-transition: all .2s ease-out 0s;
		-ms-transition: all .2s ease-out 0s;
		-moz-transition: all .2s ease-out 0s;
		-webkit-transition: all .2s ease-out 0s;
	}
	ul {
    border-radius: 0.8rem;
    background: #FFFFFF;
   
		li {
			display: inline-block;
			position: relative;
		}
	}
	>ul {
		>li {
			>a {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 5px 10px;
				text-decoration: none;
				text-transform: capitalize;
				font: 400 1.8rem Oswald;
				color: #303744;
				width: 15rem;
				height: 4.0rem;
				margin-left: 2.0rem;
				list-style: none;
				margin: 0;
				padding: 0;
				&.big-name {
					font-size: 14px;
					padding: 8px;
				}
			}
		}
	}
	li {
		>ul {
			padding: 5px 0;
			background: #555;
			position: absolute;
			visibility: hidden;
			opacity: 0;
			display: block;
			top: 100%;
			margin-top: 30px;
			border-radius: 5px;
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			-o-border-radius: 5px;
			-ms-border-radius: 5px;
		}
		&:hover {
			>ul {
				visibility: visible;
				opacity: 1;
				margin-top: 0;
			}
		}
		li {
			display: block;
			a {
				color: #FFF;
        text-decoration: none;
				text-transform: uppercase;
        font: 400 1.8rem Oswald;
        width: 15rem;
        display: flex;
        justify-content: center;
        align-items: center;
				padding: 7px 15px;
				&:hover {
					background: #333;
				}
			}
		}
}
`
