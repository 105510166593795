/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import styled from '@emotion/styled';
import { PORTRAIT_BREAK_PX } from '@ven/shared/components/styling/breakpoints';
import { COLOR_GRAY } from "@ven/shared/components/styling/colors";

import React, { useEffect, useMemo } from 'react';

import { DrawingCanvas } from '@ven/games/scribbler/components/organisms/DrawingCanvas';
import { DrawingToolbox } from './DrawingToolbox';

import { GameStateContext } from '@ven/games/scribbler/gameplay/GameStateContext';
import type { PlayerID } from '@ven/shared/core/gameplay/GameSessionData';

import { configuration } from '@ven/games/scribbler/drawing/DrawingSettings';

import { DrawingSettingsProvider } from '@ven/games/scribbler/drawing/DrawingSettings';
import { DrawingPalette } from '@ven/games/scribbler/components/organisms/DrawingPalette';
import { OverlayDialog_ChooseWord } from '../../organisms/OverlayDialog_ChooseWord';
import { ChatPanelMobile } from '../../organisms/ChatPanelMobile';
import { WordOrHint } from "../../atoms/WordOrHint";
import { OverlayDialog_Wait } from "../../organisms/OverlayDialog_Wait";
import { TimerCorner } from "../../organisms/TimerCorner";
import { OverlayDialog_GameOver } from "../../organisms/OverlayDialog_GameOver";
import { EmptyCanvasFilling } from "../../organisms/EmptyCanvasFilling";
import { OverlayDialog_PreTurn } from "../../organisms/OverlayDialog_PreTurn";
import { DEVELOPMENT_MODE } from "@ven/core/debug/consts";
import { WordOverCanvas } from "../../molecules/WordOverCanvas";
import { audioService } from "@ven/shared/core/services/SoundService";
//@ts-ignore
import CorrectSound from "@ven/shared/components/assets/sounds/correct.mp3"
import _ from "lodash";
interface Props {
  myUserId: PlayerID,
}
export const MobileInGameContent: React.FC<Props> = ({ myUserId }) => {
  const { game, data } = React.useContext(GameStateContext)

  const joined = (data?.players || {})[myUserId] !== undefined
  const myTurn = data?.state.turn.player === myUserId && !game.amSpectator();
  const currentPlayer = data?.state.turn.player

  const watchOnly = !joined || !myTurn || game.amSpectator()

  const isDrawingStage = !!data?.state?.turn.word
  const canChat = isDrawingStage && !myTurn && !game.amSpectator()

  const showPreTurnModal =
    (data?.state?.turn?.number || 0) > 1 &&
    (!data?.state.turn?.started || !myTurn)

  const canvasScaleFactor = window.innerWidth >= configuration.canvas.width
    ? 1 : window.innerWidth / configuration.canvas.width
   
    const messages = data!.state.turn?.chat
    const [chatMessagesCount, setChatMessagesCount] = React.useState(() => messages ? Object.keys(messages).length : 0)
    if (messages && Object.keys(messages).length > chatMessagesCount) {
      setChatMessagesCount(messages ? Object.keys(messages).length : 0)
    }
    useEffect(() => {
      if (!messages)
        return;
  
      const lastMessage = _.last(Object.keys(messages));
      if (lastMessage && messages[lastMessage].correct)
        audioService.play(CorrectSound, .5);
    }, [chatMessagesCount])
  
  return (
    <Wrapper
      className="game-content-wrapper"
      canvasScaleFactor={canvasScaleFactor} offsetY={myTurn ? 155 : 0}>
      <div className="game-content">
        <div className="center-bit">
          <DrawingSettingsProvider>
            {
              React.useMemo(() => (
                <Children>
                  {
                    myTurn &&
                    <DrawingPalette colors={configuration.tools.brushColors} />
                  }
                  <DrawingCanvas
                    {...{
                      game,
                      persistent: {} as any,
                      config: configuration.canvas,
                      scaleFactor: canvasScaleFactor,
                      watchOnly,
                    }}>
                    {
                      isDrawingStage && 
                      <WordOverCanvas myUserId={myUserId} />
                    }
                    {
                      isDrawingStage &&
                      <TimerCorner />
                    }
                  </DrawingCanvas>
                </Children>
              ), [currentPlayer, isDrawingStage, canvasScaleFactor])
            }
            <div className="after-canvas">
              {
                myTurn &&
                !data?.state?.over &&
                <DrawingToolbox {...{
                  game,
                  optionChoices: configuration.tools,
                  canvas: configuration.canvas,
                  canvasScaleFactor: canvasScaleFactor,
                }} />
              }
              {
                !data?.state?.over &&
                // ( canChat || !! data.state.turn?.chat?.length ) &&
                <div className="mobile-chat-container">
                  <ChatPanelMobile
                    history={data?.state?.turn?.chat || {}}
                    handleSend={!canChat ? undefined : m => game.guessWord(m)} />
                </div>
              }
            </div>
            {
              !data?.state?.turn?.started &&
              !data?.state?.turn?.canvas &&
              !myTurn &&
              <EmptyCanvasFilling />
            }
            {
              React.useMemo(() =>
                !!data?.state?.over &&
                <OverlayDialog_GameOver game={game} key="gameover" />,
                [!!data?.state?.over]
              )
            }
            {
              !data?.state?.over &&
              !isDrawingStage &&
              (
                showPreTurnModal
                  ?
                  <OverlayDialog_PreTurn game={game} />
                  :
                  myTurn
                    ?
                    <OverlayDialog_ChooseWord game={game} />
                    :
                    <OverlayDialog_Wait />
              )
            }
          </DrawingSettingsProvider>
        </div>

      </div>

    </Wrapper>
  )
}

const Children: React.FC<any> = ({ children }) => {
  return children || null;
}

const Wrapper = styled.div<any>`
  padding: 0px;
  width: ${configuration.canvas.width}px;
  width: 100%;
  height: 898px;
  overflow: hidden;

  position: relative;
  
  .game-content {
  }

  .center-bit {
    position: relative;
    width: ${configuration.canvas.width}px;
    width: 100%;
  }

  .after-canvas {
    position: absolute;
    box-sizing: border-box;
    top: ${configuration.canvas.height * 414 / configuration.canvas.width}px;
    width: 100%;
    
    border: ${DEVELOPMENT_MODE ? `black dashed 2px` : `none`};
  }

  .mobile-chat-container {
    min-height: 130px;
    max-width: 100%;
    width: 100%;

    display: block;
    position: fixed;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .drawing-canvas {
    position: absolute;
    top: 0px;
    left: 0px;
    transform: scale(${414 / configuration.canvas.width}) !important;
    transform-origin: top left;
    /* zoom: 1 !important; */
  }
`
