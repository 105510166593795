import React from 'react';

import { MainHeader } from '@ven/platform/main/components/organisms/MainHeader';
import { InGameContent } from '../templates/InGameContent';
import { PreGameContent } from '../templates/pregame/PreGameContent';
import { MainContent } from '@ven/platform/main/components/organisms/MainContent';
import { DEVELOPMENT_MODE } from '@ven/core/debug/consts';

import { GameStateContext } from '../../gameplay/GameStateContext';

import { MOBILE_BREAK_PX } from '@ven/shared/components/styling/breakpoints';

import styled from '@emotion/styled';

//@ts-ignore
import MobileBackground from "@ven/shared/components/assets/wordplay/outlaw_background-details-mobile.svg";
//@ts-ignore
import DesktopBackground from "@ven/shared/components/assets/wordplay/outlaw_background-details.svg";
//@ts-ignore
import HolidayBackground from "@ven/shared/components/assets/wordplay/bg-holiday.png";

import "../style.scss"
import { useDebugKeys } from '@ven/core/debug/useDebugKeys';
import { UserDataService } from '@ven/platform/main/services/user/UserDataService';

export const PlayerViewPage:React.FC = () => 
{
  const { data, game } = React.useContext( GameStateContext )
  
  const started = data?.state.started && data?.state.turn

  const gameId = 'outlaw'

  if ( ! data )
  {
    return null
  }
  
  const isHoliday = UserDataService.get('holiday') ? 'holiday' : '';

  return (
    <Wrapper>
      <MainContent className={`${game.gameId} red ${( started ? 'ingame' : 'pregame' )} ${isHoliday}`}>
        {
          ! started
          ? <PreGameContent/>
          : <InGameContent/>
        }
      </MainContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: rgb(3,4,115);
  background-image: url(${ DesktopBackground }), 
    linear-gradient(90deg, var(--outlaw-primary) 0%, var(--outlaw-secundary) 100%);

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  
  width: 100vw;
  /* height: 100vh; */

  transition: background-image 200ms;

  position: relative;
  overflow: hidden;
  /* overflow: visible; */

  /* position: fixed; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media (max-width: ${ MOBILE_BREAK_PX }px) {
    background-image: url(${ MobileBackground });
  }

  .main-content.holiday {
    background-image: url(${ HolidayBackground }); 
    background-position: top;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
  }

  p, h1, h2 {
    margin: 0;
    padding: 0;
  }

`
