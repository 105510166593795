import React, { useState } from 'react';

import { GameStateContext } from '../../../gameplay/GameStateContext';

import { Spacer } from '@ven/shared/components/common/atoms/Separator';
import { PadContent_ShareGame, UsernameAndAvatarForm } from '@ven/shared/components/common/pregame/common';
import { CustomGamePad, CustomGamePlayButton, PlayersList_Basic, UpsellBit } from '@ven/shared/components/common/pregame/CustomGame';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import styled from '@emotion/styled';
import { MOBILE_BREAK_PX } from '@ven/shared/components/styling/breakpoints';
import 'react-super-select/lib/react-super-select.css';
import { PackDeckDropdown } from '@ven/shared/components/common/atoms/PackDeckDropdown';
import { useHistory } from 'react-router-dom';
import { UserDataService } from '@ven/platform/main/services/user/UserDataService';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';
import ListGroup from 'react-bootstrap/esm/ListGroup';
import { Team } from '@ven/games/wordplay/gameplay/GameStateController';
import { HostPowers } from '@ven/shared/components/game/molecules/HostPowers';


export const CustomGameConfigurationContent: React.FC<any> = ({ openHowToPlay }) => {
  const { game } = React.useContext(GameStateContext)

  const history = useHistory();
  const myUserId = game.myUserId!
  const players = Object.keys(game.data.players || {})

  const [pack, setPack] = React.useState(Object);

  const MINIMUM_PLAYERS_COUNT = 2
  const missingPlayersCount = Math.max(1, MINIMUM_PLAYERS_COUNT - players.length)

  if (!localStorage.configScribbler)
    localStorage.configScribbler = JSON.stringify(game.getConfig());

  const config = JSON.parse(localStorage.configScribbler);

  const isAnon = UserDataService.get('isAnon') == 'true';
  
  const [showHostPowers, setShowHostPowers] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(Object);
 
  const handleShow = (userdata) => {
    setSelectedPlayer(userdata)
    setShowHostPowers(true);
  }

  return (<>
    <HostPowers game={game} selectedPlayer={selectedPlayer} show={showHostPowers} setShow={setShowHostPowers}></HostPowers>
    <CustomGamePad headerColor="#303744"> 
      <div className="title">
        <div className="game-title">
          Scribbler
        </div>
        <div className="how-to" onClick={openHowToPlay}>
          How to play
        </div>
      </div>

    {
      !game.data?.eventHost && <div className="inner-content">
        <UsernameAndAvatarForm spacing={28} theme="black"/>
      </div>
    }

    </CustomGamePad>

    <Spacer size={28} />

    <CustomGamePad headerColor="#303744" overflowHidden={false} >
      <div className="title title-teams">
        <div className="column">
          <div className="bigHeading">Configuration</div>
        </div>
      </div>

      <OptionGrid className="inner-content">
        <span className="label packs-label">Packs</span>
        <PackDeckDropdown
          gameId="scribbler" userId={myUserId}
          initialPackId={config.pack}
          onChange={async option => {
            if (!option) {
              return;
            }

            setPack(option);
            config.pack = option.id;
            localStorage.configScribbler = JSON.stringify(config);
          }} />

        {pack.price && !isAnon ? <a onClick={() => {
          const urlBase = window.location.origin.toString()
          const link = `${urlBase}/pack-init?name=${pack.name}&id=${pack.id}&price=${pack.price}`;
          window.open(link, '_blank')
        }} className="packs-information">Click here to buy the pack</a> : null}

        {pack.price && isAnon ? <a onClick={() => {
          (document.querySelector('button.login')! as any).click() //dirty hack
        }
        } className="packs-information pack-login">Click here to login/signup to buy the pack</a> : null}

        <span className="label rounds-label">Rounds</span>
        <Dropdown
          className="rounds-input"
          options={['1', '2', '3', '4']}
          onChange={v => {
            config.rounds = +v.value;
            localStorage.configScribbler = JSON.stringify(config);
          }}
          value={config.rounds.toString()}
        />
        <span className="label time-label">Draw Time (seconds)</span>
        <Dropdown
          className="time-input"
          options={['30', '45', '60', '75']}
          onChange={v => {
            config.turnTime = +v.value;
            localStorage.configScribbler = JSON.stringify(config);
          }}
          value={config.turnTime.toString()}
        />
        <span className="label word-time-label">Pick Word Time (seconds)</span>
        <Dropdown
          className="word-time-input"
          options={['120', '60', '45', '30', '15']}
          onChange={v => {
            config.wordTime = +v.value;
            localStorage.configScribbler = JSON.stringify(config);
          }}
          value={config.wordTime ? config.wordTime.toString() :  game.getConfig().wordTime.toString()}
        />
      </OptionGrid>
    </CustomGamePad>

    <Spacer size={28} />

    <CustomGamePad headerColor="#303744">
      <div className="title title-teams">
        <div className="column">
          <div className="bigHeading">Players</div>
        </div>
      </div>
      <p style={{padding: '5px'}}>To manage players, click in their avatar</p>
      <PlayersList_Basic game={ game }
        cursorPointer={(playerData) => playerData.uid != myUserId}
        onClickPlayer={(playerData) => { if(playerData.uid != myUserId) handleShow(playerData) }} />
      {
        !game.canStartGame() && <>
          <Spacer size={28} />
          <div className="hint"> Waiting for at least {missingPlayersCount} more player{missingPlayersCount > 1 && 's'}. </div>
          <Spacer size={28} />
        </>
      }
      <UpsellBit game={game} />
    </CustomGamePad>

    <Spacer size={28} />

    <CustomGamePad headerColor="#303744">
      <PadContent_ShareGame refererId={myUserId} game={game} />
    </CustomGamePad>

    <Spacer size={28} />

    <CustomGamePlayButton game={game} config={config} otherError={pack.price ? "Buy the pack or select other" : null} />
  </>)
}

export const OptionGrid = styled.span`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 5fr;
  gap: 10px 10px;
  grid-template-areas:
    "packs-label packs-label"
    "packs-input packs-input"
    "packs-information packs-information"
    "rounds-label time-label"
    "rounds-input time-input"
    "word-time-label ."
    "word-time-input .";

    /* grid-template-columns: 0.5fr 2fr  0.5fr;
    grid-template-rows: auto 66px;
    grid-template-areas:
    "rounds-label packs-label time-label"
    "rounds-input packs-input time-input"; */

    @media (max-width: ${MOBILE_BREAK_PX}px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    gap: 10px 10px;
    grid-template-areas:
      "packs-label"
      "packs-input"
      "packs-information"
      "rounds-label"
      "rounds-input" 
      "time-label"
      "time-input"
      "word-time-label"
      "word-time-input";
  }
  
  .packs-label { grid-area: packs-label; }
  .packs-dropdown { grid-area: packs-input; }
  .packs-information { grid-area: packs-information; cursor: pointer; }
  
  .rounds-label { grid-area: rounds-label; }
  .time-label { grid-area: time-label; }
  .word-time-label { grid-area: word-time-label; }
  .rounds-input { grid-area: rounds-input; }
  .time-input { grid-area: time-input; }
  .word-time-input { grid-area: word-time-input; }
`