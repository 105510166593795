import React from 'react';

import { HowToPlay } from '@ven/shared/components/common/templates/HowToPlay';

//@ts-ignore
import HowToImage from "@ven/components/assets/brokenmsg/how-to.jpg";

const howToData = {
  title : "How to Play BrokenMsg",
  image : HowToImage,
  text : [
    {
      heading : "OBJECTIVE",
      content : 
`Start by writing down a word or phrase. 
The next player will have to draw what you wrote down. 
The player after them will have to guess what was drawn. 
The next player draws what was written down. The next guesses. Etc.

This process repeats until everyone’s original word has made it around. 

There’s no winning in this game… it’s all about the laughs! At the end of the game, have a few laughs when you see how much of a broken message there was for each original word/phrase!
`
    },
  ]
}

export const HowToPlay_BrokenMsg:React.FC<any> = ({ ...props }) =>
  <HowToPlay { ...props } howToData={ howToData } />

