import React, { useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import { HostEventsService } from '@ven/shared/core/services/HostEvents';
import CreateSession from './CreateSession';
import styled from '@emotion/styled';
import SessionInfo from './SessionInfo';

interface EventInfoProps {
  data?: any;
}

const EventInfo: React.FC<EventInfoProps> = (props) => {

  async function deleteHostEvent(event) {
    const session = await HostEventsService.deleteHostEvent(event.id);
    window.history.pushState({}, '', '/eventHost/createEvent');
    console.log(session)
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Wrapper>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>You are about to delete {props.data.name} event. 
        This, obviously, can break games from this event, Are you sure you want to delete?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={() => {
            deleteHostEvent(props.data);
            handleClose();
          }}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Card>
        <Card.Header>
          {props.data.name}

          <Button variant="danger" onClick={handleShow}>
            Delete
          </Button>

        </Card.Header>
        <Card.Body>
          <CreateSession data={props.data}></CreateSession>
          <SessionInfo data={props.data}></SessionInfo>
        </Card.Body>
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled.div`
.nav-item {
  outline: 1px solid rgba(0,0,0,0.1);
  background: #fff;
}
button.btn.btn-danger{
  float: right;
}
&>.card {
  background-color: transparent;
  border: 0;
}
`

export default EventInfo;
