import React from 'react';

type State = {
  width: number
  height: number
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState<State>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  React.useEffect(() => {
    let dimensions = { ...windowSize };
    let animationFrame = -1;
    function handleResize() {
      if ( dimensions.width !== window.innerWidth || dimensions.height !== window.innerHeight ) {
        if ( ! document.activeElement || document.activeElement['type'] != "text" || ! document.hasFocus() ) {
          dimensions.width = window.innerWidth;
          dimensions.height = window.innerHeight;
          setWindowSize({ ...dimensions });
        } 
      }
      animationFrame = requestAnimationFrame( handleResize )
    }
    handleResize();
    return () => cancelAnimationFrame(animationFrame)
  }, []);
  return windowSize;
}
