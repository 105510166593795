import React from 'react';

import { GameStateContext } from '../../gameplay/GameStateContext';

import { Spacer } from '@ven/shared/components/common/atoms/Separator';

import styled from '@emotion/styled';

// @ts-ignore
import { GreenButton } from '@ven/shared/components/common/atoms/GreenButton';
import Notepad from '../notepad/Notepad';
import { TimerCircleProgress } from '@ven/shared/components/game/molecules/TimerCircleProgress';
import { delay } from '@ven/shared/core/utils/promise';

const SHOW_TIMER_WHEN_UNDER_SECONDS = 30;

export const FirstSentenceView:React.FC<any> = () => 
{
  const { game, data } = React.useContext( GameStateContext )

  const [ phrase, setPhrase ] = React.useState( game.getMyState().firstSentence )

  const [ sending, setSending ] = React.useState( false )

  const timeLeft = game.data?.state.turn?.time

  const getPredefinedPhrase = () => `${ game.getMyState()?.username }'s random predefined phrase...`

  React.useEffect( () => {
    if ( timeLeft! <= 0 && ! game.getMyState()?.submittedForThisTurn ) {
      game.submit( phrase || getPredefinedPhrase() )
    }
  }, [timeLeft] )

  const myPlayerId = game.myUserId!
  const myPlayerState = game.data?.players?.[myPlayerId]

  let submited = myPlayerState.submittedForThisTurn;

  const submit = () => {
    game.submit( phrase )
    setSending( true )
    delay( 2.0 ).then( () => setSending( false ) )
  }

  const tip = () => {
    var text = !submited 
    ? "Write your word or phrase here. Make sure it’s not too hard or easy to draw and watch the fun!"
    : "Nice. Let’s see how this changes throughout the game!"
    
    return (
      <div className="top-tip">
        { text }
      </div>
      )
  }

  const timer = () =>
    <div className="top-timer">
      <TimerCircleProgress time={ game?.data?.state?.turn?.time ?? '?' } />
    </div>

  const submitButton = () =>
    ( ! phrase || submited ) ? null :
    <GreenButton onClick={ submit } disabled={ sending }>
      Lock it in!
    </GreenButton>

  const waitingWarn = () => 
    !submited ? null :
    <div className="waiting-warning">
      Wait for the remaining players to write...
    </div>

  return ! data ? null : (
    <Wrapper>
      <Spacer size={ 30 } />
      { 
        game?.data?.state?.turn?.time! <= SHOW_TIMER_WHEN_UNDER_SECONDS
        ? timer()
        : tip() 
      }
      
      <Spacer size={ 30 } />

      <Notepad 
        avatar={ myPlayerState.avatar } 
        cover={ true } 
        body={ !submited ? setPhrase : phrase }
        placeholder="Write Here"         
      ></Notepad>

      <Spacer size={ 35 } />
      { submitButton() }
      { waitingWarn() }
      
    </Wrapper>

  )
}

const Wrapper = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;


.Notepad .body.input {
  height: 100%;
}

.top-tip {
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  height: 80px;
  width: 373px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.top-timer {
  position: relative;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.waiting-warning {
  font-family: Oswald;
  font-style: normal;
  font-size: 2rem;
  line-height: 30px;
  color: var(--notepad-cover);
  text-align: center;
  display: flex;
  margin-bottom: 2rem;
  position: absolute;
  bottom: 0;
}

`
