import type { PlayerID } from '@ven/shared/core/gameplay/GameSessionData';
import type { ChatMessage, GameStateController } from './GameStateController';
import { TimerPhase } from './GameStateController';
import { GamePseudoServer as GamePseudoServerBase, GameServerAction } from '@ven/shared/core/gameplay/GamePseudoServer';

import { delay } from '@ven/shared/core/utils/promise';
import { analyticsService } from '@ven/platform/main/services/AnalyticsService';

type Action = GameServerAction & (
  | {
    type : "guess",
    phrase: string,
    forTurnNumber: number
  }
)

const revealLetters = ( hint:string, whole:string, revealCount:number ) =>
{
  const hiddenIndices = [ ...hint ].map( (c,i) => c === '_' ? i : null ).filter( i => i !== null ) as number[]

  if ( hiddenIndices.length < revealCount )
  {
    revealCount = hiddenIndices.length
  }

  const revealIndices = [] as number[]
  for ( let i = 0 ; i < revealCount ; i++ )
  {
    const index = ~~( Math.random() * hiddenIndices.length )
    revealIndices.push( hiddenIndices.splice( index, 1 )[ 0 ] )
  }

  const replaceAt = function( string, index, replacement) {
    return string.substr(0, index) + replacement + string.substr(index + replacement.length);
  }

  for ( const i of revealIndices )
  {
    hint = replaceAt( hint, i, whole[i] )
  }

  return hint
}

/**
 * The intention is for this class to eventually become the de-facto server entity, 
 * which will handle all the common gameplay logic and only take requests from other
 * players in the form of 'actions', then resolve them in the correct sequence.
 */
export class GamePseudoServer extends GamePseudoServerBase<Action,GameStateController,GameStateController['data']>
{
  public async initialize() {
    await super.initialize();

    const state = await this.get.state();
    const myUserId = this.game.myUserId
    const myTurn = state?.turn?.player && myUserId && ( state?.turn?.player === myUserId ) && !this.game.amSpectator()
    const turnStarted = !! state?.turn?.started
    const turnOver = !! state?.turn?.over
    const gameOver = state?.over
    if ( myTurn && turnStarted && turnOver && ! gameOver ) {
      this.endTurn( state.turn.number );
    }
  }

  protected async tick() {
    if ( this.data?.state?.over ) return;
    if ( !this.data?.state?.started ) return;
    // if ( !this.data?.state?.turn?.started ) return;
    if ( !this.game.amHost() ) return;
    if ( this.game.amHost() && this.game.amSpectator() ) return;

    if ( this.data?.state?.turn?.over ) return;
    if ( this.data?.state?.over ) return;
    
    const turn = this.data.state.turn;
    const time = ( turn?.time ?? 0 ) - 1 // ?? because 0 is falsy too
   
    if ( time == undefined ) {
      return;
    }

    if(!this.data?.state?.turn?.word) {
      const currentPlayerOnline = this.data?.presences?.[ this.data.state.turn.player! ]?.online;
      const timeout = ( turn?.timeout ?? 0 ) - (currentPlayerOnline ? 1 : 5);
      if(timeout > 0){
        await this.update.turn({ timeout })
        return;
      }

      console.log("skipped");
      await this.endTurn( turn.number, true );
      return;
    }

    if ( time < 0 ) 
    {
      await this.update.turn({ wordHint : turn.word!, over: true })
      await this.endTurn( turn.number )
      return
    }
    //// Reveal Letters
    let timePhase = turn.timePhase
    let wordHint = turn.wordHint!
    const timeTotal = this.game.getConfig()?.turnTime || 1
    const timePercentLeft = 100 * ( time / timeTotal )

    if ( timePhase == TimerPhase.PercentLeftLessThan100 )
    {
      if ( timePercentLeft < 40 )
      {
        timePhase = TimerPhase.PercentLeftLessThan40

        if ( turn.word!.length > 4 )
        {
          const revealCount = 1 + ~~( turn.word!.length * .10 )
          wordHint = revealLetters( turn.wordHint!, turn.word!, revealCount )
        }
      }
    }
    else
    if ( timePhase == TimerPhase.PercentLeftLessThan40 )
    {
      if ( timePercentLeft < 20 )
      {
        timePhase = TimerPhase.PercentLeftLessThan20

        if ( turn.word!.length > 1 )
        {
          const revealCount = 1 + ~~( turn.word!.length * .10 )
          wordHint = revealLetters( turn.wordHint!, turn.word!, revealCount )
        }
      }
    }

    await this.update.turn({ time, timePhase, wordHint })
  }

  private async endTurn( turnNumber:number, skipped = false )
  {
    if ( this.data?.state?.turn?.number != turnNumber ) {
      console.error( `Tried to end turn #${ turnNumber } but current turn is #${ turnNumber }` );
      return;
    }

    const config = this.game.getConfig();

    let roundNumber = this.data.state?.round?.number ?? 0
    let playersWaitingForTurn = this.data.state?.round?.playersWaitingForTurn ?? []

    // await
    this.ref.state().child('prevTurnResults').update({ 
      timeLeft : this.data.state?.turn?.time || '',
      player: this.data.state.turn.player,
      skipped
    })

    if ( roundNumber < config.rounds || playersWaitingForTurn.length > 0 )
    {
      await this.giveNextTurn()
    }
    else
    {
      await delay( 1.000 )

      await this.end()
    }
  }

  public async giveNextTurn()
  {
    const state = this.data.state
    const playerIds = Object.keys( this.data.players )

    let playersWaitingForTurn = state.round?.playersWaitingForTurn ?? []
    let roundNumber = state.round?.number ?? 0

    //// If there are no players waiting for turn, increment the round and refill queue
    if ( ! playersWaitingForTurn.length )
    {
      roundNumber++
      playersWaitingForTurn = [...playerIds]
    }

    //// Pop the next turn player from the queue
    const turnPlayer = playersWaitingForTurn.pop()

    await Promise.all([
      //// Update round data
      this.update.round({ playersWaitingForTurn, number : roundNumber }),
  
      //// Reset players' data
      ...playerIds.map( id => this.update.player( id, { guessed : false } ) ),
  
      //// Reset turn data
      this.update.turn({
        started : false,
        over: false,
        player : turnPlayer,
        word : '',
        wordHint : '',
        time : this.game.getConfig()!.turnTime,
        timePhase : TimerPhase.PercentLeftLessThan100,
        timeout: this.game.getConfig()!.wordTime ?? 60,
        canvas : {},
        // chat : {},
        number : ( this.data.state?.turn?.number || 0 ) + 1,
      }),
    ])
  }

  //// //// //// //// 

  async handleAction( action: Action )
  {
    if ( action.type === "guess" ) {
      if ( action.forTurnNumber !== this.data.state.turn.number ) {
        throw new Error(`${ action.sender 
        } tried to guess for turn ${ action.forTurnNumber 
        } during turn ${ this.data.state.turn.number }`)
      }
      if ( this.data.players[ action.sender ]?.guessed ) {
        throw new Error(`${ action.sender 
        } tried to submit for rotation ${ action.forTurnNumber 
        } but they are already marked as having submitted`)
      }
      await this.handlePlayerGuess( action.phrase, action.sender )
    }
  }

  //// Actual Gameplay Logic
  
  isGuessCorrect( guess:string ) {
    const target = this.data?.state?.turn?.word;
    return !! target && guess.toLowerCase().trim() == target?.toLowerCase().trim()
  }

  /** Returns two values, the score delta for the guessing player, and for the turn player */
  calculateScores() {
    const state = this.data.state
    const players = this.data.players
    const playerStates = Object.values(players);

    //// Count players who have already guessed
    const guessedCount = playerStates.reduce(
      (accumulator,player) => accumulator + ( player.guessed ? 1 : 0 ), 
      0
    )

    let guessedScore = !guessedCount ? 25 : guessedCount == 1 ? 15 : 0;
    const guesserEarns = guessedScore + (2 * state.turn!.time!);
    let turnPlayerEarns = 15;
    
    if(guessedCount == playerStates.length - 1) {
      turnPlayerEarns += 25;
    }

    return [guesserEarns, turnPlayerEarns];
  }

  async handlePlayerGuess( phrase:string, guessingPlayerId:PlayerID )
  {
    const state = this.data.state

    if ( state?.over ) return;
    if ( !state?.started ) return;
    if ( !state?.turn?.started ) return;

    const correct = this.isGuessCorrect(phrase)
    const guessingPlayer = this.data.players[ guessingPlayerId ]
    const turnPlayerId = state.turn.player!;

    if ( guessingPlayerId === turnPlayerId ) return
    if ( guessingPlayer.guessed ) return
    
    if (correct)
    {
      const players = this.data.players
      const playerStates = Object.values(players);
      const turnPlayer = players[ state.turn.player! ];

      //// Count players who have already guessed
      const guessedCount = playerStates.reduce(
        (accumulator,player) => accumulator + ( player.guessed ? 1 : 0 ), 
        0
      )

      let guessedScore = !guessedCount ? 25 : guessedCount == 1 ? 15 : 0;
      const guesserEarns = guessedScore + (2 * (state.turn!.time! || 1));
      let turnPlayerEarns = 15;
      const turnNumber = state.turn.number
      
      if(guessedCount == playerStates.length - 1) {
        turnPlayerEarns += 25;
      }

      // const  [guesserEarns, turnPlayerEarns] = this.calculateScores();

      const turn = this.data?.state?.turn?.number || 0;
      const message:ChatMessage = { text : `${  players[guessingPlayerId].username } guessed correctly!`, correct, sender : '', turn }
      
      await Promise.all([
        this.game.ref.chat().push( message ),
        this.ref.state().child('prevTurnResults/scores').update({ 
          [guessingPlayerId] : guesserEarns,
          [turnPlayerId] : turnPlayerEarns,
        }),
        this.ref.player( guessingPlayerId ).update({ 
          guessed : true,
          score : ( guessingPlayer.score || 0 ) + guesserEarns,
        }),
        this.ref.player( turnPlayerId ).update({ 
          score : ( turnPlayer.score || 0 ) + turnPlayerEarns,
        }),
      ])
    
      if ( this.allGuessersHaveGuessed(correct ? guessingPlayerId : undefined) ) {
        await delay( 1.000 );
        await this.endTurn( turnNumber );
      }
    }
  }

  public allGuessersHaveGuessed(excluding?:PlayerID) {
    return Object.entries(this.data.players)
      .every( ([uid,{guessed}]) => 
        ( uid === this.data.state.turn.player ) || 
        ( uid === excluding ) || 
        guessed )
  }

  async end()
  {
    await this.update.state({ over : true, })
    analyticsService.GameCompleted(this.game.gameId, this.game.roomId!);
  }
}
