import React, { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MainHeader } from '../../organisms/MainHeader';
import _ from "lodash";
import { HostEventsService } from '@ven/shared/core/services/HostEvents';

import 'react-datasheet/lib/react-datasheet.css';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { Col, Nav, NavItem, NavLink, Row, Spinner, Tab } from 'react-bootstrap';
import CreateEvent from './CreateEvent';
import EventInfo from './EventInfo';
import { BeatLoader, MoonLoader } from 'react-spinners';
import { GetCurrentUserRef } from '@ven/platform/main/services/user/UserDataService';
import { auth, database } from '@ven/shared/core/data/firebase';
import { CouponService } from '@ven/shared/core/services/CouponService';
import { RequestRolePanel } from '@ven/shared/components/common/templates/RequestRolePanel';

type hostEventContext = {
  eventData: any
  session: any
  setSession: Function,
  setEventData: Function,
}
export const HostEventContext = createContext<hostEventContext>({
  eventData: {},
  session: {},
  setSession: () => { },
  setEventData: () => { }
});

const EventHostPage: React.FC = () => {

  const { eventId } = (useParams() as any);

  
  const [eventsRequested, setEventsRequested] = useState(false);
  const [events, setEvents] = useState(new Array);

  const [eventData, setEventData] = useState(Object);
  const [session, setSession] = useState(Object);

  // const [isCreateEvent, setCreateEvent] = useState(false);

  const setTab = async (key) => {
    // history.replace('/'+key); 
    // window.history.pushState({}, '', '/eventHost/' + key);
    // window.location.pathname = key;
    const isCreateEventTab = key == 'createEvent';

    // setCreateEvent(isCreateEventTab);
      setEventData(isCreateEventTab ? null : events.find(e => e.id == key))
  }

  useEffect(() => {
    const page = eventData && eventData.id ? eventData.id : 'createEvent'
    window.history.pushState({}, '', '/eventHost/' + page);
  }, [eventData])

  const [userData, setUserData] = useState(Object);

  useEffect(() => {
    auth.onAuthStateChanged(async _ => {
      GetCurrentUserRef().then(ref => {
        if (!ref) {
          return;
        }
        ref.on('value', o => {
          const userData = o.val();
     
          setUserData({
            ...userData
          });
        });
      });
    });

  }, [auth.currentUser?.uid]);

  React.useEffect(() => {
    getHostEvents();
  }, []);

  React.useEffect(() => {
    addBundle();
  }, [userData]);

  async function getHostEvents() {
    await HostEventsService.getHostEvents(events => {
      setEvents(events);
      setEventsRequested(true)
      // setCreateEvent(eventId == 'createEvent')

      if(eventData && eventData.id) {
        setEventData(events.find(e => e.id == eventData.id));
        return;
      }
     
      if(eventId && eventId != 'createEvent')
        setEventData(events.find(e => e.id == eventId));

      console.log(events);
    });
  }

  const openLoginModal = () => {
    const loginButton = document.getElementsByClassName('login')[0];
    (loginButton as any).click();
  }

  const addBundle = async () => {
    if(!userData.uid)
      return;

    if(!userData?.packages?.GameNightBundle?.EventHost)
      await CouponService.PutCodeOnUserInfo("Game Night Bundle", "EventHost", 0, 'ACTIVE', "EventHost", "EventHost", true)

    if(userData?.activeCoupon)
      return;
      
    const dbRef = database.ref(`/users/${userData.uid}/packages/GameNightBundle/EventHost`);
    dbRef.once('value').then(async (data) => {
      const userRef = database.ref(`/users/${userData.uid}/activeCoupon`);
      userRef.set({
          ...data.val(),
          activationDate: new Date().toISOString(),
          state: 'ACTIVE',
      });
    });
  }
  
  const role = "eventHost";

  return (
    <HostEventContext.Provider value={{ session, setSession, eventData, setEventData }}>
      <Wrapper>
        <Helmet>
          <title> Host Event | VEN Games </title>
        </Helmet>
        <div className="main-account">
          <div className="main-header-account">
            <MainHeader darkTheme={true} />
          </div>
          <div className="main-account">
            {
                !userData[role] ? <RequestRolePanel role={role} /> :
                <Tab.Container 
                      onSelect={(k) => setTab(k)}
                      activeKey={eventData && eventData.id ? eventData.id : "createEvent"} >
                      <Row>
                        <Col sm={3} className="nav-menu">
                          <Nav variant="pills" className="flex-column">
                            <NavItem>
                              <NavLink eventKey="createEvent">Create Event</NavLink>
                            </NavItem>
                            {
                              !eventsRequested ?
                                <NavItem>
                                  <BeatLoader />
                                </NavItem>
                                : events.map(e =>
                                  <NavItem title={e.name} key={e.id}>
                                    <NavLink eventKey={e.id}>{e.name}</NavLink>
                                  </NavItem>)
                            }
                          </Nav>
                        </Col>
                        <Col sm={9} style={{paddingLeft: 0}}>
                          <Tab.Content>
                            <Tab.Pane eventKey="createEvent">
                              <CreateEvent />
                            </Tab.Pane>
                            {
                              events != null && events.map(e =>
                                <Tab.Pane eventKey={e.id} key={e.id}>
                                  {eventData && eventData.id == e.id &&
                                    <EventInfo data={e}></EventInfo>
                                  }
                                </Tab.Pane>)
                            }
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
            }

            {/* <footer>
        <div>Virtual Engagement - VEN Games</div>
        <div><a href="mailto:contact@ven.games">Contact@VEN.games</a></div>
        <div><Link to="/termsandconditions">Terms {'&'} Conditions</Link></div>
        <div>Leave Feedback</div>
      </footer> */}
          </div>
        </div>
      </Wrapper>
    </HostEventContext.Provider>
  )
}

const Wrapper = styled.div`
  /* max-width: 1200px; */
  min-height: 100vh;
  min-width: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  .form-control {
    font-size: 1.8rem;
  }
  .btn-info {
    color: #fff;
    background-color: #001892;
    border-color: #001892;
  }
 
  .nav-item a {
    color: #001892;
    &.disabled {
      color: gray;
    }
  }
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: #001892;

  }

  .card-header .btn-link {
    font-size: 15px;
    font-weight: bold;
    text-decoration-line: underline;
  }

  .nav-menu {
    background: rgba(0,0,0,0.1);
    padding-right: 0;
  }

  .nav-item {
    outline: 1px solid rgba(0,0,0,0.1);
    background: #fff;
  }

  .main-account{
    background: #eeeeee;
    height: 100vh;
    /* margin: auto; */
    &>.row{
      height: 100vh;
    }
  }

 form {
   button[type=submit]{
     width: 100%;
     font-size: 1.5rem;
   }
 }
`;


export default EventHostPage;
