import React from 'react';

import { GameStateContext } from '../../gameplay/GameStateContext';
import { PlayerAvatar } from '@ven/shared/components/game/molecules/PlayerAvatar';

import styled from '@emotion/styled';
import { TimerCircleProgress } from '@ven/shared/components/game/molecules/TimerCircleProgress';

export const PlayerUpBit:React.FC<any> = ({ playerId, ...props }) => 
{
  const { game } = React.useContext( GameStateContext )
  const data = game.data!

  const playerTeam = data.players[ playerId ].team;
  const playerTeamName = game.getTeamName(playerTeam);

  const playerName = data.players[ playerId ].username
  const playerAvatar = data.players[ playerId ].avatar
  
  const playerOnline = data?.presences?.[ playerId ]?.online

  const timeout = data?.state?.turn?.timeout || ''
  const totalTime = game.getConfig()!.timeout;

  const NextPlayerText = styled.div`
    display: flex;
    flex-direction: row;

    .text {
      flex: 1 1;
      font-family: Bitter;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      color: #FFFFFF;

      display: flex;
      align-items: center;

      padding-left: 12px;
      
      .yellow {
        color: #FFE499;
      }
    }
  `

  return (
    <NextPlayerText {...props}>
       <TimeoutClock>
          <TimerCircleProgress time={timeout} totalTime={totalTime} 
            radius={40} displayTime={false} stroke={4}
           />
            <PlayerAvatar size={60} index={ playerAvatar }
             label={game.getTeamName(playerTeam)}
              transparent={!playerOnline} />
        </TimeoutClock>

      <div className="text">
        <div>
          { playerName } { playerId === game.myUserId && !game.amSpectator() ? `you're ` : `is ` }
          up next for the <span className="yellow">{ playerTeamName }</span> to give clues!
        </div>
      </div>
    </NextPlayerText>
  )
}

const TimeoutClock = styled.div`
  display: grid;
  margin-top: -10px;

  .avatar-container {
    grid-area: 1/1;
  }
  .circle-clock {
    grid-area: 1/1;
  }
`;