import React, { useState } from 'react';

import corner1 from '../../../../assets/images/choose_bundle_corner_1.svg';
import corner2 from '../../../../assets/images/choose_bundle_corner_2.svg';
import peopleImg from '../../../../assets/images/choose_bundle_img.svg';

import './styles.scss'

interface ChooseCardProps {
    bannerText: string,
    title: string,
    subtitle?: string,
    playerText: string,
    descriptionDesktop?: string,
    descriptionMobile?: string,
    text1?: string,
    text2?: string,
    showImages: string,
    buttonText: string,
    buttonAction: Function,
    footerText: string
}

const ChooseCard: React.FC<ChooseCardProps> = ({
    bannerText,
    title,
    subtitle,
    playerText,
    descriptionDesktop,
    descriptionMobile,
    text1,
    text2,
    showImages,
    buttonText,
    buttonAction,
    footerText,
}) => {
    const [disableButton, setDisableButton] = useState(false);

    const runButtonAction = () => {
        setDisableButton(true);
        buttonAction();
    }

    return (
        <div className="choose-cards">
            <div className="card">
                <div className="card-title">
                    <img src={showImages === 'true' ? corner1 : undefined} alt="" />
                    <p className="banner">{bannerText}</p>
                    <p className="spacer">{" "}</p>
                    <img src={showImages === 'true' ? corner2 : undefined} alt="" />
                </div>
                <div className="card-content">
                    {showImages === 'true' ?
                        <img src={peopleImg} alt="" /> : undefined
                    }
                    <div className="title-box">
                        <p className="title">{title}</p>
                        <p className="subtitle">{subtitle}</p>
                    </div>
                    <p className="player-text">{playerText}</p>
                    {text1 ?
                        <p className="text-one">{text1}</p> : undefined
                    }
                    {text2 ?
                        <p className="text-two">{text2}</p> : undefined
                    }
                    {descriptionDesktop ?
                        <p className="description-desktop">{descriptionDesktop}</p> : undefined
                    }
                    {descriptionMobile ?
                        <p className="description-mobile">{descriptionMobile}</p> : undefined
                    }
                    {showImages === 'true' ?
                        <img src={peopleImg} alt="" /> : undefined
                    }
                </div>
                <div className="card-footer">
                    <button className={`desktop-btn`} onClick={() => runButtonAction()}>{buttonText}</button>
                    <button className={`mobile-btn`} onClick={() => runButtonAction()}>{">"}</button>
                    <p>{footerText}</p>
                </div>
            </div>
        </div>
    )
};

export default ChooseCard;
