import React from 'react';
import { MainHeader } from '../../../organisms/MainHeader';

import './styles.scss';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { MainFooter } from '../../../organisms/MainFooter';

const SupportPage = () => {

    return (
        <div className="terms-landing">
            <Helmet>
                <title> Support | VEN Games </title>
            </Helmet>
            <div className="terms-content">
                <MainHeader />
                <Content>
                    <span>Need help? Contact <a href="mailto:contact@ven.games">Contact@VEN.games</a></span>
                    <span>Want to leave a feedback? <a href="https://forms.gle/wXFgK2RzbxbxdkEw6">Leave Feedback</a></span>
                </Content>
            </div>
            <MainFooter/>
        </div>
    )
};

const Content = styled.section`
    background-color: white;
    margin: 100px auto;
    display: grid;
    margin-right: auto;
    line-height: 1.7;
    padding: 70px;
    font-family: 'Oswald';
`;

export default SupportPage;
