export const InitZoom = async (zoomData: any) => {
  console.log(zoomData);
  if(zoomData) {
    zoomData = {code : zoomData};
  }
  const zoom = await fetch(`${process.env.FUNCTION_URL}/zoom`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST', body: zoomData ? JSON.stringify(zoomData) : ''
  });

  const body = await zoom.json();
  console.log(body);
  if (body.oauthUrl) {
    // If no authorization code is available, redirect to Zoom OAuth to authorize
    window.location.replace(body.oauthUrl);
    return false;
  }

  if(body.error == 'invalid_request') {
    InitZoom(null);
    return false;
  }
  console.log(body)
  // console.log(`access_token: ${body.access_token}`);
  // console.log(`refresh_token: ${body.refresh_token}`);
  // // Step 4:
  // // We can now use the access token to authenticate API calls

  // // Send a request to get your user information using the /me context
  // // The `/me` context restricts an API call to the user the token belongs to
  // // This helps make calls to user-specific endpoints instead of storing the userID

  // const zoomUserRequest = await fetch('https://api.zoom.us/v2/users/me',  { 
  //   method: "GET",
  //   headers: {
  //     'Authorization': body.token_type + " " + body.access_token,
  //     // 'Content-Type': 'application/x-www-form-urlencoded',
  //     // 'Accept': 'application/json',
  //     // 'Content-Type': 'application/json',
  //     // 'Access-Control-Allow-Origin': '*'
  //   }});
  // if (zoomUserRequest.status >= 200 && zoomUserRequest.status <= 299) {
  //   const zoomUser = await zoomUserRequest.json();
  //   console.log(zoomUser);
  // } else {
  //   console.log(zoomUserRequest.status, zoomUserRequest.statusText);
  // }

  return true;
}

//export const InitZoom = async (zoomData: any) => {
  //   // Step 1: 
  //   // Check if the code parameter is in the url 
  //   // if an authorization code is available, the user has most likely been redirected from Zoom OAuth
  //   // if not, the user needs to be redirected to Zoom OAuth to authorize
  //   const redirectURL = process.env.zoomRedirectURL;
  
  //   if (!zoomData.code) {
  //     // Step 2: 
  //     // If no authorization code is available, redirect to Zoom OAuth to authorize
  //     const zoomOAuthUrl =
  //     `https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.zoomClientID}
  //     &redirect_uri=${redirectURL}`;
  
  //     window.location.replace(zoomOAuthUrl);
  //     return;
  //   }
  
  //   // Step 3: 
  //   // Request an access token using the auth code
  //   let url = `https://zoom.us/oauth/token?grant_type=authorization_code&code=${zoomData.code}
  //              &redirect_uri=${redirectURL}`;
  
  //   let headers = new Headers();
  //   headers.append('Authorization', 'Basic ' + btoa(process.env.clientID + ":" + process.env.clientSecret));
  
  //   const response = await fetch(
  //     url, {
  //     method: 'POST',
  //     headers
  //   });
  //   const body = await response.json();
  
  //   // Logs your access and refresh tokens in the browser
  //   console.log(`access_token: ${body.access_token}`);
  //   console.log(`refresh_token: ${body.refresh_token}`);
  
  //   if (!body.access_token) {
  //     // If no authorization code is available, redirect to Zoom OAuth to authorize
  //     window.location.replace(zoomOAuthUrl);
  //     return;
  //   }
  
  //   // Step 4:
  //   // We can now use the access token to authenticate API calls
  
  //   // Send a request to get your user information using the /me context
  //   // The `/me` context restricts an API call to the user the token belongs to
  //   // This helps make calls to user-specific endpoints instead of storing the userID
  
  //   let headersUser = new Headers();
  //   headersUser.append('Authorization', body.token_type + " " + body.access_token);
  //   headersUser.append('Content-Type', 'application/x-www-form-urlencoded');
  
  //   const zoomUserRequest = await fetch('https://api.zoom.us/v2/users/me', { headers: headersUser });
  //   if (zoomUserRequest.status >= 200 && zoomUserRequest.status <= 299) {
  //     const zoomUser = await zoomUserRequest.json();
  //     console.log(zoomUser);
  //   } else {
  //     // Handle errors
  //     console.log(response.status, response.statusText);
  //   }
  
  //   return;
  // }