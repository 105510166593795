import React from 'react';

import { GameStateContext } from '@ven/games/scribbler/gameplay/GameStateContext';
import type { GameStateController } from '@ven/games/scribbler/gameplay/GameStateController';

import { PlayerAvatar } from '@ven/shared/components/game/molecules/PlayerAvatar';
import { LargeText, OverlayDialogBase, SmallText } from './OverlayDialogBase';
import { GreenButton } from '@ven/shared/components/common/atoms/GreenButton';
import { InlineSpacer, Spacer } from '@ven/shared/components/common/atoms/Separator';
import { StarIcon, UndoIcon } from '../assets/icons';

import { COLOR_DARK, COLOR_GRAY, COLOR_PRIMARY } from '@ven/shared/components/styling/colors';
import styled from '@emotion/styled';
import { MOBILE_BREAK_PX } from '@ven/shared/components/styling/breakpoints';
import { TimerCircleProgress } from '@ven/shared/components/game/molecules/TimerCircleProgress';

interface Props 
{ 
  game : GameStateController,
}
export const OverlayDialog_PreTurn:React.FC<Props> = ({ game }) =>
{
  const { data } = React.useContext( GameStateContext )!

  const currentPlayerId = data?.state?.turn?.player || ''
  const currentPlayerName = data?.players?.[ currentPlayerId ]?.username
  const currentPlayerOnline = data?.presences?.[ currentPlayerId ]?.online
  const currentPlayerAvatar = data?.players?.[ currentPlayerId ]?.avatar

  const timeout = data?.state?.turn?.timeout || ''
  const totalTime = game.getConfig()!.wordTime;
  
  const isMyTurnPlayer = data?.state.turn.player === game.myUserId && !game.amSpectator();
  const Text = LargeText;

  const players = Object
    .entries( data?.players || {} )
    .map( ([ id, data ]) => ({ 
      id, 
      name: data.username, 
      scoreDelta : game?.data?.state?.prevTurnResults?.scores?.[id] || 0,
      ...data,
    }) )
    .sort( (a,b) => b.scoreDelta - a.scoreDelta )

    const prevPlayer = data?.players?.[ data?.state?.prevTurnResults?.player! ]?.username
  const title = 
    data?.state?.prevTurnResults?.skipped ?
      `Timeout! ${prevPlayer}'s turn got`:
    ( data?.state?.prevTurnResults?.timeLeft || 0 ) < 1
    ? `That’s Time! The word was`
    : `Good job! The word was`

  const roundLabelText = !! data?.state?.round?.number ? `Round: ${ data?.state?.round?.number } / ${ data.config.rounds }` : `...`

  return (
    <OverlayDialogBase style={{ 
      width : `405px`,
      maxWidth: `90vw`,
      padding: `20px 12px`,
    }}>
      {!data?.state?.prevTurnResults?.skipped 
        ? <StarIcon />
        : <UndoIcon color={"#A19AC6"} size={32} />
      }
      <SmallText> { title } </SmallText>
      <Spacer size={ 12 } />
      {!data?.state?.prevTurnResults?.skipped ? 
        <Word> { data?.state.prevTurnResults?.word } </Word>
      : <Word> skipped! </Word>
      }
      <Spacer size={ 30 } />

      <PlayerTableContainer>
        <PlayerTable>
        {
          players.map( p => (
            <PlayerListItem key={ p.id }>
                <PlayerAvatar size={ 32 } index={ p.avatar }/> 
                <PlayerName> { p.name } </PlayerName>
                <ScoresWrapper>
                <Score>
                  { p.scoreDelta > 0 && '+' }{ p.scoreDelta || 0 } 
                </Score>
                <Score className="total-mobile">
                  {`(${p.score ||  p.scoreDelta})`}
                </Score>
                </ScoresWrapper>
            </PlayerListItem>
          ) )
        }
        </PlayerTable>
      </PlayerTableContainer>

      <Spacer size={ 20 } />
      {/* <Button> Play Again </Button> */}
      {
        !! isMyTurnPlayer 
        ? <GreenButton onClick={ () => game.startTurn() }> Continue </GreenButton>
        : ''
      }
      <Round> { roundLabelText } </Round> 
      {
        !isMyTurnPlayer 
        ?   <div>
              <TimeoutClock>
                <TimerCircleProgress time={timeout} totalTime={totalTime} 
                  radius={35} displayTime={false} stroke={4}
                  color={"#303744"} />
                <PlayerAvatar size={50} index={ currentPlayerAvatar }
                  transparent={!currentPlayerOnline} />
              </TimeoutClock>
              <Text> Wait for { currentPlayerName } to choose what to draw... </Text>
              <SmallText>{ currentPlayerName }'s turn will be skipped {!currentPlayerOnline ? 'soon' : `in ${timeout}` }...</SmallText>
              {/* <Hint> Try to guess as quickly as possible to score more points. </Hint> */}
            </div>
        : <SmallText>Continue or your turn will be skipped in {timeout}...</SmallText>
   
      }
    
    </OverlayDialogBase>
  )
}

const TimeoutClock = styled.div`
  display: grid;
  
  .avatar-container {
    grid-area: 1/1;
  }
  .circle-clock {
    grid-area: 1/1;
  }
`;

const Round = styled.div`

  @media (min-width: ${ MOBILE_BREAK_PX }px) {
    display: none;
  }

  color: ${ COLOR_GRAY };
  width: 100%;
  text-align: center;
  margin: 10px 0px;

  &:before {
    content: '';
    position: absolute;
    width: 8rem;
    margin: 1rem -10rem;
    border-top: 1px solid;
  }
  
  &:after {
    content: '';
    position: absolute;
    width: 8rem;
    margin: 1rem 2rem;
    border-top: 1px solid;
  }
`

const Word = styled.div`
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #303744;
`

const PlayerTableContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  min-width: 292px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`

const PlayerTable = styled.div`
  width: 100%;
  min-width: 200px;
  border-spacing: 0 20px; 
  border-collapse:separate;
`

const PlayerListItem = styled.div`
 display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "avatar playerName playerName score";

  align-items: center;
  justify-content: space-between; 
  margin-bottom: 20px;
  width: 100%;
  gap: 5px;
  overflow: hidden;
`
const ScoresWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 50px;
  gap: 2px;
`

const Score = styled.span`
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${ COLOR_PRIMARY };
  text-align: right;
  grid-area: score;

  &.total-mobile {
    display: none;
    font-size: 14px;
    color: grey;
  }

  @media (max-width: ${ MOBILE_BREAK_PX }px) {
    font-size: 18px;
    text-align: center;

    &.total-mobile {
      display: flex;
    }
  }
`

const PlayerName = styled.span`
  margin-left: 5px;
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: ${ COLOR_DARK };
  text-align: left;
  max-width: 180px;
  grid-area: playerName;
`

// const Tip = styled.div`
//   font-family: Bitter;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 15px;
//   line-height: 22px;
//   color: #303744;
//   max-width: 250px;
//   text-align: center;
// `
