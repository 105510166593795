import { COLOR_LIGHT, COLOR_PRIMARY, COLOR_TOOL_ACTIVE, COLOR_TOOL_IDLE } from '@ven/shared/components/styling/colors';
import styled from '@emotion/styled';

export const GreenButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Bitter', serif;
  font-weight: bold;
  font-size: 20px;
  /* text-transform: capitalize; */
  text-decoration: none;
  border-radius: 12px;
  border: none;
  outline: none;
  width: 292px;
  height: 66px;
  margin-bottom: 24px;
  color: white;
  
  color: white;
  background: ${ COLOR_PRIMARY };
  border: 1px solid ${ COLOR_PRIMARY };
  transition: color 300ms, background-color 300ms, border-color 300ms, opacity 300ms;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
  
  &[disabled] {
    opacity: .6;
  }

  &:not([disabled]) {
    cursor: pointer;
  }

  &:not([disabled]):hover {
    border: 1px solid ${ COLOR_LIGHT };
    transition: border-color 150ms;
  }
`
