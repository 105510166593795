import React, { useEffect } from 'react';

import { UserDataContext } from '@ven/platform/main/services/user/UserDataContext';
import { UpdateUsernameAvatar } from '@ven/platform/main/services/user/UserDataService';
import { UserDataService } from '@ven/platform/main/services/user/UserDataService';
import debounce from "lodash.debounce";

import styled from "@emotion/styled";

export const NameInput:React.FC<{}> = () => {
  const user = React.useContext( UserDataContext );
  const [myName,setMyName] = React.useState( user.username );

  if ( user?.username && myName === undefined ) {
    setMyName( user.username );
  }

  useEffect(()=> {
    if ( user?.username ) {
      setMyName( user.username );
    }
  
  }, [ user ])

  const updateDebounce = debounce(
    name => {
      UserDataService.set("username", name)
      UpdateUsernameAvatar( name, undefined )
      // onChange?.( name )
    }, 
    250
  );
  const update = React.useCallback(updateDebounce, []);

  const handleChange = name => {
    setMyName(name)
    name && update(name) 
  }

  return (
    <Input
    type="text"
    className="name-input"
    maxLength={30}
    onBlur={ () => ! myName && !! user.username && setMyName( user.username ) }
    onChange={ e => handleChange(e.target.value) }
    onKeyPress={ e => e.key === "Enter" && e.target['blur']() }
    value={ myName || '' }
    />
  )
}

const Input = styled.input`
margin-top: 10px;

padding-top: 10px;
display: inline-block;
width: 100%;

transition: none;
height: 67px;
border-radius: 7px;
padding: 0;

max-width: 385px;
width: 100%;

font-family: Bitter;
font-style: normal;
font-weight: bold;
font-size: 17px; /* To avoid zooms bugs on IOS, never do an input font-size less than 17px */
line-height: 67px;
text-align: center;

color: #303744;

&:focus {
  outline: none;
}
`
