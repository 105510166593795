/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { COLOR_TOOL_ACTIVE } from '@ven/shared/components/styling/colors';

import React from 'react';
import { colorIntegerToHashTag } from '@ven/shared/core/utils/colors';
import { DrawingSettingsContext, DrawingTool } from '@ven/games/scribbler/drawing/DrawingSettings';
import { PORTRAIT_BREAK_PX } from "@ven/shared/components/styling/breakpoints";

import styled from "@emotion/styled";

interface Props 
{
  colors : number[],
}
export const DrawingPalette:React.FC<Props> = ({ colors }) => 
{
  const { settings, updateSettings } = React.useContext( DrawingSettingsContext )

  //// ORGANISM

  return (
    <Row className="palette">
      <div className="slider">
        { colors.map( color => 
          <BrushColorButton 
            key={ color } color={ color } selected={ settings.brushColor === color && settings.tool === DrawingTool.Draw }
            onClick={ () => updateSettings({ brushColor : color, tool : DrawingTool.Draw }) }
          /> ) }
      </div>
    </Row>
  )
}

//// Molecule 

const Row = styled.div`
  display: flex;
  flex-direction: row;
  background: white;
  position: relative;
  box-shadow: #0003 16px 0 32px;
  z-index: 9;
  padding: 4px 0;
  box-sizing: content-box;
  min-height: 64px;
  margin-bottom: -64px;
  max-width: 100%;

  overflow-x: auto;
  overflow-y: hidden;

  .slider {
    min-width: 650px;
  }
`

//// Atoms

const brushButtonStyle = css`
  flex: 1 1 auto;
  border: none;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  background: none;

  width: 63px;
  height: 63px;

  /* The Icon */
  & .icon {
    transition: transform 250ms;
  }
  &:active .icon {
    transition: transform 100ms;
    transform: scale(.85);
  }

  /* The Circle */
  &:after {
    content: '';
    display: block;
    width: 51px;
    height: 51px;
    
    transform: translate(50% 50%);
    transform-origin: center middle;

    position: absolute;
    transition: border 400ms;
    border: 12px solid #0000;
    border-radius: 50%;
    
    z-index: 1;
  }
  &[current]:after {
    border: 2px solid ${ COLOR_TOOL_ACTIVE };
  }
`
const brushButtonIconStyle = css`
  height: 100%;
  width: 100%;
  margin: 12px;
`
const BrushColorButton = ({color, selected, onClick}) => 
{
  return (
    <button 
    {...{ current : selected ? '' : null }}
    onPointerDown={ e => e.preventDefault() } 
    onClick={ onClick }
    css={ brushButtonStyle }>
      <svg 
      className="icon"
      viewBox="0 0 52 52" 
      preserveAspectRatio="xMidYMid meet" 
      css={ brushButtonIconStyle }>
        <circle cx="26" cy="26" r="25" stroke="#A19AC6" strokeWidth="1" fill={ colorIntegerToHashTag( color ) } />
      </svg>
    </button>
  )
}
