import React from 'react';

import { GreenButton } from '../atoms/GreenButton';
import { PlayerAvatar } from '../../game/molecules/PlayerAvatar';
import styled from '@emotion/styled';
import { Spacer } from '../atoms/Separator';

export const HowToPlayLink:React.FC<{ openHowToPlay:Function }> = ({ openHowToPlay: showHowToPlay }) => (
  <div className="how-to-tip">
    Not sure how to play? <a onClick={ () => showHowToPlay() }> Learn how </a>
  </div>
)

export const InvitedText = ({ gameName, refererData }) =>
(
  <div className="invited-text"> 
  {
    !! refererData?.username 
    ? `${ refererData?.username } invited you to play ${ gameName }!`
    : `You were invited to play ${ gameName }!`
  }
  </div>
)

export const JoinButton = ({ disabled = false, game, username, joinData={} }) => {
  const [busy,setBusy] = React.useState(false);
  ////
  return (
    <Wrapper>
      <GreenButton 
        style={{ whiteSpace: 'normal' }}
        className="button-play" 
        disabled={ busy || disabled || game.hasMaxPlayers() || !username?.trim() }
        onClick={ async () => {
          setBusy( true );
          try { await game.join(joinData) }
          finally { setBusy( false ) }
        } }> 
        {`${game.hasMaxPlayers() ? `Sorry, the game is full!` : !username?.trim() ? 'Please enter a username above' :  "Join and Play!"}`}
      </GreenButton>
        
      {game.hasMaxPlayers() ? <span>Ask the host to upgrade their account so you can join!</span> : null }
      <Spacer size={10} />
    
      <a className="spectator" onClick={() => {
        const gameUrl = window.location.href.replace(`/play/`, `/watch/`);
        window.location.replace(gameUrl);
      }}>
          I would like to watch instead!
      </a>
    </Wrapper>
    
  )
}

export const CircledAvatarAtTheTop = (props:any) => (
  <div className="avatar-wrapper">
    <PlayerAvatar size={ 90 } {...props} />
  </div>
)

export const PleaseEnterNameTip = () => (
  //// TODO style this propertly
  <p style={{ height: `66px`, marginTop: `24px` }}> Please enter a username above </p>
)

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 255px;
  text-align: center;
  margin: 0 auto;

  a.spectator {
    color: white;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: white;
    }
  }
`
