import React from 'react';

import Loader from "react-spinners/PuffLoader";
import { CenteredContent } from '@ven/shared/components/common/CenteredContent';
import styled from '@emotion/styled';

export const LoadingView:React.FC = () => 
{
  const loading = true
  return (
    <Wrapper>
      VEN GAMES<br/>
      <Loader
        size={50}
        color={"#FFFC"}
        loading={ loading }
      />
    </Wrapper>
  )
}


const Wrapper = styled.div`
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;    
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: white;
`