import { LogService } from '@ven/platform/main/services/log/LogService';
import type { GameSessionData as GameSessionDataBase, PlayerID } from '@ven/shared/core/gameplay/GameSessionData';
import { GameStateController as GameStateControllerBase } from '@ven/shared/core/gameplay/GameStateController';

import { LoggingFeature, WithLogging } from '@ven/shared/core/gameplay/plugins/Logging';
import { GamePseudoServer } from './GamePseudoServer';

////

const TEAM_NAMES = [
  "🧊 Ice",
  "🔥 Fire",
];

////

export const DEFAULT_CONFIG = {
  rounds : 3,
  turnTime : 90,
  timeout: 60,
  minPlayersCount: 4,
  maxPlayersCount: 20,
  pack: 'outlaw-starter'
}
export type GameConfigurationData = typeof DEFAULT_CONFIG

////

export enum Team {
  Red = 1,
  Blue = 2,
}
export interface TabooCardData {
  word : string
  taboo : string[]
}
export interface ChatMessage {
  text : string, 
  sender : PlayerID,
  type? : "foul"
}
type PlayerData = {
  team : Team,
} & GameSessionDataBase['players'][0]

interface GameStateTurnData {
  started : boolean
  player? : PlayerID
  team? : Team
  card? : TabooCardData
  time? : number
  timeout? : number
  guessed?: number
  missed?: number
  fouls: { caller:PlayerID, word:string }[]
  number: number
  chat? : Record<string, ChatMessage>
}
interface GameStateRoundData {
  number: number
  playersWaitingForTurn? : PlayerID[]
}
interface GameStateData {
  started : boolean
  over : boolean
  nextGameUrl : string
  turn : GameStateTurnData
  round : GameStateRoundData
  scores : Record<Team,number>
  forbiddenWords : string[]
  prevTurnResults? : {
    team : Team
    player : PlayerID
    score : number
    time? : number
    guessed?: number
    missed?: number
  }
}
export interface GameSessionData extends GameSessionDataBase
{
  players : Record<PlayerID, PlayerData>
  config : GameConfigurationData
  state : GameStateData
}

export class GameStateController extends GameStateControllerBase<GameSessionData & WithLogging>
{
  public readonly log = new LoggingFeature(this);

  public readonly server = new GamePseudoServer(this);
  
  public readonly ref = {
    ...this.ref as GameStateControllerBase<GameSessionData>['ref'],
    chat : () => this.ref.state().child('turn/chat'),
  }

  public readonly update = {
    state : ( data:Partial<GameStateData> ) => this.ref.state().update( data ),
    turn : ( data:Partial<GameStateTurnData> ) => this.ref.state().child('turn').update( data ),
    round : ( data:Partial<GameStateRoundData> ) => this.ref.state().child('round').update( data ),
    player : ( playerId:PlayerID, data:Partial<PlayerData> ) => this.ref.players().child( playerId ).update( data ),
    scores : ( data:Partial<GameStateRoundData> ) => this.ref.state().child('scores').update( data ),
    me : ( data:Partial<PlayerData> ) => this.ref.players().child( this.myUserId! ).update( data ),
  }

  public getConfig():GameConfigurationData {
    return {
      ...DEFAULT_CONFIG,
      ...this.data.config,
    }
  }

  public setConfig(config : GameConfigurationData) {
    this.ref.config().update(config)
  }

  async initialize()
  {
    super.initialize();
    requestAnimationFrame( () => this.server.initialize() );
  }

  async destroy() {
    this.server.destroy();
    super.destroy();
  }

  async start(userConfig:any) 
  {
    try {
      await super.start(
        { round : { number : 0 }, scores : { 1 : 0, 2 : 0} }, 
        { ...this.getConfig(), ...userConfig },
      )
      await this.server.giveNextTurn()
    } catch ( e ) {
      this.log.error( e );
    }
  }

  public canStartGame = () => 
  {
    try {

      if (!this.getConfig().pack ) {
          return false
      }

      // if ( this.data.state.started ) {
      //   return false
      // }
        
      const teams = [ 
        Object.values( this.data.players || {} ).filter( p => p.team === Team.Red ).length, 
        Object.values( this.data.players || {} ).filter( p => p.team === Team.Blue ).length,
      ]
      return teams[0] > 1 && teams[1] > 1
    } catch ( e ) {
      this.log.error( e );
      return false;
    }
  }

  public getCurrentTeamName = () =>
  {
    const turnPlayerId = this.data.state.turn.player
    const turnTeam = this.data.players[ turnPlayerId! ].team
    const turnTeamName = this.getTeamName( turnTeam )
    return turnTeamName
  }

  public getNextPlayer = () => {
    const playersWaitingForTurn = this.data.state.round?.playersWaitingForTurn ?? []
    return playersWaitingForTurn[0]
  }

  public getTeams = () => [ Team.Red, Team.Blue ];
  
  public getTeamName = (team:Team) => TEAM_NAMES[ team - 1 ];

  //// PLAYER

  async onTick() {}

  async sendChatMessage( word:string )
  {
    const message:ChatMessage = { text : word, sender : this.myUserId! }
    const turnWord = this.data.state.turn.card?.word;
    await this.ref.chat().push( message )
    if(message.text.toLowerCase() == turnWord?.toLowerCase()) {
      await this.winCard();
    }
  }

  async startPlayingTurn()
  {
    try {
      await this.update.turn({
        started : true,
        guessed : 0,
        missed : 0,
        fouls : [],
        timeout : 0
      })
    } catch ( e ) {
      this.log.error( e );
    }
  }

  async callFoul()
  {
    try {
      const me = this.data.players[ this.myUserId! ]
      
      await Promise.all([
        // this.update.turn({ card : {} as any }),
        this.ref.turn().child("fouls").push({ caller : this.myUserId!, word : this.data.state.turn.card?.word }),
        this.ref.chat().push( { text : `${ me.username } called Outlaw!`, sender : this.myUserId!, type : 'foul' } ),
        this.passCard(),
      ])
    } catch ( e ) {
      this.log.error( e );
    }
  }

  async passCard()
  {
    try {
      const turnTeam = this.data.state.turn.team!
      const currentScore = this.data?.state?.scores[ turnTeam ] || 0
      const turn = this.data.state.turn
      // const turn = await this.get.turn()
      await Promise.all([
        this.ref.chat().push( { text: `The word was "${ this.data.state.turn.card?.word }"`, sender : this.myUserId!, type : 'foul' } ),
        this.update.turn({ card : {} as any, missed : ( ( turn?.missed ) || 0 ) + 1 }),
        this.update.scores({ [turnTeam] : +currentScore - 1 }),
        this.server.drawNextCard()
      ])

      // this.server.sendAction("passCard", { forTurnNumber : turn.number });
    } catch ( e ) {
      this.log.error( e );
    }
  }
  
  async winCard()
  {
    try {
      const turnTeam = this.data.state.turn.team!
      const currentScore = this.data?.state?.scores[ turnTeam ] || 0
      const turn = this.data.state.turn
      // const turn = await this.get.turn()
      await Promise.all([
        this.ref.chat().push( { text: `The word was "${ this.data.state.turn.card?.word }"`, sender : this.myUserId!, type : 'win' } ),
        this.update.turn({ card : {} as any, guessed : ( ( turn?.guessed ) || 0 ) + 1 }),
        this.update.scores({ [turnTeam] : +currentScore + 1 }),
        this.server.drawNextCard()
      ])
      // this.server.sendAction("winCard", { forTurnNumber : turn.number });
    } catch ( e ) {
      this.log.error( e );
    }
  }
  
  async updateScoreManually(score, team, userId, userName)
  {
    try {
      const currentScore = this.data?.state?.scores[ team ] || 0
      await Promise.all([
        this.update.scores({ [team] : +score }),
        this.ref.chat().push( { text: `${userName} have update the "${this.getTeamName(team)}" score from ${currentScore} to ${score}`, sender : userId, type: "warning" } ),
        LogService.info(`${userName} have update the "${this.getTeamName(team)}" score from ${currentScore} to ${score}`)
      ])
    } catch ( e ) {
      this.log.error( e );
    }
  }
  
}
