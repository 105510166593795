import React from 'react';
import ReactDom from 'react-dom';

import { App } from './components/app';

import 'sanitize.css';
import 'sanitize.css/typography.css';

const rootContainerId = 'SITE_MAIN';

const container = document.getElementById(rootContainerId) ?? createContainer(document.body);

function createContainer(targetParent: Element) {
  const newContainer = document.createElement('div');
  newContainer.id = rootContainerId;
  targetParent.appendChild(newContainer);
  return newContainer;
}

ReactDom.render(<App />, container);
