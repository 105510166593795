import React from 'react';

import { MAIN_HEADER_HEIGHT_PX, MOBILE_BREAK_PX } from '@ven/shared/components/styling/breakpoints';

import styled from '@emotion/styled'
import facebook from '../../assets/images/facebook.svg';
import twitter from '../../assets/images/twitter.svg';
import instagram from '../../assets/images/instagram.svg';
import youtube from '../../assets/images/youtube.svg';


export const SocialSection: React.FC<{ darkTheme?: boolean }> = () => {

  return (
    <SocialSectionWrapper>
      {/* <div className="container"> */}
        <div className="row text-center">
          <div className="col-lg-12 text-md-right
            mt-lg-0 mt-md-0 mt-5">
            <h3>Social Media</h3>
            <h6 className="d-none d-md-block d-lg-block">Follow us on</h6>
          </div>
          <div className="col-lg-12 icons text-md-right">

            <a href="https://www.facebook.com/playvengames" title="Facebook" target="_blank">
              <img className="input-icon" src={facebook} alt="" />
            </a>
            <a href="https://www.instagram.com/playvengames/" title="Instagram" target="_blank">
              <img className="input-icon" src={instagram} alt=""/>
            </a>
            <a href="https://twitter.com/playvengames" title="Twitter" target="_blank">
              <img className="input-icon" src={twitter} alt=""/>
            </a>
            <a href="https://www.youtube.com/channel/UCj0hYsFAmdFK4VM7W1tL7_Q" title="Youtube" target="_blank">
              <img className="input-icon" src={youtube} alt=""/>
            </a>
            {/* <a href="https://www.twitch.tv/rakillz" title="Twitch" target="_blank"><i className="fa fa-twitch fa-3x fa-fw"></i></a> */}
            {/* <a href="https://www.tumblr.com/blog/rakillz" title="Tumblr" target="_blank"><i className="fa fa-tumblr fa-3x fa-fw"></i></a> */}
          </div>
        </div>
      {/* </div> */}
    </SocialSectionWrapper>)
}

const SocialSectionWrapper = styled.div`
	padding: 10px;

 h3{ font-weight: bold; color: #333}
 h6{ color: #6d6d6d; margin-left: 45px;}
 img{
	fill: #6d6d6d;
	margin: 20px 15px;
  height: 32px;
	cursor: pointer;
}
@media (min-width: 770px){
  .col-lg-12 {
      padding-right: 67px !important;; 
  }

  .col-lg-12.icons {
      padding-right: 53px !important;; 
  }
}

 .facebook:hover{color: #3b5998}
 .instagram:hover{color: #833ab4}
 .twitter:hover{color: #1da1f2}
 .youtube-play:hover{color: #ff0000}
 .twitch:hover{color: #6441a5;}
 .google-plus:hover{color: #dd4b39}
 .pinterest:hover{color: #bd081c}
 .tumblr:hover{color: #35465c;}

`
