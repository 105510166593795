import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

//@ts-ignore
import wiggle from '../../../../assets/images/homepage/wiggle.svg';
//@ts-ignore
import computer from '../../../../assets/images/homepage/computer.svg';
//@ts-ignore
import people from '../../../../assets/images/homepage/people.svg';

import './styles.scss'
import Testimonal from '@ven/shared/components/common/atoms/Testimonal/Testimonal';
import GameNightCard from './GameNightCard';

import curveSectionBottom from '@ven/platform/main/assets/images/homepage/curve-section-bottom.svg';
import curveSectionTop from '@ven/platform/main/assets/images/homepage/curve-section-top.svg';

//@ts-ignore
import kickstarter from '@ven/platform/main/assets/images/teams/kickstartericonn.png';
//@ts-ignore
import docker from '@ven/platform/main/assets/images/teams/dockericon-1.png';
//@ts-ignore
import amazon from '@ven/platform/main/assets/images/teams/amazoniconn.png';
//@ts-ignore
import crowdiconn from '@ven/platform/main/assets/images/teams/crowdiconn.png';
// import testimonalSuneet from '../../../../assets/images/homepage/test_suneet.jpg';
// import testimonalNicole from '../../../../assets/images/homepage/test_nicole.jpg';
// import testimonalEric from '../../../../assets/images/homepage/test_eric.jpg';

import curveSectionBottomDark from '@ven/platform/main/assets/images/homepage/curve-section-bottom-dark.svg';

import { UserDataService } from '@ven/platform/main/services/user/UserDataService';
import { MainFooter } from '../../../organisms/MainFooter';
import { TestimonalCarrosel } from '@ven/shared/components/common/atoms/TestimonalCarrosel';
import { LogosMarquee } from '@ven/shared/components/common/atoms/LogosMarquee';
import styled from '@emotion/styled';


import devicesMobile from '../../../../assets/images/homepage/devices-mobile.png';
import facesMobile from '../../../../assets/images/homepage/mobile-faces.png';
import testimonialBackground from '../../../../assets/images/homepage/testimonial-background.png';

import playIcon from '../../../../assets/images/play-button.svg';
import { Modal } from 'react-bootstrap';


const testimonalProps = [
    {
        stars: 5,
        description: 'The games were easy to understand and explained well. Rachel has an electric personality and is exactly the right person to be doing this job. She kept things fun and light and almost felt like an extended member of the team! Bravo!.',
        image: docker,
        name: 'Docker'
    }, {
        stars: 5,
        description: "This was a great experience!  Our host was super engaging and did a fantastic job.",
        image: amazon,
        name: 'Amazon'
    }, {
        stars: 5,
        description: 'This was executed perfectly. On schedule and lots of fun. My face hurts from smiling so much. Kudos to @Trevor for making it so much fun for us.',
        image: crowdiconn,
        name: 'Crowd Cow'
    }, {
        stars: 5,
        description: 'It was super fun and Rachel was a great host. Thanks so much 🙂',
        image: kickstarter,
        name: 'Kickstarter'
    },
];
const testimonals: Array<any> = [];

for (const [i, test] of testimonalProps.entries()) {
    testimonals.push(
        <Testimonal key={i} props={test}></Testimonal>
    );
}

interface BottomProps {
    clickPlayGames: Function,
    openFreebieFlow: Function
};

const Bottom: React.FC<BottomProps> = ({
    clickPlayGames,
    openFreebieFlow,
}) => {

    const history = useHistory();
    const [testMobileContent, setTestMobileContent] = useState(<Testimonal props={testimonalProps[0]}></Testimonal>);
    const [viewTest, setViewTest] = useState(0);

    let isAnon = UserDataService.get('isAnon');

    const goToGameNightBundle = () => {
        history.push('/gamenight');
    };

    const nextTest = () => {
        let nxtTest = viewTest === testimonalProps.length-1  ? 0 : viewTest + 1;
        forceChangeTest(nxtTest);
    }

    const previousTest = () => {
        let nxtTest = viewTest === 0 ? testimonalProps.length-1  : viewTest - 1;
        forceChangeTest(nxtTest);
    }

    const forceChangeTest = (newTest: number) => {
        setViewTest(newTest);
        setTestMobileContent(<Testimonal props={testimonalProps[newTest]}></Testimonal>);
    }

    const [showVideoModal, setShowVideo] = useState(false);

    const handleClose = () => setShowVideo(false);

    return (
        <div className="bottom">
         <TestimonialSection className="testimonial-section">
            <LogosMarquee/>
            <div className="anytime">
                <img src={wiggle} alt="" />
                <h1>Play awesome games with friends. Anytime, anywhere.</h1>
                <p>Play your favorite party games online, now beautifully designed and fun for all ages.</p>
                <button className="play-games" onClick={() => clickPlayGames()}>
                    Get Started
                            </button>
            </div>
            <CurveSectionTop/>
            <div className="phone-tablet">
                <img src={wiggle} alt="" />
                <p>At home or on the go.</p>
                <p>All our games are desktop and mobile friendly.
                    Just send a join link to your friends and they can join from any device!</p>
            </div>
            <div className="families">
                <img src={wiggle} alt="" />
                <p>VEN is for friends, families, and work teams.</p>
            </div>
            {/* <p className="text">Do you want us to host your games? Check out this video and head to ven.events</p> */}

            <div className="testimonals">
                <TestimonalCarrosel></TestimonalCarrosel>
                {/* {testimonals} */}
            </div>
            <div className="testimonals-mobile" id="mobileTestimonals">
                {testMobileContent}
                <div className="steppers">
                    <div className="back-arrow" onClick={previousTest}><p>{'<'}</p></div>
                    <div className={`step ${viewTest === 0 ? 'dot-selected' : ''}`} onClick={() => forceChangeTest(0)}>{' '}</div>
                    <div className={`step ${viewTest === 1 ? 'dot-selected' : ''}`} onClick={() => forceChangeTest(1)}>{' '}</div>
                    <div className={`step ${viewTest === 2 ? 'dot-selected' : ''}`} onClick={() => forceChangeTest(2)}>{' '}</div>
                    <div className={`step ${viewTest > 2 ? 'dot-selected' : ''}`} onClick={() => forceChangeTest(3)}>{' '}</div>
                    <div className="forward-arrow" onClick={nextTest}><p>{'>'}</p></div>
                </div>
            </div>
            <div className="video-section">
                <div className="families">
                    <img src={wiggle} className='wiggle' alt="" />
                    <p>Do you want us to host your games?</p>
                    <p>Check out this video and head to ven.events</p>
                    <a className='buttom-desktop' href="https://ven.events/experiences">Book an Event</a>
                </div>
                <div className='video-wrapper' onClick={() => setShowVideo(true)}>
                    <div className='video-clip-link'>
                        <img src={playIcon}></img>
                    </div>
                </div>
                <a className='buttom-mobile' href="https://ven.events/experiences">Book an Event</a>
            </div>
            <p className="text">VEN will always be FREE for up to 4 players. Want to play with more?
                                Grab the Game Night bundle and host the perfect games night!</p>

            </TestimonialSection>
        
            <div className="bundle-section">
                <div className="game-night-section">
                
                <CurveSectionDark/>
                    <GameNightCard
                        bannerText="SPECIAL OFFERS"
                        title="2 for 1 GAME NIGHT BUNDLE"
                        subtitle="Enjoy 48 hours of unlimited gameplay and players, with all premium VEN content included."
                        text1="Play Games with an unlimited number of friends!"
                        text2="Unlock all premium extensions and word packs."
                        text3="2 for 1 — Give a Games Night as a gift for FREE!"
                        text4="Have a say in what games we create!"
                        text5=" "
                        // text5="Get early, exclusive access to new games. Play them weeks before anyone else."
                        buttonText="Buy the Bundle"
                        buttonAction={goToGameNightBundle}
                    />
                    <img src={people} className="people" alt="" />
                </div>
                <div className="freebie">
                    <img src={wiggle} alt="" />
                    <p>Free Forever</p>
                    <p>Just create an account to play with up to 4 people</p>
                    <button className="signup" onClick={() => !isAnon || isAnon === 'true' ? openFreebieFlow(1) : clickPlayGames()}> {!isAnon || isAnon === 'true' ? 'Sign up and redeem offer!' : 'Play Games'}</button>
                </div>
            </div>
            <div className="subscribe">
                <img src={computer} alt="" />
                <div className="text">
                    <p>Want to play more games before anyone else?</p>
                    <p>Get on our list to receive updates about new games.<br />
                                We’ll never spam you. Promise!</p>
                    <div className="form-container">
                        <form action="https://games.us2.list-manage.com/subscribe/post?u=9d53575796b4adedfa2ab72b0&amp;id=b9346c517f" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="form" target="_blank">
                            <input type="email" name="EMAIL" id="mce-EMAIL" autoComplete="off" placeholder="Your Email" className="email-field" required />
                            <input className="sb-button" id="mc-embedded" type="submit" value="SUBSCRIBE"></input>
                        </form>
                    </div>
                </div>
            </div>
            <MainFooter />

            <Modal show={showVideoModal} onHide={handleClose}
                // size="lg"
                dialogClassName='modal-video'
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body>
                    <iframe src="https://www.youtube.com/embed/xkryHABa6Sk?si=NF0MRP5BCt7l1Unf&autoplay=1" title="Ven Games events" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                    Save Changes
                    </Button>
                </Modal.Footer> */}
                </Modal>
        </div>

    );
};


const TestimonialSection = styled.div`
    background-color: rgba(116, 88, 253, 1);
    background-image: url(${testimonialBackground}), linear-gradient(135deg, rgba(116, 88, 253, 1) 0%, rgba(45, 17, 146, 1) 100%);
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: 40rem;
    padding-bottom: 20rem;
    @media(max-width: 700px) {
        background-color: rgba(116, 88, 253, 1);
    }
    display: flex;
    flex-direction: column;
    @media(max-width: 700px) {
        background-image: none;
        background: transparent;
        padding-bottom: 0;

        &::before {
            content: '';
            display: block;
            height: 514px;
            width: 386px;
            margin-top: 280px;
            position: absolute;
            background-repeat: no-repeat;
            background-image: url(${devicesMobile});
            background-position: 0;

        }
    }
    .testimonals{
        height: 55rem;
        /* margin-top: 7rem; */
    }
    .text {
        width: 78.8rem;
        font: 400 3.5rem Bitter;
        line-height: 5.1rem;
        color: #FFFFFF;
        text-align: center;
        align-self: center;

        @media (max-width: 700px) {
            padding: 3rem;
            font: 400 2.5rem Bitter;
            width: inherit;
        }
    }
`
const CurveSectionDark = styled.div`
    background-image: url(${curveSectionBottomDark});
    background-size: 100% 200px;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
    margin-top: -200px;

    @media (max-width: 700px) {
        display: none;
    }
`;

const CurveSectionTop = styled.div`
    background-image: url(${curveSectionTop});
    background-size: 100% 136px;
    background-position: top;
    background-repeat: no-repeat;
    width: 130rem;
    height: 156px;
    transform: translateY(-1px);

    transform-origin: bottom;
    @media(max-width: 700px) {
        display: none;
    }
`
export default Bottom;
