import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Nav, NavItem, NavLink, Spinner, Tab, Table } from 'react-bootstrap';
import _ from 'lodash';
import { database } from '@ven/shared/core/data/firebase';
import { InfoInput } from './InfoInput';
import styled from '@emotion/styled';
import { ClipboardCopy } from '@ven/shared/components/common/atoms/ClipboardCopy';

interface SessionInfoProps {
  data?: any;
}

const GameInfo: React.FC<SessionInfoProps> = (props) => {

  const [status, setStatus] = useState('');
  const [playerNumber, setPlayerNumber] = useState(-1);

  useEffect(() => {
    getInfo()
  }, []);

  const getInfo = async () => {
    const info = database.ref(props.data.sessionPath);
    info.on('value', snap => !!snap.val() && setInfo(snap.val()));
  }

  const setInfo = (info: any) => {
    setPlayerNumber(!info.players ? 0 : Object.keys(info.players).length);
    setStatus(!info.state ? 'not started'
              : info.state.over ? 'over' : 'started');
  }
  return (
    <Wrapper>
      <td>{props.data.index}</td>
      <td><ClipboardCopy copyText={`${window.location.origin}/play/${props.data.code}`} />
<a className='game-link' target="_blank" href={`${window.location.origin}/watch/${props.data.code}`}>watch/{props.data.code}</a></td>
      <td>{ playerNumber == -1 ? <Spinner animation="border"/> : playerNumber}</td>
      <td>{!status ? <Spinner animation="border"/> : status}</td>
      <td>{!props.data ? <Spinner animation="border"/> : <InfoInput data={...props.data}/>}</td>
    </Wrapper>
  )
}

const Wrapper = styled.tr`
  a.game-link{
    font-family: monospace;
      font-size: 15px;
  }
`

export default GameInfo;
