import React from "react";

import type { GameStateController } from "../gameplay/GameStateController";

const CHEAT_TIME_SECONDS = 60;

export const useDebugKeys = (game:GameStateController<any>,...deps:any[]) => {
  return React.useMemo( () => {
    const onKey = (e:KeyboardEvent) => {
      const handlers = {
        "KeyT" : () => game.update.turn({ time : ( game.data?.state?.turn?.time || 0 ) + CHEAT_TIME_SECONDS }),
        "KeyE" : () => game.update.turn({ time : 1, }),
        "KeyY" : () => game.update.turn({ time : 30, }),
        "KeyU" : () => game.update.turn({ time : 60, }),
        "Backquote" : () => game.ref.room().remove(),
      }
      for ( const keyCode in handlers ) {
        if ( e.shiftKey && e.code == keyCode ) {
          handlers[keyCode]();
          e.stopPropagation();
          e.preventDefault();
          return;
        }
      }
    }
    window.addEventListener("keypress", onKey )

    return () => window.removeEventListener("keypress", onKey )
  }, deps )
}
