import firebase from 'firebase';

import type { GameStateController } from './GameStateController';
import type { GameSessionData, PlayerID } from './GameSessionData';

import { delay, waitFor } from '@ven/shared/core/utils/promise';
import { rotate } from '@ven/shared/core/utils/arrays';
import { EventBus } from '../events/EventBus';
import type { LoggingFeature } from './plugins/Logging';
import { GetUserByUid } from '@ven/platform/main/services/user/UserDataService';

export type GameServerAction = {
  sender: PlayerID,
  time: number,
  type: string,
  data?: any,
}

/**
 * The intention is for this class to eventually become the de-facto server entity, 
 * which will handle all the common gameplay logic and only take requests from other
 * players in the form of 'actions', then resolve them in the correct sequence.
 */
export abstract class GamePseudoServer<
  TAction extends GameServerAction,
  TGameStateController extends GameStateController<TGameSessionData>,
  TGameSessionData extends GameSessionData
>
{
  public id = new Date().getTime();
  public readonly events = new EventBus<{
    hostChanged: ( newHost:PlayerID, previousHost:PlayerID ) => any,
  }>();

  protected get data() { return this.game.data }
  protected get ref() { return this.game.ref }
  protected get get() { return this.game.get }
  protected get update() { return this.game.update }
  protected get log() { return this.game['log'] as LoggingFeature<any> | undefined || {
    trace: (...rest) => console.log( ...rest ),
    error: (...rest) => console.error( ...rest ),
  } }
  
  private get refActions() { return this.game.ref.room().child('actions') }

  private queue:firebase.database.DataSnapshot[] = [];
  private tickerIsBusy:boolean = false;

  private loopTimeout:number = 0;

  private killLoop: boolean = false;

  private deviceInfoLogged = false;
  
  constructor ( protected readonly game:TGameStateController) {}

  public async initialize() {
    this.refActions.on('child_added', o => this.queue.push( o ));
    
    this.loop();

    setInterval( async () => {
      if ( this.tickerIsBusy ) {
        console.warn( `Rejecting call for tick, because previous ticker logic is still being executed...`);
        return;
      }
      try {
        this.tickerIsBusy = true;
        await this.tick();
      } catch ( e ) {
        this.log.error( e );
      } finally {
        this.tickerIsBusy = false;
      }
    }, 1000 );
  }

  protected abstract tick():Promise<void>;

  //// //// //// //// 
  
  private async loop() {

    await waitFor( () => !! this.data )

    console.log( `GamePseudoServer ${this.id}: Beginning the loop.` )
    
    while ( !! this.game && !this.killLoop ) {
  
      while ( this.loopTimeout > 0 ) {
        await delay( 1.000 );
        this.loopTimeout -= 1.000;
      }
      if ( this.game.amHost() && !this.game.amSpectator() ) {
        
        this.logDeviceInfo();
        //// Handle actions sent by all players
        //// one by one, with a quarter second period
        //// if more than one action is enqueued

        if ( ! this.queue.length ) {
          await delay( .250 );
        } 
        else {
          const snap = this.queue.pop()!
          try {
            await this.handleAction?.( snap.val() as TAction )
          } catch ( e ) {
            this.log.error( e );
          } finally {
            snap.ref.remove();
          }
        }

      } else {
        await delay( 1.000 );
        if(this.data.state.started) {}
          await this.performHostRotationCheck();
      }
    }
    console.log( `GamePseudoServer ${this.id}: loop end` )
  }

  public async performHostRotationCheck()
  {
    try {
      if(!this.data.state.started || this.data.state.over || this.game.amSpectator()){
        return;
      }
    } catch (error) {
      return;
    }
    
    this.logDeviceInfo();
  
    const currentHostState = this.data.players[this.data.host!];
    if ( !currentHostState ) {
      console.log('No host')
      return;
    }

    const presencesRef = await this.game.ref.presences().once( "value" )
    const presences = await presencesRef.val()
    const hostUserData = presences[this.data.host!]
    if ( hostUserData && hostUserData?.online ) return;
    if ( hostUserData && hostUserData.lastOnline 
      && +hostUserData.lastOnline + 3000 > firebase.firestore.Timestamp.now().seconds * 1000 ) return;

    const creatorId = this.data.creator!;
    const hostCandidates = Object.keys( this.data.players ).sort()
    rotate( hostCandidates, hostCandidates.indexOf( creatorId ) );

    const topHostCandidate = hostCandidates.find( p => presences[p].online )
    
    if ( topHostCandidate === undefined ) {
      console.log( `Host needs to change, but there is no suitable candidate... ... ...` )
      return;
    }

    if (!presences[topHostCandidate].online && presences[topHostCandidate].spectator ) {
      console.log( `Host is a spectator only, which makes him not a suitable candidate...` )
      return;
    }

    if ( topHostCandidate === this.data.host ) {
      console.log(`Host remains the same`)
      return;
    }

    if ( topHostCandidate === this.game.myUserId ) {
      this.log.trace( `Switching host from "${ this.data.host }"  (${ 
        this.data.players[this.data.host]?.username }) to "${ topHostCandidate }" (${ 
        this.data.players[topHostCandidate]?.username })`);
      const previousHost = this.data.host;
      await this.ref.host().set( topHostCandidate );
      await this.events.dispatch( 'hostChanged', topHostCandidate, previousHost );
    }
  }

  private logDeviceInfo() {
    try {
      if (!this.deviceInfoLogged) {
        this.log.trace(`${navigator.userAgent}`);
        this.log.trace(JSON.stringify({
          availHeight: screen.availHeight,
          availWidth: screen.availWidth,
          availTop: (screen as any).availTop,
          availLeft: (screen as any).availLeft,
          // colorDepth : screen.colorDepth,
          height: screen.height,
          width: screen.width,
          isExtended: (screen as any).isExtended,
          orientation: screen.orientation.type,
          orientationAngle: screen.orientation.angle,
          pixelDepth : screen.pixelDepth
        }));
        this.deviceInfoLogged = true;
      }
    } catch (error) {
      this.deviceInfoLogged = false;
    }
  }

  protected abstract handleAction( action: TAction ):Promise<any>

  public async sendAction( type: TAction['type'], data:Omit<TAction,"sender"|"time"|"type"> )
  {
    this.log.trace(`Adding action to the mq: "${ type }", ${ data && JSON.stringify(Object.keys(data)) }`);
    await this.refActions.push({ 
      sender: this.game.myUserId,
      time: firebase.database.ServerValue.TIMESTAMP,
      type,
      ...data
    })
  }

  public async destroy()
  {
    this.killLoop = true;
  }
}
