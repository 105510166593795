const DirtyModalService = () => {
    let openChooseHowToPlayModalCallback: Function;

    return {
        setOpenChooseHowToPlayModal : (callback) => {
            openChooseHowToPlayModalCallback = callback;
        },
        openChooseHowToPlayModal : () => {
            if(openChooseHowToPlayModalCallback)
                openChooseHowToPlayModalCallback();
        }
    }
}

export default DirtyModalService();