// Based and Translated from https://github.com/namniak/canvas-text-wrapper

interface CanvasTextWrapperOptions {
    font?: string,
    sizeToFill?: boolean,
    maxFontSizeToFill?: boolean | number,
    lineHeight?: number | string,
    allowNewLine?: boolean,
    lineBreak?: string, //"auto" | "word",
    textAlign?: string, //"left" | "center" | "right",
    verticalAlign?: string, //"top" | "middle" | "bottom",
    justifyLines?: false,
    paddingX?: number,
    paddingY?: number,
    fitParent?: boolean,
    strokeText?: boolean,
    renderHDPI?: boolean,
    textDecoration?: string //"none" | "underline"
}

const CanvasTextWrapper = (canvas, text: string, options: CanvasTextWrapperOptions) => {

    const defaults: CanvasTextWrapperOptions = {
        font: '18px Arial, sans-serif',
        sizeToFill: false,
        maxFontSizeToFill: false,
        lineHeight: 1,
        allowNewLine: true,
        lineBreak: 'auto',
        textAlign: 'left',
        verticalAlign: 'top',
        justifyLines: false,
        paddingX: 0,
        paddingY: 0,
        fitParent: false,
        strokeText: false,
        renderHDPI: false,
        textDecoration: 'none'
    }

    options = { ...defaults, ...options }

    const context = canvas.getContext('2d');
    context.font = options.font;
    context.textBaseline = 'bottom';

    let scale = 1;
    const devicePixelRatio = (typeof global !== 'undefined') ? global.devicePixelRatio : window.devicePixelRatio;

    if (options.renderHDPI && devicePixelRatio > 1) {
        const tempCtx = {};

        // store context settings in a temp object before scaling otherwise they will be lost
        for (const key in context) {
            tempCtx[key] = context[key];
        }

        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;
        scale = devicePixelRatio;

        canvas.width = canvasWidth * scale;
        canvas.height = canvasHeight * scale;
        canvas.style.width = canvasWidth * scale * 0.5 + 'px';
        canvas.style.height = canvasHeight * scale * 0.5 + 'px';

        // restore context settings
        for (const key in tempCtx) {
            try {
                context[key] = tempCtx[key];
            } catch (e) {

            }
        }

        context.scale(scale, scale);
    }

    const EL_WIDTH = (!options.fitParent ? canvas.width : canvas.parentNode.clientWidth) / scale;
    const EL_HEIGHT = (!options.fitParent ? canvas.height : canvas.parentNode.clientHeight) / scale;
    const MAX_TXT_WIDTH = EL_WIDTH - (options.paddingX! * 2);
    const MAX_TXT_HEIGHT = EL_HEIGHT - (options.paddingY! * 2);
    const multiNewLineDelimiter = '\u200B';

    let textBlockHeight = 0;
    let lines: any[] = [];
    let newLineIndexes: any[] = [];

    let lineHeight = 0;
    let fontParts;
    let textPos = { x: 0, y: 0 };

    const fontSize = getFontSize();

    text = handleMultipleNewline(text);
    setFont(fontSize);
    setLineHeight();
    //   validate();
    render();


    function getFontSize() {
        const isValidFontSize = options.font!.match(/\d+(px|em|%)/g);
        if (isValidFontSize != null) {
            const fontSize = isValidFontSize[0].match(/\d+/g);
            if (fontSize != null)
                return +fontSize;
        }
        return 18;
    }

    function handleMultipleNewline(text) {
        do {
            text = text.replace(/\n\n/g, '\n' + multiNewLineDelimiter + '\n');
        } while (text.indexOf('\n\n') > -1);
        return text;
    }

    function setFont(fontSize) {
        if (!fontParts) fontParts = (!options.sizeToFill) ? options.font!.split(/\b\d+px\b/i) : context.font.split(/\b\d+px\b/i);
        context.font = fontParts[0] + fontSize + 'px' + fontParts[1];
    }

    function setLineHeight() {
        if (options.lineHeight!.toString().indexOf('px') !== -1) {
            lineHeight = parseInt(options.lineHeight as string);
            return;
        } else if (options.lineHeight!.toString().indexOf('%') !== -1) {
            lineHeight = (parseInt(options.lineHeight as string) / 100) * fontSize;
            return;
        }
        lineHeight = fontSize * (options.lineHeight as number);
    }

    function render() {
        if (options.sizeToFill) {
            const wordsCount = text.trim().split(/\s+/).length;
            let newFontSize = 0;
            const fontSizeHasLimit = options.maxFontSizeToFill !== false;

            do {
                if (fontSizeHasLimit) {
                    if (++newFontSize <= options.maxFontSizeToFill!) {
                        adjustFontSize(newFontSize);
                    } else {
                        break;
                    }
                } else {
                    adjustFontSize(++newFontSize);
                }
            } while (textBlockHeight < MAX_TXT_HEIGHT && (lines.join(' ').split(/\s+/).length == wordsCount));

            adjustFontSize(--newFontSize);
        } else {
            wrap();
        }

        if (options.justifyLines && options.lineBreak === 'auto') {
            justify();
        }

        setVertAlign();
        setHorizAlign();
        drawText();
    }

    function adjustFontSize(size) {
        setFont(size);
        lineHeight = size;
        wrap();
    }

    function wrap() {
        if (options.allowNewLine) {
            const newLines = text.trim().split('\n');
            for (let i = 0, idx = 0; i < newLines.length - 1; i++) {
                idx += newLines[i].trim().split(/\s+/).length;
                newLineIndexes.push(idx)
            }
        }

        const words = text.trim().split(/\s+/);
        checkLength(words);
        breakText(words);

        textBlockHeight = lines.length * lineHeight;
    }

    function checkLength(words) {
        let testString, tokenLen, sliced, leftover;

        words.forEach(function (word, index) {
            testString = '';
            tokenLen = context.measureText(word).width;

            if (tokenLen > MAX_TXT_WIDTH) {
                let k = 0;
                for (; (context.measureText(testString + word[k]).width <= MAX_TXT_WIDTH) && (k < word.length); k++) {
                    testString += word[k];
                }

                sliced = word.slice(0, k);
                leftover = word.slice(k);
                words.splice(index, 1, sliced, leftover);
            }
        });
    }

    function breakText(words) {
        lines = [];
        for (let i = 0, j = 0; i < words.length; j++) {
            lines[j] = '';

            if (options.lineBreak === 'auto') {
                if (context.measureText(lines[j] + words[i]).width > MAX_TXT_WIDTH) {
                    break;
                } else {
                    while ((context.measureText(lines[j] + words[i]).width <= MAX_TXT_WIDTH) && (i < words.length)) {

                        lines[j] += words[i] + ' ';
                        i++;

                        if (options.allowNewLine) {
                            for (let k = 0; k < newLineIndexes.length; k++) {
                                if (newLineIndexes[k] === i) {
                                    j++;
                                    lines[j] = '';
                                    break;
                                }
                            }
                        }
                    }
                }
                lines[j] = lines[j].trim();
            } else {
                lines[j] = words[i];
                i++;
            }
        }
    }

    function justify() {
        let maxLen, longestLineIndex, tokenLen;
        for (let i = 0; i < lines.length; i++) {
            tokenLen = context.measureText(lines[i]).width;

            if (!maxLen || tokenLen > maxLen) {
                maxLen = tokenLen;
                longestLineIndex = i;
            }
        }

        // fill lines with extra spaces
        let numWords, spaceLength, numOfSpaces, num, filler;
        const delimiter = '\u200A';
        for (let i = 0; i < lines.length; i++) {
            if (i === longestLineIndex) continue;

            numWords = lines[i].trim().split(/\s+/).length;
            if (numWords <= 1) continue;

            lines[i] = lines[i].trim().split(/\s+/).join(delimiter);

            spaceLength = context.measureText(delimiter).width;
            numOfSpaces = (maxLen - context.measureText(lines[i]).width) / spaceLength;
            num = numOfSpaces / (numWords - 1);

            filler = '';
            for (let j = 0; j < num; j++) {
                filler += delimiter;
            }

            lines[i] = lines[i].trim().split(delimiter).join(filler);
        }
    }

    function underline(text, x, y) {
        const width = context.measureText(text).width;

        switch (context.textAlign) {
            case 'center':
                x -= (width / 2);
                break;
            case 'right':
                x -= width;
                break;
        }

        context.beginPath();
        context.moveTo(x, y);
        context.lineTo(x + width, y);
        context.stroke();
    }

    function drawText() {
        const skipLineOnMatch = multiNewLineDelimiter + ' ';
        const textDecoration = options.textDecoration!.toLocaleLowerCase() === 'underline';
        for (let i = 0; i < lines.length; i++) {
            textPos.y = textPos.y + lineHeight;
            if (lines[i] !== skipLineOnMatch) {
                context.fillText(lines[i], textPos.x, textPos.y);

                if (options.strokeText) {
                    context.strokeText(lines[i], textPos.x, textPos.y);
                }

                if (textDecoration) {
                    underline(lines[i], textPos.x, textPos.y);
                }
            }
        }
    }

    function setHorizAlign() {
        context.textAlign = options.textAlign;

        if (options.textAlign == 'center') {
            textPos.x = EL_WIDTH / 2;
        } else if (options.textAlign == 'right') {
            textPos.x = EL_WIDTH - options.paddingX!;
        } else {
            textPos.x = options.paddingX!;
        }
    }

    function setVertAlign() {
        if (options.verticalAlign == 'middle') {
            textPos.y = ((EL_HEIGHT - textBlockHeight) / 2);
        } else if (options.verticalAlign == 'bottom') {
            textPos.y = EL_HEIGHT - textBlockHeight - options.paddingY!;
        } else {
            textPos.y = options.paddingY!;
        }
    }
}

export default CanvasTextWrapper;